import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import { actions, selectors } from '@/redux/api/tag';

import Button, { ButtonVariants } from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import { ActionsContainer } from '+components/Layout';
import NoDataPage from '+components/NoDataPage';
import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import { pluralize } from '+utils';

import { getColumns } from './components/columns';

const FlowTags = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isFetching, flowTags } = useSelector(selectors.getState);
  const permissions = usePermissions(PermissionModel.Resources.flow_tag.value);

  useLoadingIndicator(isFetching);

  const [inProgress, setInProgress] = useState(false);
  const [selected, setSelected] = useState([]);
  const [showBulkDeleteModal, toggleBulkDeleteModal] = useToggle(false);

  const [modal, setModal] = useState({
    show: false,
    flowtagId: null,
    flowtagName: null,
  });

  const rows = useMemo(() => Object.values(flowTags || {}), [flowTags]);

  const toggleDeleteModal = useCallback(
    (flowtagId = 0, flowtagName = null) =>
      setModal((prev) => {
        const show = !!flowtagId && !prev.show;
        return {
          show,
          flowtagId,
          flowtagName,
        };
      }),
    [],
  );

  const cxActionMenu = useCallback(
    (id, { name }) => {
      const items = [
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          onClick: () => navigate(`${RoutePaths.flowTags}/${id}`),
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled: !permissions?.delete,
          onClick: () => {
            toggleDeleteModal(id, name);
          },
        },
      ];

      return (
        <MenuColumnContextMenu
          title={name}
          items={items}
          dataTracking="flowtag"
        />
      );
    },
    [permissions, toggleDeleteModal],
  );

  const columns = useMemo(() => getColumns({ cxActionMenu }), [cxActionMenu]);

  const onDeleteFlowTag = useCallback(() => {
    const { flowtagId } = modal;

    setInProgress(true);
    dispatch(actions.deleteFlowTag(flowtagId));
  }, [modal]);

  const onAddButtonClick = useCallback(
    () => navigate(`${RoutePaths.flowTags}/add`),
    [],
  );

  const onConfirmBulkDelete = useCallback(() => {
    toggleBulkDeleteModal();
    if (!selected.length) {
      return;
    }

    dispatch(actions.bulkDeleteTags(selected));
  }, [selected]);

  const onSelectedRowsChange = useCallback((selectedRowIds) => {
    setSelected((prev) => {
      const next = Object.entries(selectedRowIds || {})
        .map(([key, value]) => (value ? key : null))
        .filter(Boolean);

      if (!prev.length && !next.length) {
        return prev;
      }

      return next;
    });
  }, []);

  const getIsRowSelectorDisabled = useCallback(
    () => !permissions?.update,
    [permissions],
  );

  useEffect(() => {
    if (isFetching || !inProgress) {
      return;
    }

    setInProgress(false);
    setModal({});
  }, [isFetching, inProgress]);

  useEffect(() => {
    dispatch(actions.fetchFlowTags());

    return () => {
      dispatch(actions.cancel());
    };
  }, []);

  return (
    <Fragment>
      <ActionsContainer>
        <Button
          onClick={onAddButtonClick}
          disabled={!permissions?.create || isFetching}
        >
          Add Flow Tag
        </Button>

        <Button
          variant={ButtonVariants.outlined}
          onClick={toggleBulkDeleteModal}
          disabled={!permissions?.delete || !selected.length || isFetching}
        >
          Delete Selected
        </Button>
      </ActionsContainer>

      {!rows.length && (
        <NoDataPage
          image="/assets/graphic-flow-tags-bw.png"
          imageOffsetY={20}
          noDataText="No Flow Tags set"
          actionButtonText="Add Flow Tag"
          loading={isFetching}
        />
      )}
      {rows.length > 0 && (
        <Fragment>
          <Table
            id="Configuration_FlowTagsList"
            columns={columns}
            data={rows}
            getIsRowSelectorDisabled={getIsRowSelectorDisabled}
            onSelectedRowsChange={onSelectedRowsChange}
          />
          <ConfirmModal
            item={modal.flowtagName || ''}
            isOpen={modal.show}
            onConfirm={onDeleteFlowTag}
            onToggle={() =>
              toggleDeleteModal(modal.flowtagId, modal.flowtagName)
            }
          />
        </Fragment>
      )}
      {showBulkDeleteModal && (
        <ConfirmModal
          item={`${selected.length} Flow ${pluralize(selected.length, 'Tag')}`}
          confirmButtonText="delete"
          whyAsking=""
          onToggle={toggleBulkDeleteModal}
          onConfirm={onConfirmBulkDelete}
          toggleOnConfirm={false}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default FlowTags;
