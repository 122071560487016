import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMeasure, useWindowSize } from 'react-use';
import { VariableSizeList as List } from 'react-window';

import classNames from 'classnames';
import styled from 'styled-components';

import useEvent from '+hooks/useEvent';
import { propsSelectors as themeTable } from '+theme/slices/table';

import { defaultRowHeight } from '../../constants';
import { NoData, TBody } from './Components';
import FakeRow from './FakeRow';
import Row from './Row';
import ScrollbarList from './ScrollbarList';

const StickyContainer = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  background: ${themeTable.background};
  width: 100%;
`;

const getParentGroups = (row, rows) => {
  const parentIndex = rows.findIndex((item) => item?.subRows?.includes(row));
  if (parentIndex === -1) {
    return [];
  }
  return [
    ...getParentGroups(rows[parentIndex], rows),
    { item: rows[parentIndex], index: parentIndex },
  ];
};

const isStickyGroupsEqual = (prevValue, nextValue) => {
  return (
    prevValue.length === nextValue.length &&
    prevValue.every(
      (prevItem, index) => prevItem.item.id === nextValue[index]?.item?.id,
    )
  );
};

const virtualizedListStyle = { width: 'unset' };

const Body = (props) => {
  const {
    getTableBodyProps,
    getRowProps,
    getCellProps,
    prepareRow,
    allRows,
    rows,
    rowsCount,
    SubComponent,
    expandSingleSubRow,
    fakeRows,
    headerGroups,
    noDataText,
    autoHeight,
    isGrouped,
    virtualized: virtualizedProp,
    rowHeight,
    zebra,
    isStaticTable,
    ...tail
  } = props;

  const { height: windowHeight } = useWindowSize();
  const [ref, { height: bodyHeight }] = useMeasure();
  const [stickyGroups, setStickyGroups] = useState([]);
  const refList = useRef();

  const refBody = useCallback(
    (scrollbar) => {
      if (ref) {
        ref(scrollbar?.containerEl);
      }
    },
    [ref],
  );

  const noData = useMemo(() => noDataText ?? 'No data found', [noDataText]);

  const [lastHeaderGroup] = headerGroups.slice(-1);
  const tableBodyProps = getTableBodyProps();

  const items = useMemo(
    () => [...rows, ...(autoHeight || !rowsCount ? fakeRows : [])],
    [rows, rowsCount, fakeRows, autoHeight],
  );

  const virtualized = virtualizedProp || items.length > 50;

  const virtualizedListHeight = useMemo(() => {
    let rowsHeight = items.length * (rowHeight ?? 0);
    if (typeof rowHeight === 'function') {
      rowsHeight = items.reduce(
        (acc, item, index) =>
          acc +
          rowHeight(items[index], defaultRowHeight, index, index >= rowsCount),
        0,
      );
    }

    return autoHeight ? Math.min(rowsHeight, windowHeight) : bodyHeight;
  }, [
    autoHeight,
    items.length,
    rowHeight,
    bodyHeight,
    windowHeight,
    rows,
    rowsCount,
  ]);

  const bodyProps = useMemo(() => ({ style: tail.style || {} }), [tail.style]);

  const getRowHeight = useCallback(
    (index) => {
      let result = rowHeight;
      if (typeof rowHeight === 'function') {
        result = rowHeight(
          items[index],
          defaultRowHeight,
          index,
          index >= rowsCount,
        );
      }

      return result;
    },
    [items, rowHeight, rows, rowsCount],
  );

  const rowProps = {
    tableBodyProps,
    getCellProps,
    getRowProps,
    SubComponent,
    expandSingleSubRow,
  };

  const rowPropsAsDeps = Object.values(rowProps);

  const rowRenderItem = useCallback(
    ({ index, odd, ...rest }) => {
      const item = rows[index];
      if (!item) {
        return null;
      }
      prepareRow(item);
      return (
        <Row
          {...rowProps}
          key={item?.getRowProps?.().key}
          className={classNames(
            rowProps.className || '',
            !zebra && !isGrouped && 'root-row',
            !zebra &&
              isGrouped &&
              (item.isGrouped || item.isFromEmptyGroup) &&
              'root-row',
            !zebra &&
              isGrouped &&
              !item.isGrouped &&
              !item.isFromEmptyGroup &&
              'sub-row',
          )}
          row={item}
          index={item.index}
          odd={odd}
          testId="table-row"
          {...rest}
        />
      );
    },
    [...rowPropsAsDeps, prepareRow, isGrouped, zebra, rows],
  );

  const rowRenderFactory = useCallback(
    ({ index, ...rest }) => {
      const isFake = index >= rows.length;
      const odd = !(index % 2);

      if (isFake) {
        return (
          <FakeRow
            {...rowProps}
            key={`key_dummyRow_${fakeRows[index - rows.length]}`}
            className={classNames(
              rowProps.className || '',
              !zebra && 'root-row',
            )}
            odd={odd}
            headers={lastHeaderGroup?.headers}
            testId="table-row-fake"
            {...rest}
          />
        );
      }

      // Don't render sticky items
      // const stickyItem = stickyGroups.find((item) => item.index === index);
      // if (stickyItem) {
      //   return null;
      // }
      return rowRenderItem({ index, odd, ...rest });
    },
    [
      rows,
      ...rowPropsAsDeps,
      fakeRows,
      lastHeaderGroup,
      rowRenderItem,
      isGrouped,
      zebra,
    ],
  );

  const onScroll = useEvent(({ scrollOffset }) => {
    setStickyGroups((prevValue) => {
      if (!scrollOffset) {
        return !prevValue.length ? prevValue : [];
      }

      let topItemIndex;

      if (typeof rowHeight === 'function') {
        let currentHeight = 0;
        topItemIndex = items.findIndex((item, index) => {
          currentHeight += rowHeight(
            item,
            defaultRowHeight,
            index,
            index >= rowsCount,
          );
          return currentHeight > scrollOffset;
        });
      } else {
        topItemIndex = Math.floor(scrollOffset / rowHeight);
      }

      const topItem = items[Math.min(items.length - 1, topItemIndex + 1)];
      const nextValue = getParentGroups(topItem, items);
      // const isExpandedGroup = topItem.isGrouped && topItem.isExpanded;
      // if (isExpandedGroup) {
      //   nextValue.push({ item: topItem, index: topItemIndex });
      // }
      return isStickyGroupsEqual(prevValue, nextValue) ? prevValue : nextValue;
    });
  });

  const refIndexes = useRef([]);
  const firstRendering = useRef(true);

  useEffect(() => {
    const prepared = rows
      .map((row, index) => (!row.isGrouped && row.isExpanded ? index : null))
      .filter((index) => index !== null);

    if (firstRendering.current) {
      firstRendering.current = false;
      refIndexes.current = prepared;
      return;
    }

    const diff =
      prepared.length > refIndexes.current.length
        ? prepared.filter((index) => !refIndexes.current.includes(index))
        : refIndexes.current.filter((index) => !prepared.includes(index));

    refIndexes.current = prepared;

    const firstChange = diff[0];

    if (firstChange === undefined) {
      return;
    }

    refList.current?.resetAfterIndex(firstChange);
  }, [rows]);

  return (
    <TBody
      {...getTableBodyProps(bodyProps)}
      ref={refBody}
      $autoHeight={autoHeight}
      className={isStaticTable && 'static-table'}
    >
      {!!stickyGroups.length && (
        <StickyContainer>
          {stickyGroups.map(({ index }) => rowRenderItem({ index }))}
        </StickyContainer>
      )}

      {virtualized ? (
        <List
          ref={refList}
          style={virtualizedListStyle}
          itemCount={items.length}
          itemSize={getRowHeight}
          height={virtualizedListHeight}
          outerElementType={ScrollbarList}
          onScroll={onScroll}
        >
          {rowRenderFactory}
        </List>
      ) : (
        items.map((_, index) => rowRenderFactory({ index }))
      )}

      {noData && !rows.length && <NoData>{noData}</NoData>}
    </TBody>
  );
};

Body.propTypes = {
  fakeRows: PropTypes.arrayOf(PropTypes.number).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  allRows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  rowsCount: PropTypes.number,
  headerGroups: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getRowProps: PropTypes.func,
  getCellProps: PropTypes.func,
  SubComponent: PropTypes.elementType,
  expandSingleSubRow: PropTypes.bool,
  noDataText: PropTypes.string,
  autoHeight: PropTypes.bool,
  isGrouped: PropTypes.bool,
  virtualized: PropTypes.bool,
  /**
   * Height of the row. If it's a function, it will be called with the row, defaultRowHeight,
   * the index of the row, and the last arg is isFakeRow or not.
   */
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  zebra: PropTypes.bool,
  isStaticTable: PropTypes.bool,
};

Body.defaultProps = {
  getRowProps: null,
  getCellProps: null,
  SubComponent: null,
  expandSingleSubRow: null,
  noDataText: null,
  autoHeight: true,
  isGrouped: false,
  rowsCount: 0,
  virtualized: undefined,
  rowHeight: defaultRowHeight,
  zebra: true,
  isStaticTable: false,
};

export default Body;
