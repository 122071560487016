import PropTypes from '+prop-types';
import { memo, useCallback, useMemo } from 'react';

import RoutePaths from '@/models/RoutePaths';

import Button from '+components/Button';
import { usePageTabs } from '+components/PageTabs';
import useThresholdOverrides from '+hooks/useThresholdOverrides';

export const OverridesButton = memo((props) => {
  const { event, isSubAccountRecord } = props;
  const [, , pageTabMethods] = usePageTabs();

  const isAutoThreshold = event?.tdm?.auto_threshold;

  const algorithmId = useMemo(() => event?.tdm?.id, [event]);
  const { getTrackBySearchParams, triggerExists } = useThresholdOverrides(
    algorithmId,
    event,
  );

  const onOverrideClick = useCallback(() => {
    const trackbySearchParams = getTrackBySearchParams();
    const path = RoutePaths.modelsDetection;
    pageTabMethods.add({
      pathname: `${path}/${algorithmId}/${RoutePaths.modelOverrides}/update`,
      search: `${trackbySearchParams}${triggerExists() ? '&mode=edit' : ''}`,
    });
  }, [event, algorithmId, getTrackBySearchParams, triggerExists]);

  const onViewOverridesClick = useCallback(() => {
    const path = RoutePaths.modelsDetection;
    pageTabMethods.add(
      `${path}/${algorithmId}/${RoutePaths.modelAutoThresholds}`,
    );
  }, [event, algorithmId, getTrackBySearchParams, triggerExists]);

  return (
    !isSubAccountRecord && (
      <Button
        onClick={isAutoThreshold ? onViewOverridesClick : onOverrideClick}
        className="excludeStyle"
      >
        {isAutoThreshold && 'Overrides'}
        {!isAutoThreshold && `${triggerExists() ? 'Edit' : 'Add'} Override`}
      </Button>
    )
  );
});

OverridesButton.displayName = 'OverridesButton';

OverridesButton.propTypes = {
  event: PropTypes.shape().isRequired,
  isSubAccountRecord: PropTypes.bool,
};
OverridesButton.defaultProps = {
  isSubAccountRecord: false,
};
