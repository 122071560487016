import PropTypes from '+prop-types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as ipIntelActions,
  selectors as ipIntelSelectors,
} from '@/redux/api/ipintel';

import Flag from '+components/Flag';
import { Col, NoData } from '+components/Layout';
import { UniversalCell } from '+components/Table/Cells';
import { timestampFormatter } from '+components/Table/Cells/formatters';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useIpLabels from '+hooks/useIpLabels';

import {
  BodyCountryCode,
  BodyGroupCol,
  BodyGroupTitleRow,
  BodyItemRow,
  BodyNameCol,
  BodySeparator,
  BodyTag,
  BodyValueCol,
} from './BodyComponents';

const IpIntelligence = ({ padding, ip, showLabels }) => {
  const dispatch = useDispatch();

  const [filters] = useGlobalFilters();
  const optionsIps = useMemo(
    () => ({ fetchExact: !showLabels ? null : ip }),
    [showLabels, ip],
  );
  const { ipLabelsHash } = useIpLabels(optionsIps);
  const isFetching = useSelector(ipIntelSelectors.isFetching);
  const intelData = useSelector(ipIntelSelectors.getIpIntel(ip));
  const isIntelDataEmpty = !Object.keys(intelData || {}).length;

  useEffect(() => {
    if (ip && isIntelDataEmpty) {
      dispatch(ipIntelActions.fetchIpIntel(ip));
    }
  }, [ip, isIntelDataEmpty]);

  const labels = useMemo(
    () => (!showLabels ? [] : ipLabelsHash[ip]?.[filters.labelContext.ip]),
    [ip, showLabels, ipLabelsHash, filters.labelContext.ip],
  );

  if (!Object.keys(intelData || {}).length) {
    const noDataText = isFetching
      ? 'Loading...'
      : 'No IP Intelligence to display';

    return <NoData padding={padding}>{noDataText}</NoData>;
  }

  return (
    <Col gap="14px">
      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        {showLabels && (
          <BodyItemRow>
            <BodyNameCol>Default Label:</BodyNameCol>
            <BodyValueCol>
              {UniversalCell('ipname')({
                value: labels,
                row: { original: { ip } },
                options: {
                  context: filters.labelContext.ip,
                  useDataValueInPropertiesTray: true,
                  disabled: true,
                },
              })}
            </BodyValueCol>
          </BodyItemRow>
        )}
        <BodyItemRow>
          <BodyNameCol>First Seen:</BodyNameCol>
          <BodyValueCol>
            {timestampFormatter(intelData?.firstseen)}
          </BodyValueCol>
        </BodyItemRow>
        <BodyItemRow>
          <BodyNameCol>Last Seen:</BodyNameCol>
          <BodyValueCol>{timestampFormatter(intelData?.lastseen)}</BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Autonomous System</BodyGroupTitleRow>
        <BodyItemRow>
          <BodyNameCol>Number:</BodyNameCol>
          <BodyValueCol>{intelData?.as?.number}</BodyValueCol>
        </BodyItemRow>
        <BodyItemRow>
          <BodyNameCol>Organization:</BodyNameCol>
          <BodyValueCol>{intelData?.as?.org}</BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Threat Intel</BodyGroupTitleRow>
        <BodyItemRow>
          <BodyNameCol>Bogon:</BodyNameCol>
          <BodyValueCol>
            {intelData?.bogon === true && (
              <BodyTag color="danger" outlined={false}>
                Yes
              </BodyTag>
            )}
            {intelData?.bogon === false && (
              <BodyTag color="success" outlined={false}>
                No
              </BodyTag>
            )}
          </BodyValueCol>
        </BodyItemRow>
        <BodyItemRow>
          <BodyNameCol>IP Reputation:</BodyNameCol>
          <BodyValueCol $preLine>
            {intelData?.iprep?.count === 0 && (
              <BodyTag color="success" outlined={false}>
                None
              </BodyTag>
            )}
            {intelData?.iprep?.count > 0 &&
              intelData?.iprep?.categories?.join('\n')}
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>DNS</BodyGroupTitleRow>
        <BodyItemRow>
          <BodyNameCol>Reverse:</BodyNameCol>
          <BodyValueCol $preLine>{intelData?.rdns?.join('\n')}</BodyValueCol>
        </BodyItemRow>
        <BodyItemRow>
          <BodyNameCol>Passive:</BodyNameCol>
          <BodyValueCol $preLine>
            {intelData?.pdns?.records?.join('\n')}
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>IP Geo</BodyGroupTitleRow>
        <BodyItemRow>
          <BodyNameCol>Continent:</BodyNameCol>
          <BodyValueCol>{intelData?.geo?.continentcode}</BodyValueCol>
        </BodyItemRow>
        <BodyItemRow>
          <BodyNameCol>Country:</BodyNameCol>
          <BodyValueCol>
            {intelData?.geo?.countrycode && (
              <BodyCountryCode>
                <Flag countryCode={intelData?.geo.countrycode} />
                <span>{intelData?.geo.countrycode}</span>
              </BodyCountryCode>
            )}
          </BodyValueCol>
        </BodyItemRow>
        <BodyItemRow>
          <BodyNameCol>Subdivision:</BodyNameCol>
          <BodyValueCol>{intelData?.geo?.subdiso}</BodyValueCol>
        </BodyItemRow>
        <BodyItemRow>
          <BodyNameCol>Subdivision B:</BodyNameCol>
          <BodyValueCol>{intelData?.geo?.subdivisionb}</BodyValueCol>
        </BodyItemRow>
        <BodyItemRow>
          <BodyNameCol>City:</BodyNameCol>
          <BodyValueCol>{intelData?.geo?.city}</BodyValueCol>
        </BodyItemRow>
        <BodyItemRow>
          <BodyNameCol>Postal:</BodyNameCol>
          <BodyValueCol>{intelData?.geo?.postal}</BodyValueCol>
        </BodyItemRow>
        <BodyItemRow>
          <BodyNameCol>Coordinates:</BodyNameCol>
          <BodyValueCol>
            {intelData?.geo?.location &&
              `${intelData?.geo?.location.lat}, ${intelData?.geo?.location.lon}`}
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>
    </Col>
  );
};

IpIntelligence.propTypes = {
  padding: PropTypes.string.isRequired,
  ip: PropTypes.string,
  showLabels: PropTypes.bool,
};

IpIntelligence.defaultProps = {
  ip: null,
  showLabels: false,
};

export default IpIntelligence;
