import PropTypes from '+prop-types';
import { memo } from 'react';

export const CloudTwoArrowsIcon = memo((props) => {
  const { size = 24 } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9601_23570)">
        <path
          d="M13.5868 7.58665V8.123C13.597 8.46431 13.4753 8.77637 13.232 9.02991C12.9886 9.27371 12.6641 9.41998 12.3295 9.42973H3.57923C3.04184 9.41023 2.54501 9.19569 2.17999 8.81537C1.81497 8.43505 1.62232 7.94746 1.63246 7.41112C1.62232 6.89427 1.81497 6.40668 2.17999 6.03612C2.54501 5.66555 3.04184 5.44126 3.56909 5.43151H4.64387L4.71484 4.71963C4.8061 3.87122 5.21168 3.09108 5.8606 2.51573C6.50952 1.94037 7.35109 1.60881 8.2535 1.57956C8.76047 1.57956 9.27758 1.70633 9.73386 1.93062C10.1901 2.15491 10.5957 2.47672 10.91 2.86679L11.0216 3.01307H12.9379L12.6033 2.46697C12.1572 1.73559 11.5285 1.12122 10.7681 0.692146C10.0076 0.263068 9.14577 0.029025 8.2028 0.00952148C6.99621 0.0387768 5.83018 0.467855 4.91763 1.20899C4.07606 1.90137 3.47784 2.82778 3.21421 3.86147C2.3625 3.95899 1.58176 4.33931 0.983539 4.94392C0.334616 5.60704 -0.0202638 6.49445 1.50074e-05 7.41112C-0.0202638 8.34729 0.334616 9.2347 1.00382 9.91732C1.67302 10.5999 2.58557 10.9803 3.56909 11.0095H12.3498C12.7453 11.0095 13.1204 10.9218 13.4753 10.7657C13.8302 10.6097 14.1546 10.3952 14.4183 10.1221C14.692 9.84906 14.8948 9.537 15.0368 9.18594C15.1787 8.83488 15.2396 8.46431 15.2396 8.123C15.2396 8.05473 15.2396 7.98647 15.2396 7.91821L15.2193 7.56715L13.5767 7.5964L13.5868 7.58665Z"
          fill="currentColor"
        />
        <path
          d="M9.91635 3.79354V5.2563H13.6882V6.74832L16 4.52492L13.6882 2.30151V3.79354H9.91635Z"
          fill="currentColor"
        />
        <path
          d="M6.87453 6.47511L9.18631 8.69852V7.2065H12.9582V5.74373H9.18631V4.25171L6.87453 6.47511Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9601_23570">
          <rect width="16" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

CloudTwoArrowsIcon.propTypes = {
  size: PropTypes.number,
};

CloudTwoArrowsIcon.defaultProps = {
  size: 24,
};
