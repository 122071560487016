import PropTypes from '+prop-types';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ContextTypes } from '@/models/ContextTypes';

import { selectors as labelsSelectors } from '@/redux/api/labels/ips';

import { Col, LayoutTypes, Row } from '+components/Layout';
import Table from '+components/Table';
import useGlobalFilters from '+hooks/useGlobalFilters';

import { TableContainer } from '../TableContainer';
import { getColumns } from './getColumns';
import { useIpRateData } from './useIpRateData';

export const IpSummary = memo((props) => {
  const { event, isSubAccountRecord } = props;

  const [filters] = useGlobalFilters(ContextTypes.alerts);

  const ipsRates = useIpRateData(event);

  const scoreMinMax = useMemo(
    () =>
      Object.values(ipsRates).reduce(
        ([min, max], rate) => [
          Math.min(min, rate || 0),
          Math.max(max, rate || 0),
        ],
        [Infinity, -Infinity],
      ),
    [ipsRates],
  );

  const columns = useMemo(
    () =>
      getColumns({
        labelContext: filters.labelContext,
        scoreMinMax,
        isSubAccountRecord,
        context: event?.traffic_type || ContextTypes.flow,
      }),
    [
      filters.labelContext,
      ...scoreMinMax,
      isSubAccountRecord,
      event?.traffic_type,
    ],
  );

  const ipLabelsHash = useSelector(labelsSelectors.getIpLabelsHash);

  const ipIntellData = useMemo(
    () =>
      (event?.ipinfo || []).map((item) => {
        const loaded =
          filters.labelContext.show &&
          filters.labelContext.ip === 'name' &&
          item.ipname;

        return {
          ...item,
          ipname:
            ipLabelsHash?.[item.ip]?.[filters.labelContext.ip] || loaded || [],
          customer: event?.customer,
          totalScore: ipsRates?.[item.ip] || null,
        };
      }),
    [event, ipsRates, ipLabelsHash],
  );

  return (
    <Row $type={LayoutTypes.card}>
      <Col item>
        <Row $type={LayoutTypes.titleLarge}>IP Summary</Row>

        <TableContainer item container={false}>
          <Table
            data={ipIntellData}
            columns={columns}
            minRows={1}
            showMenu={false}
            disableDuplicateBy
            isStaticTable
          />
        </TableContainer>
      </Col>
    </Row>
  );
});

IpSummary.displayName = 'IpSummary';

IpSummary.propTypes = {
  event: PropTypes.object,
  isSubAccountRecord: PropTypes.bool,
};

IpSummary.defaultProps = {
  event: undefined,
  isSubAccountRecord: false,
};
