import Palette from '../palette';
import { createThemeSlice } from '../util';

const themeSlice = createThemeSlice('input', (choose) => ({
  border: choose({
    light: '#f2f4f7',
    dark: '#8C8C8C',
  }),
  borderHover: choose({
    light: Palette.primaryLight,
    dark: Palette.primaryDark,
  }),
  borderFocused: choose({
    light: Palette.primaryLight,
    dark: Palette.primaryDark,
  }),
  colorSelect: choose({
    light: '#B4BFD0',
    dark: '#a8a8a8',
  }),
}));

export const { selector, selectors, propsSelector, propsSelectors } =
  themeSlice;
export default themeSlice;
