/* eslint-disable react/prop-types */
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import Tooltip from '+components/Tooltip';

const RowTechnique = styled.div`
  display: flex;
  align-items: left;

  &.column {
    flex-direction: column;
  }

  &.row {
    gap: 10px;
    flex-direction: row;
  }
`;

const TechniqueId = styled.span`
  color: ${({ theme }) => theme.linkColor} !important;
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-color: ${({ theme }) => theme.linkColor} !important;
  text-decoration-thickness: 5%;
  text-underline-position: under;
  text-underline-offset: -1px;
}
`;

const TechniqueName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 80%;
  font-weight: 600;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
`;

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  font-size: 12px;
  color: ${({ theme }) => theme.linkColor} !important;
`;

const CellTechnique = ({ original }) => {
  const cellOrientation =
    original.mitre.techniques?.names?.length === 1 ? 'column' : 'row';

  const cellTechnique =
    original.mitre.techniques !== null &&
    original.mitre.techniques.ids.map((techniqueId, key) => {
      const techniqueName = original.mitre.techniques.names[key];
      const formattedTechniqueId =
        techniqueId.charAt(0).toUpperCase() + techniqueId.slice(1);
      return original.mitre.techniques.ids.length > 1 ? (
        <Tooltip key={techniqueId} title={techniqueName}>
          <StyledNavLink
            to={`https://attack.mitre.org/techniques/${formattedTechniqueId}`}
            target="_blank"
          >
            <TechniqueId>{formattedTechniqueId}</TechniqueId>
            <StyledOpenInNewIcon size={12} />
          </StyledNavLink>
        </Tooltip>
      ) : (
        <StyledNavLink
          key={techniqueId}
          to={`https://attack.mitre.org/techniques/${formattedTechniqueId}`}
          target="_blank"
        >
          <TechniqueId>{formattedTechniqueId}</TechniqueId>
          <StyledOpenInNewIcon size={12} />
        </StyledNavLink>
      );
    });

  const cellTechniqueName =
    original.mitre.techniques?.names?.length === 1 &&
    original.mitre.techniques.names.map((techniqueName) => {
      return (
        <TechniqueName key={`technique-${techniqueName}`}>
          {techniqueName}
        </TechniqueName>
      );
    });

  return (
    <RowTechnique className={cellOrientation}>
      {cellTechnique}
      {cellTechniqueName}
    </RowTechnique>
  );
};

export default CellTechnique;
