const RoutePaths = {
  home: {
    rootPath: '/',
    pageName: '',
  },
  openSourceSoftware: {
    rootPath: '/',
    pageName: 'open-source-software',
  },
  search: {
    rootPath: '/investigate',
    pageName: 'search',
  },
  searchTraffic: {
    rootPath: '/investigate/search',
    pageName: 'traffic',
  },
  searchFlow: {
    rootPath: '/investigate/search',
    pageName: 'flow',
  },
  searchDns: {
    rootPath: '/investigate/search',
    pageName: 'dns',
  },
  searchEvents: {
    // Info: do not forget that this route is used in Notifier in email templates,
    // and we need to update it in case of any changes
    // https://netography.atlassian.net/browse/ESC-61
    rootPath: '/investigate/search',
    pageName: 'events',
  },
  searchBlocks: {
    rootPath: '/investigate/search',
    pageName: 'blocks',
  },
  searchAuditLogs: {
    rootPath: '/admin',
    pageName: 'audit-logs',
  },
  dashboards: {
    rootPath: '/',
    pageName: 'dashboards',
  },
  dashboardsFavorites: {
    rootPath: '/',
    pageName: 'dashboards-favorites',
  },
  dashboardsScheduled: {
    rootPath: '/',
    pageName: 'dashboards-scheduled',
  },
  realTimeFlow: {
    rootPath: '/investigate',
    pageName: 'real-time-flow',
  },
  realTimeTraffic: {
    rootPath: '/investigate',
    pageName: 'real-time-traffic',
  },
  trafficMiner: {
    rootPath: '/investigate',
    pageName: 'traffic-miner',
  },
  networkTop: {
    rootPath: '/investigate',
    pageName: 'network-top',
  },
  trafficTop: {
    rootPath: '/investigate',
    pageName: 'traffic-top',
  },
  ipExplorer: {
    rootPath: '/investigate',
    pageName: 'ip-explorer',
  },
  realTimeFlowMap: {
    rootPath: '/investigate',
    pageName: 'real-time-flow-map',
  },
  ipIntelligence: {
    rootPath: '/investigate',
    pageName: 'ip-intel',
  },
  events: {
    rootPath: '/ndr',
    pageName: 'events',
  },
  eventsAsset: {
    rootPath: '/ndr',
    pageName: 'events',
  },
  eventsDetection: {
    rootPath: '/ndr/events',
    pageName: 'detection',
  },
  eventsList: {
    rootPath: '/ndr/events',
    pageName: 'list',
  },
  eventsMitre: {
    rootPath: '/ndr/events',
    pageName: 'mitre',
  },
  models: {
    rootPath: '/ndr',
    pageName: 'models',
  },
  modelsNetwork: {
    rootPath: '/ndr/models',
    pageName: 'network',
  },
  modelsDetection: {
    rootPath: '/ndr/models',
    pageName: 'detection',
  },
  modelsContext: {
    rootPath: '/ndr/models',
    pageName: 'context',
  },
  cveLookup: {
    rootPath: '/',
    pageName: 'cve-lookup',
  },
  integrationsResponse: {
    rootPath: '/admin',
    pageName: 'response-integrations',
  },
  allowlists: {
    rootPath: '/admin/response-integrations',
    pageName: 'allowlists',
  },
  ipTrustLists: {
    rootPath: '/admin',
    pageName: 'ip-trust-lists',
  },
  ip: {
    rootPath: '/admin/response-integrations',
    pageName: 'allowlists',
  },
  responsePolicies: {
    rootPath: '/admin',
    pageName: 'response-policies',
  },
  sources: {
    rootPath: '/admin',
    pageName: 'sources',
  },
  sourcesDevices: {
    rootPath: '/admin/sources',
    pageName: 'devices',
  },
  sourcesCloudProviders: {
    rootPath: '/admin/sources',
    pageName: 'vpcs',
  },
  trafficClassification: {
    rootPath: '/admin',
    pageName: 'traffic-classification',
  },
  networkClassification: {
    rootPath: '/admin',
    pageName: 'network-classification',
  },
  customers: {
    rootPath: '/admin',
    pageName: 'customers',
  },
  detectionCategories: {
    rootPath: '/admin',
    pageName: 'detection-categories',
  },
  labels: {
    rootPath: '/admin',
    pageName: 'labels',
  },
  labelsPort: {
    rootPath: '/admin/labels',
    pageName: 'port',
  },
  labelsIp: {
    rootPath: '/admin/labels',
    pageName: 'ip',
  },
  integrationsContext: {
    rootPath: '/admin',
    pageName: 'context-integrations',
  },
  flowTags: {
    rootPath: '/admin',
    pageName: 'flow-tags',
  },
  roles: {
    rootPath: '/admin',
    pageName: 'roles',
  },
  users: {
    rootPath: '/admin',
    pageName: 'users',
  },
  profile: {
    rootPath: '/admin',
    pageName: 'profile',
  },
  profilePersonalization: {
    rootPath: '/admin',
    pageName: 'profile-personalization',
  },
  profileSecurity: {
    rootPath: '/admin',
    pageName: 'profile-security',
  },
  profileActivity: {
    rootPath: '/admin',
    pageName: 'profile-activity',
  },
  settings: {
    rootPath: '/admin',
    pageName: 'overview',
  },
  billing: {
    rootPath: '/admin',
    pageName: 'billing',
  },
  apiKeys: {
    rootPath: '/admin',
    pageName: 'api-keys',
  },
  security: {
    rootPath: '/admin',
    pageName: 'security',
  },
  samlSettings: {
    rootPath: '/admin',
    pageName: 'security/samlsettings',
  },
  logout: {
    rootPath: '/auth',
    pageName: 'logout',
  },
  modelOverrides: {
    rootPath: '',
    pageName: 'overrides',
  },
  modelAutoThresholds: {
    rootPath: '',
    pageName: 'autothresholds',
  },
  nqlPresets: {
    rootPath: '/admin',
    pageName: 'nql-presets',
  },
};

// add fullPagePath and override toString() to return the full path
Object.keys(RoutePaths).forEach((key) => {
  const routePath = RoutePaths[key];
  routePath.fullPagePath = [routePath.rootPath, routePath.pageName]
    .filter(Boolean)
    .join('/');
  // replace // with /
  routePath.fullPagePath = routePath.fullPagePath.replace(/\/\//g, '/');
  routePath.toString = () => routePath.fullPagePath;
});

export default RoutePaths;
