import { call, put, select } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  createSelector,
  createSlice,
  pseudoTtlCache,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const FetchStates = {
  error: 'error',
  init: 'init',
  fetching: 'fetching',
  empty: 'empty',
  success: 'success',
};

const setStatus = (statusKey, status) => (state) => {
  state[statusKey] = status;
  if (status !== FetchStates.error) {
    state.error = null;
  }
  return state;
};

const getUrl = (path) => `/mitre/${path}`;

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const tacticsStatusKey = 'mitreTacticsStatus';
const tacticsCacheKey = 'mitreTactics';
const tacticsTtl = 1 * 60 * 60 * 1000; // 1 hr

const initialState = {
  [tacticsStatusKey]: FetchStates.init,
  tactics: [],
};

const slice = createSlice({
  name: 'mitre',
  initialState,

  reducers: {
    fetchTactics: setStatus(tacticsStatusKey, FetchStates.fetching),
    fetchTacticsSuccess(state, { payload: { data: { data } = {} } = {} }) {
      if (!data?.length) {
        setStatus(tacticsStatusKey, FetchStates.empty)(state);
        return;
      }
      pseudoTtlCache.setWithTtl(state, tacticsCacheKey, data, tacticsTtl);
      setStatus(tacticsStatusKey, FetchStates.success)(state);
      state.tactics = data;
    },
    error(state, { payload: { message, statusKey } }) {
      setStatus(statusKey, FetchStates.error)(state);
      state.error = message;
    },
    skip(state, { payload: { statusKey } }) {
      setStatus(statusKey, FetchStates.success)(state);
    },
  },

  sagas: (actions, selectors) => ({
    [actions.fetchTactics]: {
      taker: takeLeading(actions.skip),
      *saga() {
        const state = yield select(selectors.getState);
        if (pseudoTtlCache.isValid(state, tacticsCacheKey)) {
          yield put(
            actions.skip({
              statusKey: tacticsStatusKey,
            }),
          );
          return;
        }

        try {
          initApi();
          const path = getUrl('tactics');
          const response = yield call(api.get, path);
          yield put(actions.fetchTacticsSuccess(response));
        } catch (error) {
          yield put(
            actions.error({
              message: error.message,
              statusKey: tacticsStatusKey,
            }),
          );
          yield put(
            toastActions.error({
              message: 'Error fetching MITRE ATT&CK tactics',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) => state[tacticsStatusKey] === FetchStates.fetching,
    ),
    getTactics: createSelector([getState], (state) => state.tactics),
  }),
});

export const { actions, selectors } = slice;

export default slice;
