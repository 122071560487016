import styled from 'styled-components';

import { Row } from '+components/Layout';

export const TableContainer = styled(Row)`
  width: 100%;

  .pagination-container {
    background: ${({ theme }) => theme.cardBackgroundColor};
  }
`;
