import { css } from 'styled-components';

export const UnderlineMixin = css`
  color: ${({ theme }) => theme.table.underlineTextColor};
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-color: ${({ theme }) => theme.table.underlineTextColor};
  text-decoration-thickness: 5%;
  text-underline-position: under;
  text-underline-offset: -1px;
`;
