import { useEffect, useState } from 'react';
import { useIntersection } from 'react-use';

const useOnScreen = (ref, rootMargin = '0px') => {
  const [isPrintMode, setIsPrintMode] = useState(false);

  const intersection = useIntersection(ref, { rootMargin });

  const isOnScreen = intersection?.isIntersecting;

  useEffect(() => {
    if (!ref.current) {
      return undefined;
    }

    // on print mode
    const printModeOn = () => {
      setIsPrintMode(true);
    };

    const printModeOff = () => {
      setIsPrintMode(false);
    };

    window.addEventListener('beforeChartPrint', printModeOn);
    window.addEventListener('afterChartPrint', printModeOff);

    return () => {
      window.removeEventListener('beforeChartPrint', printModeOn);
      window.removeEventListener('afterChartPrint', printModeOff);
    };
  }, []);

  return isPrintMode || isOnScreen;
};

export default useOnScreen;
