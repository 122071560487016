import styled from 'styled-components';

import Link from '@mui/material/Link';

const GlobalLink = styled(Link)`
  text-decoration: none !important;
  &:hover {
    text-decoration: none;
  }
`;

export default GlobalLink;
