import PropTypes from '+prop-types';
import { Fragment, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import * as HSX from 'react-jsx-highcharts';

import classNames from 'classnames';
import styled, { css } from 'styled-components';

import { findMetricSettings } from '@/models/MetricSettings';

import ScaleTypes from '+components/charts/common/ScaleTypes';
import useShowDataLabelsOnPrint from '+components/charts/common/useShowDataLabelsOnPrint';
import useExportingFilename from '+hooks/useExportingFilename';
import useGlobalFilters from '+hooks/useGlobalFilters';
import dayjs, { DateFormat } from '+utils/dayjs';

import useDefaultPropsHSX from './common/defaultPropsHSX';
import FlagBackgroundMixin from './common/FlagBackgroundMixin';
import {
  defaultSeriesColorIndexFormatter,
  defaultSeriesValueFormatter,
} from './common/formatters';
import { Highcharts } from './common/highcharts';
import { defaultColorVariety, getDefs, lang } from './common/utils';

const defs = getDefs();

const gridLineColor = 'rgba(200,200,200,0.3)';

// if scale is logarithmic, 0 (and negative values) are disallowed by Highcharts.
// the 'hack' is to set those values to 0.001, then have formatters only show 2 decimal points.
// @see https://www.highcharts.com/forum/viewtopic.php?t=39841
const logarithmicScaleMin = 0.001;
const logarithmicDataMap = (data) =>
  (data || []).reduce(
    (acc, point) => [
      ...acc,
      [point[0], point[1] <= 0 ? logarithmicScaleMin : point[1]],
    ],
    [],
  );

/**
 * Sparkline chart
 */
const SparklineChart = styled((props) => {
  const {
    className,
    title,
    subtitle,
    type,
    context,
    series,
    exporting,
    loading,
    tooltip,
    width,
    height,
    colorVariety,
    colorIndex,
    seriesNameFormatter,
    seriesColorIndexFormatter,
    hideTitle,
    hideSubtitle,
    synchronizeTooltip,
    waitingOnNql,
    showMinMax = false,
    scale,
    overrides = {},
    tickPositioner,
    tickYLabelFormat,
  } = props;

  const chartRef = useRef(null);
  const [filters] = useGlobalFilters();
  const Series = type === 'area' ? HSX.AreaSeries : HSX.LineSeries;
  const metric = series?.[0]?.metric;

  const exportingFilename = useExportingFilename(title);

  const defaultProps = useDefaultPropsHSX({ exporting });

  useShowDataLabelsOnPrint(chartRef.current, 'series');

  const plotOptions = useMemo(
    () => ({
      series: {
        dataLabels: { enabled: false },
        animation: false,
        lineWidth: 2,
        shadow: false,
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        marker: {
          enabled: false,
          symbol: 'circle',
          states: {
            hover: {
              radius: 3,
            },
          },
        },
        fillOpacity: 0.25,
        enableMouseTracking: tooltip,
      },
      sparkline: {
        animation: false,
        shadow: false,
        dataLabels: { style: { textShadow: false } },
      },
    }),
    [tooltip],
  );

  const tooltipFormatter = useCallback(
    function () {
      const that = this;
      const { tooltipFormatter: valueFormatter } = findMetricSettings({
        context,
        metric,
      });
      return renderToStaticMarkup(
        <Fragment>
          <span className="tooltip-timestamp">
            {dayjs(that.x).format(DateFormat.minute)}
          </span>
          <span className="tooltip-data">
            {that.points
              .sort((a, b) => b.y - a.y)
              .map((point) => (
                <span key={point.series.colorIndex} className="tooltip-row">
                  <b
                    className={`tooltip-row-marker highcharts-color-${point.series.colorIndex}`}
                  >
                    ▬
                  </b>
                  <span
                    className="tooltip-row-name"
                    dangerouslySetInnerHTML={{ __html: point.series.name }}
                  />
                  <span className="tooltip-row-value">
                    {valueFormatter(point.y ?? 0)}
                  </span>
                </span>
              ))}
          </span>
        </Fragment>,
      );
    },
    [context, metric],
  );

  const dataLabelFormatter = useCallback(
    function () {
      const that = this;
      const { tooltipFormatter: valueFormatter } = findMetricSettings({
        context,
        metric,
      });
      return valueFormatter(that.y ?? 0);
    },
    [context, metric],
  );

  const formatYAxisCb = useCallback(
    (item) => {
      const decimals = scale === ScaleTypes.logarithmic ? 2 : undefined;
      const metricSettings = findMetricSettings({ context, metric });
      const prepared = metricSettings?.yAxisFormatter(
        item.value || 0,
        decimals,
      );
      return (
        tickYLabelFormat?.(item, prepared, metricSettings, decimals) ?? prepared
      );
    },
    [context, metric, scale, tickYLabelFormat],
  );

  const normalizedSeries = useMemo(
    () => (Array.isArray(series) ? series : []),
    [series],
  );

  const onChartCallback = useCallback((chart) => {
    chartRef.current = chart;
  }, []);

  // Dynamically set export file name (for cases when chart title changing dynamically in widgets)
  // @see: https://api.highcharts.com/highcharts/exporting.filename
  // @see: https://www.highcharts.com/forum/viewtopic.php?t=31299
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.options.exporting.filename = exportingFilename;
    }
  }, [chartRef.current, exportingFilename]);

  const yExtents = useMemo(() => {
    let min = Infinity;
    let max = -Infinity;

    series?.forEach((item) => {
      item.data?.forEach(([, value]) => {
        min = Math.min(min, value);
        max = Math.max(max, value);
      });
    });

    return [min, max];
  }, [series]);

  const drawSeries = useCallback(
    () =>
      normalizedSeries.map((item, i) => {
        const seriesName = seriesNameFormatter({
          ...item,
          labelContext: filters.labelContext,
        });
        const seriesId = `${seriesName}_${i}`;
        const seriesColorIndex =
          colorIndex ?? item.colorIndex ?? seriesColorIndexFormatter(item);

        const seriesData =
          scale === ScaleTypes.logarithmic
            ? logarithmicDataMap(item.data)
            : item.data;

        return (
          <Series
            key={seriesId}
            id={seriesId}
            name={seriesName}
            data={seriesData}
            colorIndex={seriesColorIndex}
            // We need this to prevent Highcharts update error #15 https://www.highcharts.com/errors/15/
            jsxOptions={{ updatePoints: false }}
            dataLabels={{
              formatter: dataLabelFormatter,
            }}
          />
        );
      }),
    [
      scale,
      normalizedSeries,
      seriesNameFormatter,
      dataLabelFormatter,
      seriesColorIndexFormatter,
    ],
  );

  return (
    <HSX.HighchartsProvider Highcharts={Highcharts}>
      <HSX.HighchartsSparkline
        {...defaultProps}
        className={classNames(
          className,
          'sparkline-chart',
          `p-${colorVariety ?? defaultColorVariety}`,
          {
            short: width > 360 && height < 180,
            ultrashort: width <= 360 && height < 180,
          },
        )}
        plotOptions={plotOptions}
        defs={type === 'area' ? defs : undefined}
        callback={onChartCallback}
        __colorVariety={colorVariety}
      >
        {!!title && !hideTitle && (
          <HSX.Title align="left" useHTML>
            {title}
          </HSX.Title>
        )}

        {!!subtitle && !hideSubtitle && (
          <HSX.Subtitle useHTML>{subtitle}</HSX.Subtitle>
        )}

        <HSX.Loading isLoading={loading}>
          {waitingOnNql ? lang.waitingOnNql : lang.loading}
        </HSX.Loading>
        <HSX.Tooltip
          enabled={tooltip}
          useHTML
          shared
          stickyTracking={false}
          followPointer={false} // to avoid tooltip jumping @see: https://gitlab.com/netography/portal/-/issues/1155
          stickOnContact // to avoid tooltip jumping @see: https://gitlab.com/netography/portal/-/issues/1155
          crosshairs
          borderRadius={8}
          formatter={tooltipFormatter}
          shadow={false}
          animation={false}
        />

        <HSX.Chart
          type={type}
          marginTop={title && subtitle ? 42 : 27}
          marginLeft={showMinMax ? 50 : undefined}
          marginBottom={showMinMax ? 10 : undefined}
          width={width}
          height={height}
          animation={false}
          reflow={false}
          __synchronizeTooltip={synchronizeTooltip && tooltip}
          __type="sparkline-chart"
          {...(overrides?.chart || {})}
        />

        {showMinMax && (
          <HSX.YAxis
            minPadding={0.2}
            maxPadding={0.2}
            min={yExtents[0]}
            max={yExtents[1]}
            startOnTick={false}
            endOnTick={false}
            gridLineColor={gridLineColor}
            title={{ text: '' }}
            tickPositioner={
              tickPositioner?.(yExtents) ||
              (() => {
                return [yExtents[0], yExtents[1]];
              })
            }
            {...(overrides?.yAxis || {})}
            labels={{
              format: '{value}',
              formatter: formatYAxisCb,
            }}
          >
            {drawSeries()}
          </HSX.YAxis>
        )}
        {!showMinMax && drawSeries()}
      </HSX.HighchartsSparkline>
    </HSX.HighchartsProvider>
  );
})`
  ${(props) =>
    props.flagValue && props.showFlag && FlagBackgroundMixin(props.flagValue)}

  ${(props) =>
    !props.showMinMax &&
    css`
      .highcharts-grid,
      .highcharts-axis {
        display: none !important;
      }
    `}

  .highcharts-graph {
    stroke-width: 1.5px;
  }

  .highcharts-area {
    fill-opacity: 0.6;
  }

  .highcharts-tooltip > span {
    .tooltip-timestamp {
      font-weight: bold;
    }

    .tooltip-data {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .tooltip-row {
      display: flex;
      align-items: center;
    }

    .tooltip-row-marker {
      font-weight: bold;
      margin-right: 4px;
    }

    .tooltip-row-name {
      display: flex;
      align-items: center;
      margin-right: 6px;
    }

    .tooltip-row-value {
      font-weight: bold;
      margin-left: auto;
      white-space: nowrap;
    }

    span + span {
      margin-top: 2px;
    }
  }

  g.highcharts-tooltip[class*='highcharts-color-'] {
    fill: transparent;
    stroke: transparent;
    color: transparent;
  }

  .highcharts-data-label {
    visibility: hidden;
  }
`;

SparklineChart.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Chart title.
   */
  title: PropTypes.string,
  /**
   * Chart subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * Chart type.
   */
  type: PropTypes.oneOf(['area', 'line']),
  /**
   * Chart series.
   */
  series: PropTypes.arrayOf(
    PropTypes.shape({
      metric: PropTypes.string,
    }),
  ),
  /**
   * Chart context.
   */
  context: PropTypes.string,
  /**
   * If true, exporting mode on.
   */
  exporting: PropTypes.bool,
  /**
   * If true, loading overlay will be active.
   */
  loading: PropTypes.bool,
  /**
   * If true, tooltip will be enabled.
   */
  tooltip: PropTypes.bool,
  /**
   * Chart width.
   */
  width: PropTypes.number,
  /**
   * Chart height.
   */
  height: PropTypes.number,
  /**
   * Series color palette variety.
   */
  colorVariety: PropTypes.number,
  /**
   * Series color index.
   */
  colorIndex: PropTypes.number,
  /**
   * Series name formatter.
   */
  seriesNameFormatter: PropTypes.func,
  /**
   * Series color index formatter.
   */
  seriesColorIndexFormatter: PropTypes.func,
  /**
   * If true, chart title will be hidden.
   */
  hideTitle: PropTypes.bool,
  /**
   * If true, chart subtitle will be hidden.
   */
  hideSubtitle: PropTypes.bool,
  /**
   * If true, tooltip will be synchronized between charts.
   */
  synchronizeTooltip: PropTypes.bool,
  /**
   * if true, chart data will not be loaded until user inputs NQL (handled in StatsWrapper)
   * Will show the corresponding message on the chart
   */
  waitingOnNql: PropTypes.bool,

  showMinMax: PropTypes.bool,

  /**
   * Override or extend chart options.
   */
  overrides: PropTypes.shape({}),

  tickPositioner: PropTypes.func,
  tickYLabelFormat: PropTypes.func,

  /**
   * YAxis scale type
   */
  scale: PropTypes.oneOf(Object.values(ScaleTypes)),
};

SparklineChart.defaultProps = {
  className: '',
  title: undefined,
  subtitle: undefined,
  type: 'line',
  context: undefined,
  series: [],
  exporting: true,
  loading: false,
  tooltip: true,
  width: undefined,
  height: 300,
  colorVariety: defaultColorVariety,
  colorIndex: undefined,
  seriesNameFormatter: defaultSeriesValueFormatter,
  seriesColorIndexFormatter: defaultSeriesColorIndexFormatter,
  hideTitle: false,
  hideSubtitle: false,
  synchronizeTooltip: true,
  showMinMax: false,
  overrides: {},
  tickPositioner: undefined,
  scale: ScaleTypes.linear,
  tickYLabelFormat: undefined,
};

export default memo(SparklineChart);
