import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';
import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

import { Breadcrumb } from '+components/Breadcrumb';
import { CardTitle } from '+components/Card';
import ConfirmModal from '+components/ConfirmModal';
import EditPageAuditLogTabs from '+components/EditPageAuditLogTabs';
import { FormSpy } from '+components/form/FinalForm';
import FormWizard, { Step } from '+components/FormWizard';
import { usePageTabs } from '+components/PageTabs';
import useMaxSources from '+hooks/useMaxSources';
import usePermissions from '+hooks/usePermissions';
import useUIProperty from '+hooks/useUIProperty';

import { deviceUItoParams } from '../../utils';
import { BgpFields } from '../DeviceBgpConfig/components/BgpTab/components/Form';
import GeneralFields from './components/GeneralFields';

const unique = (arr) =>
  arr.reduce((acc, el) => (acc.includes(el) ? acc : [...acc, el]), []);

const defaultDeviceValues = ({ maxDownsample, ips }) => {
  return {
    samplerate: 1,
    downsample: maxDownsample ?? 0,
    ips,
  };
};

const DeviceForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deviceid } = useParams();
  const [searchParams] = useSearchParams();
  const ips = searchParams.get('ips');
  const editing = !!deviceid;

  const [, activePageTab] = usePageTabs();
  const [formValues, setFormValues] = useState({});
  const permissions = usePermissions(PermissionModel.Resources.device.value);
  const [isUnderCovered] = useUIProperty('underCover');

  const [isCopyMode, setCopyMode] = useToggle(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { maxDownsample } = useMaxSources();

  const {
    tags: deviceTags,
    isFetching,
    error,
  } = useSelector(deviceSelectors.getState);
  const vpcTags = useSelector(vpcSelectors.getVpcTags);

  const device =
    useSelector(deviceSelectors.getDeviceById(deviceid)) ||
    defaultDeviceValues({ maxDownsample, ips });
  const create = !editing || isCopyMode;
  const canManage = create ? permissions?.create : permissions?.update;
  const canRemove = editing && !isCopyMode && permissions?.delete;
  const currentValues = useMemo(
    () => ({ ...device, ...formValues }),
    [device, formValues],
  );

  const canManageDownsample = maxDownsample == null || isUnderCovered;

  const isBgpRequired = !!(
    currentValues.bgp_addressremote ||
    currentValues.bgp_description ||
    currentValues.bgp_asn
  );

  const normalizedTags = useMemo(() => {
    const flowTags = [...(deviceTags || []), ...(vpcTags || [])];
    return unique(flowTags.map((row) => row?.tag).filter((tag) => !!tag));
  }, [deviceTags, vpcTags]);

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(activePageTab?.id);

      const deviceData = deviceUItoParams(values);

      if (isCopyMode) {
        // prefilled edit form treated as add form
        delete deviceData.id;
        dispatch(deviceActions.deviceAdd(deviceData));
      } else if (deviceid) {
        // normal edit form
        dispatch(deviceActions.deviceUpdate(deviceData));
      } else {
        // normal add form
        dispatch(deviceActions.deviceAdd(deviceData));
      }
    },
    [isCopyMode, deviceid, activePageTab?.id],
  );

  const onCancel = useCallback(() => {
    navigate(`${RoutePaths.sources}/add`);
  }, []);

  const onDone = useCallback(() => {
    navigate(`${RoutePaths.sources}`);
  }, []);

  const onSecondary = useCallback(() => {
    setCopyMode(!isCopyMode);
  }, [isCopyMode]);

  const additionalActions = useMemo(
    () =>
      editing && permissions?.create
        ? [
            {
              text: `Switch to ${isCopyMode ? 'Update' : 'Create'}`,
              onClick: onSecondary,
            },
          ]
        : undefined,
    [permissions, isCopyMode, onSecondary, editing],
  );

  const onDeleteModal = useCallback(() => {
    setShowDeleteModal((prevValue) => !prevValue);
  }, []);

  const onDelete = useCallback(() => {
    dispatch(deviceActions.deviceDelete(deviceid));
    onDone();
  }, [deviceid, onDone]);

  const timer = useRef();
  const onChange = useCallback(({ values }) => {
    timer.current = setTimeout(() => {
      setFormValues(values);
    }, 10);
  }, []);
  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
    [],
  );

  useEffect(() => {
    dispatch(deviceActions.fetchTags());

    if (deviceid) {
      dispatch(deviceActions.deviceFetchById(deviceid));
    }
  }, [deviceid]);

  useEffect(() => {
    dispatch(vpcActions.fetchTags());
  }, []);

  useEffect(() => {
    if (!isSubmitting || isFetching) {
      return;
    }

    if (isSubmitting !== activePageTab?.id) {
      return;
    }

    if (!error) {
      onDone();
    } else {
      setIsSubmitting(false);
    }
  }, [isSubmitting, isFetching, error, activePageTab?.id, onDone]);

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(() => {
    if (editing) {
      setMasqueradeUrl(`${RoutePaths.sources}`);
    }
    return () => {
      setMasqueradeUrl(null);
    };
  }, []);

  return (
    <EditPageAuditLogTabs
      showTabs={editing}
      auditNqlQuery={`class == device && original_id == ${deviceid}`}
      breadcrumb={<Breadcrumb title={`${editing ? 'Edit' : 'Add'} Device`} />}
    >
      <FormWizard
        initialValues={device}
        onSubmit={onSubmit}
        onCancel={onCancel}
        additionalActions={additionalActions}
        confirmButtonText={create ? 'Create' : 'Update'}
        loading={isFetching}
        disabled={isFetching || !canManage}
        deleteButtonText="Delete Device"
        onDelete={onDeleteModal}
        deleteButtonHidden={!editing || isCopyMode}
        deleteButtonDisabled={!canRemove}
      >
        <Step title="General Info">
          <FormSpy subscription={{ values: true }} onChange={onChange} />
          <GeneralFields
            tags={normalizedTags}
            disabled={!canManage}
            canManageDownsample={canManageDownsample}
          />
        </Step>

        {(!editing || isCopyMode) && permissions?.create && (
          <Step title="BGP" optional={!isBgpRequired}>
            <FormSpy subscription={{ values: true }} onChange={onChange} />
            <CardTitle
              head="Add BGP Neighbor"
              subhead={
                <Fragment>
                  Now you can add only one BGP Neighbor.
                  <br />
                  You will be able to add more BGP Neighbor after device
                  created.
                </Fragment>
              }
              style={{ marginLeft: '140px' }}
            />

            <BgpFields
              inputPrefix="bgp"
              values={currentValues}
              required={isBgpRequired}
              disabled={!canManage}
            />
          </Step>
        )}
      </FormWizard>

      {showDeleteModal && (
        <ConfirmModal
          item={device?.name}
          onToggle={onDeleteModal}
          onConfirm={onDelete}
          isOpen
        />
      )}
    </EditPageAuditLogTabs>
  );
};

export default DeviceForm;
