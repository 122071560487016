import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';

import { selectors as globalFiltersSelectors } from '@/redux/globalFilters';

import { usePageTabs } from '+components/PageTabs';
import { useGlobalFilters } from '+hooks';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useStatsRequest from '+hooks/useStatsRequest';
import { makeId } from '+utils';

export const useEventByIp = (event, algorithms) => {
  const [, activePageTab] = usePageTabs();
  const autoRefresh = useSelector(
    globalFiltersSelectors.getAutoRefresh(activePageTab?.id),
  );
  const filtersManualRefresher = useSelector(
    globalFiltersSelectors.getRefresherManualOnly(activePageTab?.id),
  );

  const [filters] = useGlobalFilters(ContextTypes.alerts);

  const algorithmsValues = useMemo(
    () => Object.values(algorithms || {}),
    [algorithms],
  );

  const seriesId = useMemo(
    () => `events-by-ips-${event?.id || makeId()}`,
    [event?.id],
  );

  const period = useMemo(() => {
    const now = Math.floor(Date.now() / 1000);
    const start = event?.start || now;
    const end = event?.end;

    return {
      start: (start - 120) * 1000,
      end: end ? Math.min(end + 120, now) * 1000 : undefined,
    };
  }, [event?.start, event?.end]);

  const request = useMemo(
    () => ({
      seriesId,
      params: {
        start: period.start,
        end: period.end,
        format: 'keymap',
        series: [
          {
            metric: 'counts',
            field: [
              'ip',
              'ndm.name',
              !!filters.customers?.length && 'customer',
            ].filter(Boolean),
            name: 'events-by-ips',
            size: 1000,
          },
        ],
        customers: filters.customers,
      },
    }),
    [JSON.stringify(filters.customers)],
  );

  const { series, isFetching } = useStatsRequest({
    context: ContextTypes.ip,
    requestType: StatsRequest.Types.agg,
    request,
    clearIfRequestChanged: false,
    refresher: filtersManualRefresher,
    stopPollingHeartbeat: !!event.end || !autoRefresh,
  });

  useLoadingIndicator(isFetching);

  const ips = useMemo(
    () =>
      (series?.[0]?.buckets || []).reduce((acc, e) => {
        const key = e.ip;
        let item = acc[key];
        if (!item) {
          item = {
            ip: key,
            algorithms: new Set(),
            customer: new Set(),
          };
          acc[key] = item;
        }

        if (e['ndm.name'] != null) {
          item.algorithms.add(e['ndm.name']);
        }
        item.customer.add(e.customer);

        return acc;
      }, {}),
    [series],
  );

  return useMemo(
    () =>
      Object.values(ips).map((item) => {
        const dms = Array.from(item.algorithms)
          .filter(Boolean)
          .map(
            (name) =>
              algorithmsValues?.find((e) => e.name === name) || { name },
          );

        return {
          ...item,
          algorithms: dms.map((dm) => dm.name).sort(),
          customer: Array.from(item.customer).filter(Boolean),
        };
      }),
    [ips, algorithmsValues],
  );
};
