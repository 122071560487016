import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import styled from 'styled-components';

import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import EyeIcon from 'mdi-react/EyeIcon';

import { ContextTypes } from '@/models/ContextTypes';

import { selectors as customerSelectors } from '@/redux/api/customer';

import { Breadcrumb } from '+components/Breadcrumb';
import Button, { ButtonVariants } from '+components/Button';
import {
  ActionsContainer,
  Col,
  LayoutSizes,
  LayoutTypes,
  Row,
} from '+components/Layout';
import RecordModal from '+components/RecordModal';
import { timestampFormatter } from '+components/Table/Cells/formatters';
import SubAccountTag from '+components/Tag/SubAccountTag';
import {
  useFetchContextRecord,
  useGetContextRecord,
  useIsFetchingContextRecord,
} from '+hooks';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useUIProperty from '+hooks/useUIProperty';
import { DateFormat } from '+utils/dayjs';

import JsonDiffViewer from './components/JsonDiffViewer';
import Name from './components/Name';
import Value from './components/Value';

const Card = styled(Col)`
  gap: 0;
  flex: unset;
  padding: 5px 10px;
  border-radius: 4px;
  border-left: 4px solid ${({ theme }) => theme.auditDetailsCardBorder};
  background: ${({ theme }) => theme.auditDetailsCardBackground};
`;

const fieldNameProps = {
  item: true,
  sx: { maxWidth: 100 },
  $type: LayoutTypes.fieldName,
};

const fieldValueProps = {
  item: true,
  component: Value,
  $type: LayoutTypes.fieldValue,
};

const Index = () => {
  const { id } = useParams();
  const location = useLocation();

  const [recordModalOpened, setRecordModalOpened] = useState(false);

  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const [searchParams] = useSearchParams();
  const customerParam = searchParams.get('customer');
  const timestampParam = searchParams.get('timestamp');

  const auditLog = useGetContextRecord(ContextTypes.audit, id);
  const isFetching = useIsFetchingContextRecord(ContextTypes.audit, id);

  useFetchContextRecord(
    ContextTypes.audit,
    {
      id,
      customer: customerParam,
      timestamp: timestampParam,
    },
    'pageDetails',
    [id, customerParam, timestampParam],
  );

  const showObjectComparison = !!(
    auditLog?.original_object || auditLog?.new_object
  );

  useLoadingIndicator(isFetching);

  const isSubAccountRecord =
    auditLog?.customer && auditLog?.customer !== customer?.shortname;

  const onViewRecordClick = useCallback(() => setRecordModalOpened(true), []);

  const onToggleRecordModal = useCallback(
    () => setRecordModalOpened(false),
    [],
  );

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(() => {
    const rootPath = location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/'),
    );
    setMasqueradeUrl(rootPath);
    return () => {
      setMasqueradeUrl(null);
    };
  }, [location.pathname]);

  if (!auditLog?.id) {
    return (
      <Fragment>
        <Breadcrumb title="Audit Log Details" />

        <Col
          $type={LayoutTypes.cardLegacy}
          alignItems="center"
          justifyContent="center"
          height="80vh"
        >
          {isFetching ? 'Loading...' : 'Audit Log not found'}
        </Col>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Breadcrumb title="Audit Log Details" />

      <ActionsContainer>
        <Button variant={ButtonVariants.text} onClick={onViewRecordClick}>
          View Raw Record
        </Button>
      </ActionsContainer>

      <Col gap={LayoutSizes.groupGap}>
        <Row gap={LayoutSizes.groupGap} wrap="nowrap">
          <Col xs={12} lg={3} gap="10px" $type={LayoutTypes.cardLegacy}>
            <Card $type={LayoutTypes.cardLegacy}>
              <Name>Class</Name>
              <Value>{auditLog?.class}</Value>
            </Card>

            <Card $type={LayoutTypes.cardLegacy}>
              <Name>Subclass</Name>
              <Value>{auditLog?.subclass}</Value>
            </Card>

            <Card $type={LayoutTypes.cardLegacy}>
              <Name>Action</Name>
              <Value>{auditLog?.action}</Value>
            </Card>

            <Card $type={LayoutTypes.cardLegacy}>
              <Name>User</Name>
              <Value>{auditLog?.user}</Value>
            </Card>
          </Col>

          <Col>
            <Row $type={LayoutTypes.cardLegacy} height="100%">
              <Col gap={LayoutSizes.groupGap}>
                <Row $type={LayoutTypes.title}>
                  <EyeIcon size={16} />
                  <span>Overview</span>
                </Row>

                {isSubAccountRecord && (
                  <Row $type={LayoutTypes.field}>
                    <Col {...fieldNameProps}>Account:</Col>
                    <Col {...fieldValueProps}>
                      <SubAccountTag
                        icon={<AccountMultipleIcon size={16} />}
                        context={auditLog.customer}
                        clickable={false}
                      />
                    </Col>
                  </Row>
                )}

                <Row $type={LayoutTypes.field}>
                  <Col {...fieldNameProps}>Timestamp:</Col>
                  <Col {...fieldValueProps}>
                    {auditLog?.timestamp &&
                      timestampFormatter(auditLog.timestamp, DateFormat.minute)}
                  </Col>
                </Row>

                <Row $type={LayoutTypes.field}>
                  <Col {...fieldNameProps}>Description:</Col>
                  <Col {...fieldValueProps}>{auditLog?.description}</Col>
                </Row>

                <Row $type={LayoutTypes.field}>
                  <Col {...fieldNameProps}>Source:</Col>
                  <Col {...fieldValueProps}>{auditLog?.source}</Col>
                </Row>

                <Row $type={LayoutTypes.field}>
                  <Col {...fieldNameProps}>ID:</Col>
                  <Col {...fieldValueProps}>{auditLog?.id}</Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {showObjectComparison && (
          <Row $type={LayoutTypes.cardLegacy}>
            <Col gap={LayoutSizes.groupGap}>
              <Row $type={LayoutTypes.title}>
                <span>Object Comparison</span>
              </Row>
              <JsonDiffViewer auditlog={auditLog} />
            </Col>
          </Row>
        )}
      </Col>

      {recordModalOpened && (
        <RecordModal
          title={`Audit Log Record — ${auditLog?.id}`}
          data={auditLog}
          onToggle={onToggleRecordModal}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default Index;
