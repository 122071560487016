/* eslint-disable max-len */
import PropTypes from '+prop-types';
import { memo } from 'react';

const MicrosoftSentinelIcon = memo(({ size }) => (
  <svg
    viewBox="0 0 18 18"
    version="1.1"
    id="svg8"
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs6">
      <linearGradient
        id="a"
        x1="9"
        y1="16.209999"
        x2="9"
        y2="0.62"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#1b93eb" id="stop1" />
        <stop offset=".21" stopColor="#2095eb" id="stop2" />
        <stop offset=".44" stopColor="#2e9ced" id="stop3" />
        <stop offset=".69" stopColor="#45a7ef" id="stop4" />
        <stop offset=".95" stopColor="#64b6f1" id="stop5" />
        <stop offset="1" stopColor="#6bb9f2" id="stop6" />
      </linearGradient>
    </defs>
    <path
      d="m 16,8.44 c 0,4.57 -5.53,8.25 -6.73,9 a 0.43,0.43 0 0 1 -0.46,0 C 7.57,16.69 2,13 2,8.44 V 2.94 A 0.44,0.44 0 0 1 2.43,2.5 c 4.34,-0.11 3.35,-2 6.57,-2 3.22,0 2.23,1.89 6.53,2 a 0.44,0.44 0 0 1 0.43,0.44 z"
      fill="#1b93eb"
      id="path6"
    />
    <path
      d="m 15.38,8.48 c 0,4.2 -5.07,7.57 -6.17,8.25 a 0.4,0.4 0 0 1 -0.42,0 C 7.69,16.05 2.62,12.68 2.62,8.48 v -5 A 0.41,0.41 0 0 1 3,3 C 6.94,2.89 6,1.17 9,1.17 c 3,0 2.05,1.76 6,1.83 a 0.41,0.41 0 0 1 0.39,0.4 z"
      fill="url(#a)"
      id="path7"
    />
    <path
      d="M 9,6.53 A 2.88,2.88 0 0 1 11.84,9 0.49,0.49 0 0 0 12.33,9.4 h 1.4 a 0.49,0.49 0 0 0 0.5,-0.53 5.26,5.26 0 0 0 -10.46,0 0.49,0.49 0 0 0 0.5,0.53 h 1.4 A 0.49,0.49 0 0 0 6.16,9 2.88,2.88 0 0 1 9,6.53 Z"
      fill="#c3f1ff"
      id="path8"
    />
    <circle cx="9" cy="9.3999996" r="1.91" fill="#ffffff" id="circle8" />
  </svg>
));

MicrosoftSentinelIcon.propTypes = {
  size: PropTypes.number,
};

MicrosoftSentinelIcon.defaultProps = {
  size: 24,
};

export { MicrosoftSentinelIcon };
