/* eslint-disable react/no-array-index-key */
import PropTypes from '+prop-types';
import { Fragment, memo, useCallback, useMemo, useState } from 'react';

import { ContextTypes } from '@/models/ContextTypes';

import { Title } from '@/pages/EventDetails/components/Thresholds/Title';

import { Col, LayoutSizes, LayoutTypes, Row } from '+components/Layout';
import useSynchronizedCharts from '+hooks/useSynchronizedCharts';

import ThresholdChart, { ThresholdFuncs } from './ThresholdChart';
import useThresholdsParse from './useThresholdsParse';

const disallowedFns = [ThresholdFuncs.divide, ThresholdFuncs.multiply];

const getColorIndex = (index) => (index >= 6 ? index + 1 : index);

export const Thresholds = memo((props) => {
  const { event, collapsed, onShowAllClick } = props;

  const [syncRef, setSyncRef] = useState();

  const context = event.traffic_type || ContextTypes.flow;

  const thresholdContext =
    context === ContextTypes.dns
      ? ContextTypes.thresholdDns
      : ContextTypes.thresholdFlow;

  const thresholds = useThresholdsParse(
    event?.threshold,
    thresholdContext,
    event?.id,
  );

  const uniqueThresholds = useMemo(
    () =>
      Object.values(
        (thresholds || []).reduce((acc, threshold) => {
          const { Expr } = threshold;

          if (!acc[Expr]) {
            acc[Expr] = threshold;
          }

          return acc;
        }, {}),
      ).filter((threshold) => !disallowedFns.includes(threshold.Func)),
    [thresholds],
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const currentThreshold = uniqueThresholds?.[currentIndex];

  const onIndexChange = useCallback((index) => {
    setCurrentIndex(index);
  }, []);

  useSynchronizedCharts({ current: syncRef }, collapsed || !syncRef);

  if (!collapsed) {
    return (
      <Fragment>
        <Row item>
          <Col $type={LayoutTypes.card}>
            <Title collapsed={false} onShowAllClick={onShowAllClick} />
          </Col>
        </Row>

        {!!uniqueThresholds?.length && !!event.start && (
          <Row item spacing={LayoutSizes.groupGap} ref={setSyncRef}>
            {uniqueThresholds.map((threshold, cIndex) => (
              <Col
                item
                xs={Math.max(12 / uniqueThresholds.length, 3)}
                container={false}
                key={threshold.Expr}
              >
                <Row $type={LayoutTypes.card}>
                  <ThresholdChart
                    event={event}
                    threshold={threshold}
                    colorIndex={getColorIndex(cIndex)}
                  />
                </Row>
              </Col>
            ))}
          </Row>
        )}
      </Fragment>
    );
  }

  return (
    <Row $type={LayoutTypes.card} flex="1 1 0" minHeight="fit-content">
      <Col item>
        <Title
          collapsed
          max={uniqueThresholds?.length}
          onShowAllClick={uniqueThresholds?.length > 1 ? onShowAllClick : null}
          onIndexChange={onIndexChange}
        />

        {!!event.start && !!currentThreshold && (
          <Row>
            <Col item xs={12} container={false}>
              <ThresholdChart
                event={event}
                threshold={currentThreshold}
                colorIndex={getColorIndex(currentIndex)}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
});

Thresholds.displayName = 'Thresholds';

Thresholds.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string,
    traffic_type: PropTypes.string,
    threshold: PropTypes.string,
    search: PropTypes.arrayOf(PropTypes.string),
    start: PropTypes.number,
    end: PropTypes.number,
  }),
  collapsed: PropTypes.bool,
  onShowAllClick: PropTypes.func,
};
Thresholds.defaultProps = {
  event: null,
  collapsed: true,
  onShowAllClick: undefined,
};
