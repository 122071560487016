import { ContextTypes } from '@/models/ContextTypes';
import * as PropertiesTray from '@/models/PropertiesTray';

import getEventTableFilterValues from '+components/ContextTables/EventTable/getEventTableFilterValues';
import { UniversalCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnFactory,
  MenuColumnPropertiesTrayTrigger,
  NumberColumnFactory,
  TimestampColumnFactory,
  TrafficColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { someOfFieldsFilter } from '+components/Table/FilterTypeFactories';
import { autoRemoveByOperator } from '+components/Table/ReactTable/utils';
import { getColumnsHelper } from '+utils/getColumnsHelper';

export const Columns = {
  menu: 'menu',
  severity: 'severity',
  summary: 'summary',
  technique: 'technique',
  categories: 'categories',
  srcips: 'srcips',
  dstips: 'dstips',
  start: 'start',
  duration: 'duration',
  alerttype: 'alerttype',
  traffictype: 'traffic_type',
  tdm: 'tdm',
};

const columnsCollection = () => {
  const collection = {
    [Columns.menu]: MenuColumnFactory({
      cxActionMenu: (_, original) => (
        <MenuColumnPropertiesTrayTrigger
          title={`Event Details — ${original.id}`}
          dataType={PropertiesTray.DataTypes.record}
          recordType={ContextTypes.alerts}
          value={original}
        />
      ),
    }),
    [Columns.severity]: BaseColumnFactory({
      width: 80,
      ...getEventTableFilterValues(Columns.severity),
    }),
    [Columns.alerttype]: BaseColumnFactory({
      width: 80,
      ...getEventTableFilterValues(Columns.alerttype),
    }),
    [Columns.dstips]: BaseColumnFactory({
      Cell: UniversalCell(Columns.dstips),
      sortType: 'ip',
    }),
    [Columns.duration]: NumberColumnFactory({
      accessor: Columns.duration,
      maxWidth: 80,
      Cell: ({ value, row }) => {
        const original = getRowOriginal(row);
        const localValue =
          value || Math.floor(Date.now() / 1000) - original.timestamp;
        if (Number.isNaN(localValue)) {
          return '-';
        }
        return UniversalCell(Columns.duration)({
          value: localValue,
        });
      },
    }),
    [Columns.srcips]: BaseColumnFactory({
      Cell: UniversalCell(Columns.srcips),
      sortType: 'ip',
    }),
    [Columns.start]: TimestampColumnFactory({
      accessor: Columns.start,
      width: 160,
    }),
    [Columns.summary]: BaseColumnFactory({
      getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
      Cell: ({ row }) =>
        UniversalCell(Columns.tdm)({
          value: {
            id: row?.original?.tdm?.id,
            name: row?.original?.tdm?.name,
            description: row?.original?.summary,
          },
        }),
      realAccessor: ['tdm.name', 'summary'],
      filter: someOfFieldsFilter(
        ['description', 'summary'],
        autoRemoveByOperator,
      ),
    }),
    [Columns.technique]: BaseColumnFactory({
      Header: 'techniques',
      Cell: UniversalCell(Columns.technique),
      width: 200,
    }),
    [Columns.traffictype]: TrafficColumnFactory(),
    [Columns.categories]: BaseColumnFactory({
      Cell: UniversalCell(Columns.categories),
      width: 200,
    }),
  };

  return collection;
};

export const getColumns = getColumnsHelper(columnsCollection);
