import PropTypes from '+prop-types';
import { forwardRef, useMemo } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

const Tag = forwardRef((props, ref) => {
  const { className, color, pill, outlined, uppercased, tag, ...attributes } =
    props;

  const Component = useMemo(
    () => (attributes.href && tag === 'span' ? 'a' : tag),
    [attributes.href, tag],
  );

  return (
    <Component
      {...attributes}
      ref={ref}
      className={classNames(className, 'tag', `tag-${color}`, {
        'tag-outlined': outlined,
        'tag-pill': pill,
        'tag-uppercased': uppercased,
      })}
    />
  );
});

Tag.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  pill: PropTypes.bool,
  outlined: PropTypes.bool,
  uppercased: PropTypes.bool,
  tag: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
      ]),
    ),
  ]),
};

Tag.defaultProps = {
  className: '',
  color: 'secondary',
  pill: false,
  outlined: true,
  uppercased: true,
  tag: 'span',
};

export default styled(Tag)`
  // TODO: Style was moved from label.scss and we need to update colors to our theme.palette and update class names
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 8px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  //line-height: 1;
  min-height: 21px;
  max-height: 21px;
  border-width: 1px;
  border-style: solid;
  width: fit-content;

  transition:
    color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  &.tag-uppercased {
    text-transform: uppercase;
  }

  &.tag.tag-pill,
  &.tag.tag-rounded {
    border-radius: 99999px;
  }

  &.tag.tag-default {
    background-color: #878787;
    color: black;
  }

  &.tag.tag-primary {
    background-color: ${({ theme }) => theme.tagPrimaryBackground};
    border-color: ${({ theme }) => theme.tagPrimaryBorder};
    color: ${({ theme }) => theme.tagPrimaryColor};
  }

  &.tag.tag-secondary {
    background-color: ${({ theme }) => theme.tagSecondaryBackground};
    border-color: ${({ theme }) => theme.tagSecondaryBorder};
    color: ${({ theme }) => theme.tagSecondaryColor};
  }

  &.tag.tag-info {
    background-color: ${({ theme }) => theme.tagInfoBackground};
    border-color: ${({ theme }) => theme.tagInfoBorder};
    color: ${({ theme }) => theme.tagInfoColor};
  }

  &.tag.tag-success {
    background-color: ${({ theme }) => theme.tagSuccessBackground};
    border-color: ${({ theme }) => theme.tagSuccessBorder};
    color: ${({ theme }) => theme.tagSuccessColor};
  }

  &.tag.tag-warning {
    background-color: #f6d874;
    border-color: #f6d874;
    color: black;
  }

  &.tag.tag-error {
    background-color: #ff9f43;
    border-color: #ff9f43;
    color: black;
  }

  &.tag.tag-danger {
    background-color: ${({ theme }) => theme.tagDangerBackground};
    border-color: ${({ theme }) => theme.tagDangerBorder};
    color: ${({ theme }) => theme.tagDangerColor};
  }

  &.tag.tag-srcip {
    background-color: ${({ theme }) => theme.tagSrcipBackground};
    border-color: ${({ theme }) => theme.tagSrcipBorder};
    color: ${({ theme }) => theme.tagSrcipColor};
  }

  &.tag.tag-dstip {
    background-color: ${({ theme }) => theme.tagDstipBackground};
    border-color: ${({ theme }) => theme.tagDstipBorder};
    color: ${({ theme }) => theme.tagDstipColor};
  }

  &.tag.tag-outlined.tag-default {
    background-color: transparent !important;
    border-color: #878787;
    color: #878787;
  }

  &.tag.tag-outlined.tag-primary {
    background-color: ${({ theme }) => theme.tagPrimaryOutlinedBackground};
    border-color: ${({ theme }) => theme.tagPrimaryOutlinedBorder};
    color: ${({ theme }) => theme.tagPrimaryOutlinedColor};
  }

  &.tag.tag-outlined.tag-secondary {
    background-color: ${({ theme }) => theme.tagSecondaryOutlinedBackground};
    border-color: ${({ theme }) => theme.tagSecondaryOutlinedBorder};
    color: ${({ theme }) => theme.tagSecondaryOutlinedColor};
  }

  &.tag.tag-outlined.tag-info {
    background-color: ${({ theme }) => theme.tagInfoOutlinedBackground};
    border-color: ${({ theme }) => theme.tagInfoOutlinedBorder};
    color: ${({ theme }) => theme.tagInfoOutlinedColor};
  }

  &.tag.tag-outlined.tag-success {
    background-color: ${({ theme }) => theme.tagSuccessOutlinedBackground};
    border-color: ${({ theme }) => theme.tagSuccessOutlinedBorder};
    color: ${({ theme }) => theme.tagSuccessOutlinedColor};
  }

  &.tag.tag-outlined.tag-warning {
    background-color: transparent;
    border-color: #f6d874;
    color: #f6d874;
  }

  &.tag.tag-outlined.tag-error {
    background-color: transparent;
    border-color: #ff9f43;
    color: #ff9f43;
  }

  &.tag.tag-outlined.tag-danger {
    background-color: ${({ theme }) => theme.tagDangerOutlinedBackground};
    border-color: ${({ theme }) => theme.tagDangerOutlinedBorder};
    color: ${({ theme }) => theme.tagDangerOutlinedColor};
  }

  .menu-trigger:hover > &.tag {
    &.tag-outlined.tag-default {
      background-color: transparent;
      border-color: #cccccc;
      color: #cccccc;
    }
  }
`;
