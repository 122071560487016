/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from '+prop-types';
import { useCallback, useEffect } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';

const Container = styled.label`
  &.radio-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding-right: 20px;
    margin-bottom: unset !important;

    .radio-btn__radio-custom {
      border: 1px solid #54545a;
      height: 18px;
      width: 18px;
    }

    &:last-child {
      padding-right: 0;
    }

    &:hover {
      .radio-btn__radio-custom {
        border-color: ${({ theme }) => theme.primary};
      }

      .radio-btn__label {
        color: ${({ theme }) => theme.primary};
      }
    }

    &.disabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.4;
    }

    &.radio-btn--colored {
      .radio-btn__radio-custom {
        border: 2px solid ${({ theme }) => theme.primary};
      }
    }

    &.radio-btn--colored-click {
      .radio-btn__radio:checked + .radio-btn__radio-custom {
        background: ${({ theme }) => theme.primary};

        &::before {
          background: #ffffff;
        }
      }

      &.disabled {
        .radio-btn__radio:checked + .radio-btn__radio-custom {
          background: transparent;

          &::before {
            background: ${({ theme }) => theme.primary};
          }
        }
      }
    }

    &.radio-btn--button {
      background: ${({ theme }) => theme.primary};
      min-width: 150px;
      color: #ffffff;
      height: 24px;
      border-radius: 4px;
      transition: all 0.3s;
      display: flex;
      padding: 0 6px;
      width: 100%;

      .radio-btn__radio-custom {
        display: none;
      }

      .radio-btn__label-svg {
        margin: auto 4px auto auto;
        height: 16px;
        line-height: 1;

        svg {
          fill: #ffffff;
          width: 14px;
          height: 14px;
        }

        .radio-btn__label-check {
          display: none;
        }
      }

      .radio-btn__radio:checked ~ .radio-btn__label-svg {
        .radio-btn__label-check {
          display: block;
        }

        .radio-btn__label-uncheck {
          display: none;
        }
      }

      .radio-btn__label {
        margin: auto auto auto 0;
        padding: 0;
        color: #ffffff;
      }

      &:hover {
        background: ${({ theme }) =>
          theme.colorTool.lighten(theme.primary, 0.1)};

        .radio-btn__label {
          color: #ffffff;
        }
      }
    }
  }

  .radio-btn__radio {
    display: none;
  }

  .radio-btn__radio-custom {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: relative;
    transition: all 0.3s;
    border: 1px solid ${({ theme }) => theme.checkboxBorderColor};
  }

  .radio-btn__radio:checked + .radio-btn__radio-custom {
    border-color: ${({ theme }) => theme.primary};
  }

  .radio-btn__label {
    line-height: 18px;
    padding-left: 9px;
    transition: all 0.3s;
    color: ${({ theme }) => theme.colorText};
  }

  .radio-btn__radio-custom,
  .radio-btn__label {
    display: inline-block;
    vertical-align: middle;
  }

  .radio-btn__radio:checked + .radio-btn__radio-custom::before {
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    top: calc(50% - 3px);
    left: calc(50% - 3px);
    background: ${({ theme }) => theme.primary};
    border-radius: 50%;
  }
`;

const RadioButtonField = (props) => {
  const {
    onChange,
    id,
    name,
    value,
    label,
    defaultChecked,
    radioValue,
    disabled,
    className,
  } = props;

  const doChange = useCallback(() => {
    onChange(radioValue);
  }, [onChange, radioValue]);

  useEffect(() => {
    if (defaultChecked) {
      onChange(radioValue);
    }
  }, [defaultChecked, onChange, radioValue]);

  return (
    <Container
      htmlFor={id}
      className={classNames({
        [`radio-btn--${className}`]: !!className,
        'radio-btn': true,
        disabled,
      })}
    >
      <input
        id={id}
        className="radio-btn__radio"
        name={name}
        type="radio"
        onChange={doChange}
        checked={value === radioValue}
        disabled={disabled}
      />
      <span className="radio-btn__radio-custom" />
      {className === 'button' && (
        <span className="radio-btn__label-svg">
          <CheckIcon className="radio-btn__label-check" />
          <CloseIcon className="radio-btn__label-uncheck" />
        </span>
      )}
      <span className="radio-btn__label">{label}</span>
    </Container>
  );
};

RadioButtonField.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  radioValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

RadioButtonField.defaultProps = {
  label: '',
  defaultChecked: false,
  radioValue: '',
  disabled: false,
  className: '',
  value: '',
};

const renderRadioButtonField = (props) => (
  <RadioButtonField
    {...props.input}
    id={props.id}
    label={props.label}
    defaultChecked={props.defaultChecked}
    disabled={props.disabled}
    radioValue={props.radioValue}
    className={props.className}
  />
);

renderRadioButtonField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  radioValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

renderRadioButtonField.defaultProps = {
  label: '',
  defaultChecked: false,
  disabled: false,
  radioValue: '',
  className: '',
};

export default renderRadioButtonField;
