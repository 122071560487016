import PropTypes from '+prop-types';
import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';

import CancelIcon from 'mdi-react/CancelIcon';
import FormatListGroupIcon from 'mdi-react/FormatListGroupIcon';
import GraphIcon from 'mdi-react/GraphIcon';
import IpIcon from 'mdi-react/IpIcon';

import { ContextTypes } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';
import RoutePaths from '@/models/RoutePaths';

import { actions as rulesActions } from '@/redux/api/rules';

import { CloudTwoArrowsIcon } from '@/shared/img/icon';

import AddIpToDiscardForm from '+components/AddIpToDiscardForm';
import { usePageTabs } from '+components/PageTabs';
import Tooltip from '+components/Tooltip';
import useGlobalFilters from '+hooks/useGlobalFilters';
import getNqlByFieldName from '+utils/getNqlByFieldName';
import { getSearchUrl } from '+utils/getSearchUrl';

const Button = styled(IconButton)`
  border: 2px solid currentColor;
  color: ${({ theme }) => theme.palette.primary.main};
  background: transparent !important;
  padding: 2px;
`;

export const Actions = memo((props) => {
  const { original, context, isSubAccountRecord } = props;

  const { ip, dstip, customer } = original;
  const ipFieldType = dstip ? 'dstip' : 'srcip';

  const dispatch = useDispatch();
  const [, , pageTabMethods] = usePageTabs();

  const [filters] = useGlobalFilters(ContextTypes.alerts);

  const { from, to } = filters;

  const nql = useMemo(
    () => getNqlByFieldName({ context, field: 'ip', value: ip }),
    [context, ip],
  );

  const onSearchClick = useCallback(() => {
    pageTabMethods.add(
      getSearchUrl({
        autoRefresh: false,
        period: {
          type: CustomType,
        },
        from,
        to,
        context,
        nql: context === ContextTypes.dns ? nql.dnsNql : nql.flowNql,
        customer: isSubAccountRecord ? customer : undefined,
      }),
    );
  }, [context, customer, isSubAccountRecord, nql, pageTabMethods, from, to]);

  const onTrafficTopClick = useCallback(() => {
    const nqlByField = getNqlByFieldName({
      context,
      field: ipFieldType,
      value: ip,
    });

    const [, search] = getSearchUrl({
      autoRefresh: false,
      period: {
        type: CustomType,
      },
      from,
      to,
      context,
      nql:
        context === ContextTypes.dns ? nqlByField.dnsNql : nqlByField.flowNql,
      customer: isSubAccountRecord ? customer : undefined,
    }).split('?');

    let aggParam = ipFieldType === 'dstip' ? 'srcip' : '';
    if (context === ContextTypes.dns) {
      aggParam = '';
    }
    aggParam = aggParam && `field=${aggParam}&`;

    pageTabMethods.add(`${RoutePaths.trafficTop}?${aggParam}${search}`);
  }, [context, customer, isSubAccountRecord, pageTabMethods]);

  const onIpIntelClick = useCallback(() => {
    const [, search] = getSearchUrl({
      autoRefresh: false,
      period: {
        type: CustomType,
      },
      from,
      to,
      context: ContextTypes.flow,
      customer: isSubAccountRecord ? customer : undefined,
    }).split('?');

    pageTabMethods.add(`${RoutePaths.ipIntelligence}?ip=${ip}&${search}`);
  }, [ip, from, to, customer]);

  const onIpExplorerClick = useCallback(() => {
    const [, search] = getSearchUrl({
      autoRefresh: false,
      period: {
        type: CustomType,
      },
      from,
      to,
      context: ContextTypes.flow,
      customer: isSubAccountRecord ? customer : undefined,
    }).split('?');

    pageTabMethods.add(
      `${RoutePaths.ipExplorer}?ip=${ip}&type=${ipFieldType}&${search}`,
    );
  }, [ip, ipFieldType, from, to, customer]);

  const [discardOpen, setDiscardOpen] = useState(false);
  const onAddToDiscardToggle = useCallback(() => {
    setDiscardOpen((prevValue) => !prevValue);
  }, []);
  const onAddToDiscardSubmit = useCallback((values) => {
    dispatch(rulesActions.updateAlgorithm(values));
    setDiscardOpen(false);
  }, []);

  return (
    <Stack gap="10px" direction="row" alignItems="center">
      <Tooltip
        title={context === ContextTypes.dns ? 'Search DNS' : 'Search Flow'}
      >
        <Stack>
          <Button
            onClick={onSearchClick}
            data-tracking="ip-summary-action-search"
          >
            <FormatListGroupIcon size={16} />
          </Button>
        </Stack>
      </Tooltip>

      <Tooltip title="Traffic Top">
        <Stack>
          <Button
            onClick={onTrafficTopClick}
            data-tracking="ip-summary-action-traffic-top"
          >
            <CloudTwoArrowsIcon size={16} />
          </Button>
        </Stack>
      </Tooltip>
      <Tooltip title="IP Intelligence">
        <Stack>
          <Button
            onClick={onIpIntelClick}
            data-tracking="ip-summary-action-ip-intel"
          >
            <IpIcon size={16} />
          </Button>
        </Stack>
      </Tooltip>
      <Tooltip title="IP Explorer">
        <Stack>
          <Button
            onClick={onIpExplorerClick}
            data-tracking="ip-summary-action-ip-explorer"
          >
            <GraphIcon size={16} />
          </Button>
        </Stack>
      </Tooltip>
      <Tooltip title="Add IP to Discard list">
        <Stack>
          <Button
            data-tracking="ip-summary-action-add-discard"
            onClick={onAddToDiscardToggle}
          >
            <CancelIcon size={16} />
          </Button>
        </Stack>
      </Tooltip>

      {discardOpen && (
        <AddIpToDiscardForm
          ip={ip}
          isOpen
          onSubmit={onAddToDiscardSubmit}
          onToggle={onAddToDiscardToggle}
        />
      )}
    </Stack>
  );
});

Actions.displayName = 'Actions';

Actions.propTypes = {
  original: PropTypes.object.isRequired,
  context: PropTypes.string.isRequired,
  isSubAccountRecord: PropTypes.bool.isRequired,
};
