import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeRenderer } from 'react-table';

import isEqual from 'lodash.isequal';
import isFunction from 'lodash.isfunction';
import omit from 'lodash.omit';

import SettingCategories from '@/models/SettingCategories';

import * as Menu from '+components/Menu';
import { usePageTabs } from '+components/PageTabs';
import useFocusOnRowId from '+hooks/useFocusOnRowId';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import { useUIProperty } from '+hooks/useUIProperty';
import { closest } from '+utils/closest';
import { makeId } from '+utils/general';

import useDelayedState from './hooks/useDelayedState';
import { useExport } from './hooks/useExport';
import { useSilentSettingSave } from './hooks/useSilentSettingSave';
import { useTableSettings } from './hooks/useTableSettings';
import DefaultColumn from './ReactTable/components/DefaultColumn';
import { BaseColumnFactory, ManageColumnProvider } from './Columns';
import { emptySettings, validMinRows } from './constants';
import Pagination from './Pagination';
import ReactTable from './ReactTable';

const columnFactoryHandler = (columnProp) => {
  // check for createdOnFactory and remove it. if it is not createdOnFactory, then we need to wrap it with BaseColumnFactory
  const column = columnProp.createdOnFactory
    ? columnProp
    : BaseColumnFactory(columnProp);
  return omit(column, 'createdOnFactory');
};

const assignColumnId = (column) => {
  const { id: _id, accessor, Header } = column;

  let id = _id;

  if (typeof accessor === 'string') {
    id = _id || accessor;
  }

  if (!_id && typeof Header === 'string' && Header) {
    id = Header;
  }

  if (!id && column.columns) {
    // eslint-disable-next-line no-console
    console.error(column);
    throw new Error('A column ID (or unique "Header" value) is required!');
  }

  if (!id) {
    // eslint-disable-next-line no-console
    console.error(column);
    throw new Error('A column ID (or string accessor) is required!');
  }

  Object.assign(column, { id });

  return column;
};

const makeDefaultColumn = (accessor) => ({ Header: accessor, accessor });

const defaultColumn = DefaultColumn();

const someIncludes = (a, b) =>
  Array.isArray(a) && Array.isArray(b) && a.some((item) => b.includes(item));

const basedEmpty = {
  order: [],
  hidden: [],
  widths: {},
  size: 5,
  selected: [],
  filters: [],
  groupBy: [],
  sortBy: [],
  aggregates: [],
  duplicates: [],
};

const makeEmpty = ({
  based,
  hidden,
  order,
  size,
  filters,
  groupBy,
  sortBy,
}) => ({
  ...based,
  ...(hidden && { hidden }),
  ...(order && { order }),
  ...(size && { size }),
  ...(filters && { filters }),
  ...(groupBy && { groupBy }),
  ...(sortBy && { sortBy }),
});

/**
 * Table displays sets of data.
 */
const Table = (props) => {
  const {
    id,
    title,
    className,
    data,
    minRows,
    pageSize,
    pagination,
    exportingCurrentView,
    exportingAllFields,
    columns,
    hiddenColumns: hiddenColumnsProp,
    columnOrder: columnOrderProp,
    availableColumns,
    showManagerLayout,
    expanded: expandedProp,
    onExpandedChange,
    getTableProps: getTablePropsProp,
    onGetExportData,
    columnsGroupBy,
    filters: filtersProp,
    onFiltersChange,
    groupBy: groupByProp,
    onGroupByChange,
    disableGroupBy,
    disableAggregators,
    sortBy: sortByProp,
    onSortByChange,
    zebra,
    onAggregatesChange,
    onDuplicatesChange,
    onPageSizeChange,
    onPageIndexChange,
    serverPagination,
    showMenu,
    ...otherProps
  } = props;

  const [, activePageTab] = usePageTabs();
  const [guest] = useUIProperty('guest');
  const [focusOnRowId, , removeRowId] = useFocusOnRowId(id);
  const hasRowId = useRef(focusOnRowId);
  const [expanded, saveExpanded, removeExpanded] = useUIProperty(
    id && activePageTab?.id ? `tableExpanded_${id}_${activePageTab?.id}` : null,
    expandedProp,
  );
  const [pageIndex, savePageIndex, removePageIndex] = useUIProperty(
    id && activePageTab?.id
      ? `tablePageIndex_${id}_${activePageTab?.id}`
      : null,
    0,
  );
  const [isColumnDragging, setIsColumnDragging] = useState(false);

  const [settings, saveSettings] = usePortalSettingsValue(
    SettingCategories.tables,
    id || '',
    emptySettings,
  );
  const { lastId: currentPreset = null, prepared } = settings;

  const {
    companyDefaultPresetId,
    defaultPresetId,
    lastAdded,
    presets,
    saveConfig,
    updateConfig,
    removeConfig,
    toggleDefault,
  } = useTableSettings(id);

  const idPreset = currentPreset || companyDefaultPresetId || defaultPresetId;

  useEffect(() => {
    if (id === lastAdded?.tableId) {
      saveSettings({ lastId: lastAdded.id });
    }
  }, [lastAdded]);

  const empty = useRef(
    makeEmpty({
      base: basedEmpty,
      hidden: hiddenColumnsProp,
      order: columnOrderProp,
      size: pageSize,
      filters: filtersProp,
      groupBy: groupByProp,
      sortBy: sortByProp,
    }),
  );

  useEffect(() => {
    empty.current = makeEmpty({
      base: basedEmpty,
      hidden: hiddenColumnsProp,
      order: columnOrderProp,
      size: pageSize,
      filters: filtersProp,
      groupBy: groupByProp,
      sortBy: sortByProp,
    });
  }, [
    pageSize,
    hiddenColumnsProp,
    columnOrderProp,
    filtersProp,
    groupByProp,
    sortByProp,
  ]);

  const {
    order,
    hidden,
    widths,
    size,
    selected,
    filters,
    groupBy,
    sortBy,
    aggregates,
    duplicates,
  } = useMemo(
    () => presets?.[idPreset]?.value || prepared || empty.current,
    [idPreset, presets, prepared],
  );

  const [normalizedId] = useState(id || makeId());
  const [tableInstance, setTableInstance] = useState();
  const [lastId, setLastId] = useState(idPreset);
  const [defaultPreset, setDefaultPreset] = useState({
    ...empty.current,
    order,
    hidden,
    widths,
    size,
    selected,
    filters,
    groupBy,
    sortBy,
    aggregates,
    duplicates,
  });

  const [selectedLocal, setSelectedLocal] = useState([]);
  const [pageSizeLocal, setPageSizeLocal] = useState(size || pageSize || 20);
  const [hiddenColumnsLocal, setHiddenColumnsLocal] = useState(hidden);
  const [orderLocal, setOrderLocal] = useDelayedState(order);
  const [widthsLocal, setWidthsLocal] = useDelayedState(widths);
  const [filtersLocal, setFiltersLocal] = useDelayedState(filters);
  const [groupByLocal, setGroupByLocal] = useDelayedState(groupBy);
  const [sortByLocal, setSortByLocal] = useDelayedState(sortBy);
  const [aggregatesLocal, setAggregatesLocal] = useDelayedState(aggregates);
  const [duplicatesLocal, setDuplicatesLocal] = useDelayedState(duplicates);

  const normalizedMinRows = useMemo(
    () => (minRows < 5 ? minRows : closest(minRows, validMinRows)),
    [minRows],
  );

  const normalizedPageSizeOptions = useMemo(() => validMinRows.slice(1), []);

  const normalizedPageSize = useMemo(
    () => closest(pageSizeLocal, normalizedPageSizeOptions),
    [pageSizeLocal, normalizedPageSizeOptions],
  );

  const normalizedAvailable = useMemo(() => {
    const availableLocal =
      availableColumns?.length || !selectedLocal?.length
        ? availableColumns
        : selectedLocal.map(makeDefaultColumn);
    return (availableLocal || []).map((column) => {
      assignColumnId(column);
      Object.assign(column, { extra: true });
      return column;
    });
  }, [selectedLocal, availableColumns]);

  const allColumns = useMemo(() => {
    if (!columns?.length) {
      return [];
    }

    const fixedColumns = columns.map((column) => {
      assignColumnId(column);
      return column;
    });

    const columnsHash = fixedColumns.reduce(
      (acc, item) => ({ ...acc, [item.id]: item }),
      {},
    );

    const availableHash = normalizedAvailable.reduce(
      (acc, item) => ({ ...acc, [item.id]: item }),
      {},
    );

    const additionalSelected = (selectedLocal || [])
      .map((key) => availableHash[key])
      .filter(Boolean);

    const additionalDuplicatedColumns = (duplicatesLocal || [])
      .map((item) => {
        const found =
          columnsHash[item.duplicateOf] || availableHash[item.duplicateOf];
        if (!found) {
          return null;
        }
        return {
          ...found,
          id: item.id,
          Header: found.Header || found.id,
          realAccessor: found.realAccessor || [found.accessor],
        };
      })
      .filter(Boolean);

    return [
      ...fixedColumns,
      ...additionalSelected,
      ...additionalDuplicatedColumns,
    ].map(columnFactoryHandler);
  }, [columns, selectedLocal, normalizedAvailable, duplicatesLocal]);

  useSilentSettingSave({
    id,
    lastId,
    empty,
    defaultPreset,
    presets,
    prepared,
    saveSettings,
  });

  const { exportData, isExporting } = useExport({
    id,
    title,
    allColumns: tableInstance?.allColumns,
    hiddenColumns: hiddenColumnsLocal,
    filteredRows: tableInstance?.filteredRows,
    onGetExportData,
  });

  const doExpandedChange = useCallback(
    (newValue, ...args) => {
      if (Object.keys(newValue).length) {
        saveExpanded(newValue);
      } else {
        removeExpanded();
      }

      if (isFunction(onExpandedChange)) {
        onExpandedChange(newValue, ...args);
      }
    },
    [onExpandedChange],
  );

  const doPageSizeChange = useCallback(
    (page) => {
      setPageSizeLocal(page);
      onPageSizeChange?.(page);
    },
    [onPageSizeChange],
  );

  const doPageIndexChange = useCallback(
    (index) => {
      if (index) {
        savePageIndex(index);
      } else {
        removePageIndex();
      }
      onPageIndexChange?.(index);
    },
    [onPageIndexChange],
  );

  const onColumnOrderChange = useCallback(
    (newOrder) => setOrderLocal(newOrder),
    [],
  );

  const onColumnWidthsChange = useCallback(
    (newWidths) => setWidthsLocal(newWidths),
    [],
  );

  const onColumnSelect = useCallback(
    (columnId) => () => {
      setSelectedLocal((prev) => {
        if (!prev?.includes(columnId)) {
          return [...(prev || []), columnId];
        }
        return prev;
      });

      setOrderLocal((prev) => {
        if (!prev?.length) {
          return prev;
        }
        return [...prev, columnId];
      });

      setHiddenColumnsLocal((prev) => {
        const index = (prev || []).indexOf(columnId);
        if (index === -1) {
          return prev;
        }
        const result = [...(prev || [])];
        result.splice(index, 1);
        return result;
      });
    },
    [],
  );

  const onHiddenColumnsChange = useCallback((newHidden) => {
    let fixedHidden;
    const removedSelected = [];
    setSelectedLocal((prev) => {
      if (!prev?.length || !someIncludes(newHidden, prev)) {
        return prev;
      }

      const next = [...prev];
      fixedHidden = newHidden.reduce((acc, item) => {
        const index = next.indexOf(item);

        if (index >= 0) {
          removedSelected.push(item);
          next.splice(index, 1);
        } else {
          acc.push(item);
        }

        return acc;
      }, []);

      return next;
    });

    if (removedSelected.length) {
      setOrderLocal((prev) => {
        if (!prev?.length) {
          return prev;
        }

        return prev.filter((item) => !removedSelected.includes(item));
      });
    }

    setHiddenColumnsLocal(fixedHidden || newHidden);
  }, []);

  const doFiltersChange = useCallback(
    (newFilters) => {
      setFiltersLocal(newFilters);
      onFiltersChange?.(newFilters);
    },
    [onFiltersChange],
  );

  const doGroupByChange = useCallback(
    (newGroupBy) => {
      setGroupByLocal(newGroupBy);
      onGroupByChange?.(newGroupBy);
    },
    [onGroupByChange],
  );

  const doSortByChange = useCallback(
    (newSortBy) => {
      setSortByLocal(newSortBy);
      onSortByChange?.(newSortBy);
    },
    [onSortByChange],
  );

  const doAggregatesChange = useCallback(
    (newAggregates) => {
      setAggregatesLocal(newAggregates);
      onAggregatesChange?.(newAggregates);
    },
    [onAggregatesChange],
  );

  const doDuplicatesChange = useCallback(
    (newDuplicates) => {
      setDuplicatesLocal(newDuplicates);
      onDuplicatesChange?.(newDuplicates);
    },
    [onDuplicatesChange, orderLocal],
  );

  const getTableProps = useCallback(
    (...args) => ({
      id: normalizedId,
      ...(isFunction(getTablePropsProp) && getTablePropsProp(...args)),
    }),
    [normalizedId, getTablePropsProp],
  );

  useEffect(() => {
    setLastId(idPreset);
  }, [idPreset]);

  const isDefaultLayout = useMemo(() => {
    const idItem = companyDefaultPresetId || defaultPresetId || null;
    const preset = presets?.[idItem]?.value || empty.current;
    return (
      isEqual(preset.order || [], orderLocal || []) &&
      isEqual(preset.hidden || [], hiddenColumnsLocal || []) &&
      isEqual(preset.selected || [], selectedLocal || []) &&
      isEqual(preset.widths || {}, widthsLocal || {}) &&
      isEqual(preset.size, pageSizeLocal) &&
      isEqual(preset.filters || [], filtersLocal || []) &&
      isEqual(preset.groupBy || [], groupByLocal || []) &&
      isEqual(preset.sortBy || [], sortByLocal || []) &&
      isEqual(preset.aggregates || [], aggregatesLocal || []) &&
      isEqual(preset.duplicates || [], duplicatesLocal || [])
    );
  }, [
    defaultPresetId,
    companyDefaultPresetId,
    presets,
    orderLocal,
    hiddenColumnsLocal,
    selectedLocal,
    widthsLocal,
    pageSizeLocal,
    filtersLocal,
    groupByLocal,
    sortByLocal,
    aggregatesLocal,
    duplicatesLocal,
  ]);

  const resetDefault = useCallback(() => {
    const idItem = companyDefaultPresetId || defaultPresetId || null;
    const preset = presets?.[idItem]?.value || empty.current;
    setOrderLocal(preset.order);
    setHiddenColumnsLocal(preset.hidden);
    setSelectedLocal(preset.selected);
    setWidthsLocal(preset.widths);
    setPageSizeLocal(preset.size);
    setFiltersLocal(preset.filters);
    setGroupByLocal(preset.groupBy);
    setSortByLocal(preset.sortBy);
    setAggregatesLocal(preset.aggregates);
    setDuplicatesLocal(preset.duplicates);
    setLastId(idItem);
  }, [defaultPresetId, companyDefaultPresetId, presets]);

  useEffect(() => {
    setFiltersLocal((prev) => (isEqual(prev, filters) ? prev : filters));
    setDefaultPreset((prev) =>
      isEqual(prev.filters, filters)
        ? prev
        : {
            ...empty.current,
            ...prev,
            filters,
          },
    );
  }, [filters]);

  useEffect(() => {
    setGroupByLocal((prev) => (isEqual(prev, groupBy) ? prev : groupBy));
    setDefaultPreset((prev) =>
      isEqual(prev.groupBy, groupBy)
        ? prev
        : {
            ...empty.current,
            ...prev,
            groupBy,
          },
    );
  }, [groupBy]);

  useEffect(() => {
    setSortByLocal((prev) => (isEqual(prev, sortBy) ? prev : sortBy));
    setDefaultPreset((prev) =>
      isEqual(prev.sortBy, sortBy)
        ? prev
        : {
            ...empty.current,
            ...prev,
            sortBy,
          },
    );
  }, [sortBy]);

  useEffect(() => {
    setAggregatesLocal((prev) =>
      isEqual(prev, aggregates) ? prev : aggregates,
    );
    setDefaultPreset((prev) =>
      isEqual(prev.aggregates, aggregates)
        ? prev
        : {
            ...empty.current,
            ...prev,
            aggregates,
          },
    );
  }, [aggregates]);

  useEffect(() => {
    setDuplicatesLocal((prev) =>
      isEqual(prev, duplicates) ? prev : duplicates,
    );
    setDefaultPreset((prev) =>
      isEqual(prev.duplicates, duplicates)
        ? prev
        : {
            ...empty.current,
            ...prev,
            duplicates,
          },
    );
  }, [duplicates]);

  useEffect(() => {
    setOrderLocal((prev) => (isEqual(prev, order) ? prev : order));
    setDefaultPreset((prev) =>
      isEqual(prev.order, order)
        ? prev
        : {
            ...empty.current,
            ...prev,
            order,
          },
    );
  }, [order]);

  useEffect(() => {
    setWidthsLocal(widths);
    setDefaultPreset((prev) => ({
      ...empty.current,
      ...prev,
      widths,
    }));
  }, [widths]);

  useEffect(() => {
    setHiddenColumnsLocal(hidden);
    setDefaultPreset((prev) => ({
      ...empty.current,
      ...prev,
      hidden,
    }));
  }, [hidden]);

  useEffect(() => {
    setSelectedLocal(selected);
    setDefaultPreset((prev) => ({
      ...empty.current,
      ...prev,
      selected,
    }));
  }, [selected]);

  useEffect(() => {
    setPageSizeLocal(size);
    setDefaultPreset((prev) => ({
      ...empty.current,
      ...prev,
      size,
    }));
  }, [size]);

  useEffect(() => {
    if (!id) {
      return;
    }

    const newState = {
      ...empty.current,
      order: orderLocal,
      hidden: hiddenColumnsLocal,
      widths: widthsLocal,
      size: pageSizeLocal,
      selected: selectedLocal,
      filters: filtersLocal,
      groupBy: groupByLocal,
      sortBy: sortByLocal,
      aggregates: aggregatesLocal,
      duplicates: duplicatesLocal,
    };

    if (isEqual(newState, defaultPreset)) {
      return;
    }

    setDefaultPreset({
      ...defaultPreset,
      ...newState,
    });
  }, [
    id,
    defaultPreset,
    orderLocal,
    hiddenColumnsLocal,
    widthsLocal,
    pageSizeLocal,
    selectedLocal,
    filtersLocal,
    groupByLocal,
    sortByLocal,
    aggregatesLocal,
    duplicatesLocal,
  ]);

  useEffect(
    () => () => {
      if (hasRowId.current) {
        removeRowId();
      }
    },
    [],
  );

  const available = useMemo(() => {
    // prettier-ignore
    if (!(
        tableInstance
        && Array.isArray(normalizedAvailable)
        && normalizedAvailable.length
      )) {
        return null;
      }

    return normalizedAvailable
      .filter((column) => !selectedLocal?.includes(column.id))
      .map((column) => {
        const item = {
          ...defaultColumn,
          ...column,
        };

        item.render = makeRenderer(tableInstance, item);
        item.toggleHidden = onColumnSelect(column.id);

        return item;
      });
  }, [tableInstance, normalizedAvailable, selectedLocal]);

  const showPagination = useMemo(
    () => pagination && data.length > normalizedPageSize,
    [pagination, normalizedPageSize, data],
  );

  const ManageProps = {
    id,
    presets,
    lastId,
    setDefaultPreset,
    setLastId,
    saveSettings,
    defaultPreset,
    resetDefault,
    availableColumns: available,
    exportingCurrentView: showMenu && exportingCurrentView,
    exportingAllFields: showMenu && exportingAllFields,
    exportData,
    isExporting,
    showManagerLayout: guest ? false : showMenu && showManagerLayout,
    saveConfig,
    updateConfig,
    removeConfig,
    toggleDefault,
    columnsGroupBy,
    disableGroupBy,
    isColumnDragging,
    isDefaultLayout,
  };

  return (
    <Menu.Provider>
      <Menu.TriggerMenu />
      <ManageColumnProvider {...ManageProps}>
        <ReactTable
          {...otherProps}
          ref={setTableInstance}
          columns={allColumns}
          filters={filtersLocal}
          groupBy={groupByLocal}
          sortBy={sortByLocal}
          aggregates={aggregatesLocal}
          duplicates={duplicatesLocal}
          getTableProps={getTableProps}
          className={className || ''}
          hiddenColumns={hiddenColumnsLocal}
          columnOrder={orderLocal}
          columnWidths={widthsLocal}
          data={data}
          minRows={normalizedMinRows}
          serverPagination={serverPagination}
          pageSizeOptions={normalizedPageSizeOptions}
          pageSize={normalizedPageSize}
          pageIndex={pageIndex}
          PaginationComponent={(showPagination && Pagination) || null}
          onPageSizeChange={doPageSizeChange}
          onPageIndexChange={doPageIndexChange}
          onColumnOrderChange={onColumnOrderChange}
          onHiddenColumnsChange={onHiddenColumnsChange}
          onColumnWidthsChange={onColumnWidthsChange}
          onFiltersChange={doFiltersChange}
          onGroupByChange={doGroupByChange}
          onSortByChange={doSortByChange}
          onAggregatesChange={doAggregatesChange}
          onDuplicatesChange={doDuplicatesChange}
          focusOnRowId={focusOnRowId}
          expanded={expanded}
          onExpandedChange={doExpandedChange}
          onColumnsDraggingChange={setIsColumnDragging}
          disableGroupBy={disableGroupBy}
          disableAggregators={disableAggregators}
          zebra={zebra}
          showMenu={showMenu}
        />
      </ManageColumnProvider>
    </Menu.Provider>
  );
};

Table.displayName = 'Table';

const ColumnsProps = PropTypes.arrayOf(
  PropTypes.shape({
    accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    id: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape({})),

    filter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    sortType: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    aggregate: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

    // Renderers
    Header: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.func,
    ]),
    Description: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.func,
    ]),
    Cell: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.func,
    ]),
    Aggregated: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.func,
    ]),
    Filter: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.func,
    ]),

    maxWidth: PropTypes.number,
    minWidth: PropTypes.number,
    width: PropTypes.number,

    disableSortBy: PropTypes.bool,
    disableFilters: PropTypes.bool,
    disableGroupBy: PropTypes.bool,
    disableAggregators: PropTypes.bool,
    disableResizing: PropTypes.bool,
    disableDragAndDrop: PropTypes.bool,
    disableHide: PropTypes.bool,
    disableTooltip: PropTypes.bool,

    getProps: PropTypes.func,
    getFilterProps: PropTypes.func,
    getCellProps: PropTypes.func,
  }),
);

Table.propTypes = {
  /**
   * ID for a table, will be used for storing settings and expanded rows, is id for DOM-element.
   */
  id: PropTypes.string,
  /**
   * Table title for exporting file name.
   */
  title: PropTypes.string,
  /**
   * Extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * List of columns.<br />
   * A column should have `accessor`.<br />
   * If `accessor` is function, then you should provide `id`.<br />
   * If a column is a group of columns, then you should provide `id` (or unique `Header` value).
   *
   * There are not all available props described in propTypes definition,<br />
   * more information: https://react-table.tanstack.com/docs/overview
   *
   * @example
   * ```javascript
   * columns=[{
   *   accessor: 'name',
   *   Header: 'Name',
   * }, {
   *   id: 'secondName',
   *   accessor: 'name',
   *   Header: 'Second Name',
   * }, {
   *   id: 'sub',
   *   Header: 'Birthday',
   *   columns: [{
   *     accessor: 'day',
   *   }, {
   *     accessor: 'month',
   *   }, {
   *     accessor: 'year',
   *   }],
   * }]
   * ```
   */
  columns: ColumnsProps.isRequired,
  /**
   * Array of all available columns
   */
  availableColumns: ColumnsProps,
  /**
   * Table data
   */
  data: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Min rows number.
   */
  minRows: PropTypes.oneOf([0, 1, ...validMinRows]),
  /**
   * Page size.
   */
  pageSize: PropTypes.oneOf(validMinRows),
  /**
   * Allows to use pagination
   */
  rowsCount: PropTypes.number,
  pagination: PropTypes.bool,
  serverPagination: PropTypes.bool,
  pageCount: PropTypes.number,
  onPageIndexChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  /**
   * Array of ids of hidden columns
   */
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  /**
   * Array of ids of columns in needed order
   */
  columnOrder: PropTypes.arrayOf(PropTypes.string),
  /**
   * Allows exporting current view to CSV.
   */
  exportingCurrentView: PropTypes.bool,
  /**
   * Allows exporting all fields to CSV.
   */
  exportingAllFields: PropTypes.bool,
  /**
   * Allows Manager Layout
   */
  showManagerLayout: PropTypes.bool,
  /**
   * List of expanded rows, `{ [rowId]: boolean }`, true - expanded
   */
  expanded: PropTypes.shape({}),
  /**
   * Calls each time when `expanded` is changed.
   */
  onExpandedChange: PropTypes.func,
  /**
   * Get props for table
   */
  getTableProps: PropTypes.func,
  /**
   * Allows to overwrite default get export data behaviour.
   * By default current table data uses for export.
   */
  onGetExportData: PropTypes.func,
  columnsGroupBy: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      pattern: PropTypes.shape(),
    }),
  ),
  groupBy: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.shape({
        value: PropTypes.any,
        operator: PropTypes.string,
      }),
    }),
  ),
  onFiltersChange: PropTypes.func,
  onGroupByChange: PropTypes.func,
  disableGroupBy: PropTypes.bool,
  disableAggregators: PropTypes.bool,
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ),
  onSortByChange: PropTypes.func,
  zebra: PropTypes.bool,
  onAggregatesChange: PropTypes.func,
  onDuplicatesChange: PropTypes.func,
  showMenu: PropTypes.bool,
};

Table.defaultProps = {
  id: null,
  title: '',
  className: null,
  availableColumns: [],
  data: [],
  rowsCount: null,
  minRows: validMinRows[0],
  pageSize: validMinRows[2],
  pagination: true,
  serverPagination: false,
  pageCount: null,
  onPageIndexChange: null,
  onPageSizeChange: null,
  hiddenColumns: null,
  columnOrder: null,
  exportingCurrentView: true,
  exportingAllFields: true,
  showManagerLayout: true,
  expanded: null,
  onExpandedChange: null,
  getTableProps: null,
  onGetExportData: null,
  columnsGroupBy: null,
  groupBy: null,
  filters: null,
  onFiltersChange: null,
  onGroupByChange: null,
  disableGroupBy: false,
  disableAggregators: false,
  sortBy: null,
  onSortByChange: null,
  zebra: false,
  onAggregatesChange: null,
  onDuplicatesChange: null,
  showMenu: true,
};

export { technicalColumns } from '+components/Table/constants';
export default Table;
