/* eslint-disable react/no-array-index-key */
import PropTypes from '+prop-types';
import { memo } from 'react';

import { Drawer } from '+components/Drawer';
import { NoData } from '+components/Layout';

import { DrawerBody } from './DrawerBody';

export const DetectionDrawer = memo((props) => {
  const { id, onClose, ...rest } = props;

  return (
    <Drawer title="Model Details" onClose={onClose} {...rest}>
      {!!id && <DrawerBody id={id} onClose={onClose} />}
      {!id && <NoData>No Data</NoData>}
    </Drawer>
  );
});

DetectionDrawer.displayName = 'DetectionDrawer';

DetectionDrawer.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func,
};

DetectionDrawer.defaultProps = {
  id: undefined,
  onClose: undefined,
};
