import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';

import { selectors as trafficTopSelectors } from '@/redux/api/trafficTop';

import { useTrafficTopData } from '@/pages/TrafficTop/useTrafficTopData';
import { useTrafficTopFields } from '@/pages/TrafficTop/useTrafficTopFields';

import { lang } from '+components/charts/common/utils';
import { Field, useFormState } from '+components/form/FinalForm';
import { normalizeSelectValue } from '+components/form/Normalizers';
import AdditionalFiltersDropdownField, {
  AdditionalFiltersDropdownCaptureContainer,
  AdditionalFiltersDropdownCaptureLabel,
  AdditionalFiltersDropdownCaptureValue,
} from '+components/GlobalFilters/Panel/components/AdditionalFiltersDropdownField';
import AdditionalFiltersRowItem from '+components/GlobalFilters/Panel/components/AdditionalFiltersRowItem';
import AdditionalFiltersSeparator from '+components/GlobalFilters/Panel/components/AdditionalFiltersSeparator';
import GlobalFiltersPortal from '+components/GlobalFilters/Portal';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useGlobalFiltersProperty from '+hooks/useGlobalFiltersProperty';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import { timeBounds } from '+utils';

import TrafficTopDNSTable from './components/TrafficTopDNSTable';
import TrafficTopFlowTable from './components/TrafficTopFlowTable';
import TrafficTopTrafficTable from './components/TrafficTopTrafficTable';

const excludeContexts = new Set([ContextTypes.alerts, ContextTypes.blocks]);

const fieldLabel = 'Aggregate by';
const defaultFieldValue = {
  [ContextTypes.flow]: 'dstip',
  [ContextTypes.dns]: 'query.tld',
  [ContextTypes.traffic]: 'srcip',
};

const TableComponents = {
  [ContextTypes.flow]: TrafficTopFlowTable,
  [ContextTypes.dns]: TrafficTopDNSTable,
  [ContextTypes.traffic]: TrafficTopTrafficTable,
};

const getFieldKey = (context) => `traffic_top_field_${context}`;

const GlobalFiltersFields = () => {
  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: ContextTypes.flow,
  });

  const fieldKey = getFieldKey(context);

  const { values } = useFormState({ subscription: { values: true } });

  const { [fieldKey]: field = defaultFieldValue[context] } = values;

  const fields = useTrafficTopFields(context);

  const fieldsOptions = useMemo(() => {
    const options = (fields || []).reduce((acc, item) => {
      acc.push({
        value: item.field,
        label: item.field,
        description: item.description,
      });

      return acc;
    }, []);

    options.unshift({
      value: fieldLabel,
      label: fieldLabel,
      header: true,
    });
    return options;
  }, [fields, context]);

  return (
    <Fragment>
      <AdditionalFiltersRowItem>
        <Field
          component={AdditionalFiltersDropdownField}
          name={fieldKey}
          options={fieldsOptions}
          parse={normalizeSelectValue}
          caption={
            <AdditionalFiltersDropdownCaptureContainer>
              <AdditionalFiltersDropdownCaptureLabel>
                {fieldLabel}
              </AdditionalFiltersDropdownCaptureLabel>
              <AdditionalFiltersDropdownCaptureValue>
                {field}
              </AdditionalFiltersDropdownCaptureValue>
            </AdditionalFiltersDropdownCaptureContainer>
          }
          data-tracking="filter-row-aggregate-by"
        />
      </AdditionalFiltersRowItem>

      <AdditionalFiltersSeparator />
    </Fragment>
  );
};

export default () => {
  const [{ context: selectedContext }] = useGlobalFilters();

  const [searchParams] = useSearchParams();
  const fieldParam = searchParams.get('field');

  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: ContextTypes.flow,
  });

  const [filters] = useGlobalFilters(context);

  const fieldKey = getFieldKey(context);

  const [field] = useGlobalFiltersProperty(
    fieldKey,
    fieldParam || defaultFieldValue[context],
  );
  const isFiltered = field !== defaultFieldValue[context];
  const portalInitialValues = useMemo(() => ({ [fieldKey]: field }), [field]);

  const isFetching = useSelector(trafficTopSelectors.isFetching);

  useLoadingIndicator(isFetching);

  const { start, end } = timeBounds(filters);

  const data = useTrafficTopData(
    context,
    field,
    {
      size: 1000,
      start,
      end,
      ...StatsRequest.makeSearch({
        search: filters.nql,
        intersect: filters.intersect,
      }),
      customers: filters.customers,
    },
    [
      start,
      end,
      JSON.stringify(filters.nql),
      JSON.stringify(filters.intersect),
      JSON.stringify(filters.customers),
      filters.refresher,
    ],
  );

  const TableComponent = useMemo(() => TableComponents[context], [context]);

  const excludeContextsArr = useMemo(
    () => Array.from(excludeContexts),
    [excludeContexts],
  );

  return (
    <Fragment>
      <GlobalFiltersSetting
        nql
        context={context}
        excludeContexts={excludeContextsArr}
        customers
      />

      {!excludeContexts.has(selectedContext) && (
        <GlobalFiltersPortal
          isFiltered={isFiltered}
          initialValues={portalInitialValues}
        >
          <GlobalFiltersFields />
        </GlobalFiltersPortal>
      )}

      <TableComponent
        field={field}
        data={data}
        noDataText={data ? undefined : lang.loading}
      />
    </Fragment>
  );
};
