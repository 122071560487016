/* eslint-disable react/prop-types */
import { Fragment, useCallback, useMemo } from 'react';

import classNames from 'classnames';
import styled, { css } from 'styled-components';

import SliderOrigin from '@mui/material/Slider';

import FormField from '+components/form/FormField';
import Description from '+components/FormWizard/components/Description';
import { Row } from '+components/Layout';
import makeArr from '+utils/makeArr';

import NumberInput from './NumberInput';

const NUMBER_INPUT_WIDTH_PX = 62;

const SliderDescription = styled(Description)`
  font-size: 11px;
  flex-grow: 9;
`;

const InputDescription = styled(Description)`
  font-size: 11px;
  width: ${NUMBER_INPUT_WIDTH_PX}px;
`;

const StyledNumberInput = styled(NumberInput)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const NumberInputContainer = styled.div`
  width: ${NUMBER_INPUT_WIDTH_PX}px;
  margin-left: 20px;
`;

const DescriptionRow = styled(Row)`
  flex-wrap: nowrap;
`;

const valueFormatter = (val) => `${val}`;

const Slider = styled(
  ({
    className,
    leftLabel,
    rightLabel,
    getAriaValueText,
    valueLabelFormat,
    disabled,
    readOnly,
    isRangeSlider,
    ...tail
  }) => (
    <div
      className={classNames(className, 'MuiSlider-container', {
        disabled,
        readOnly,
      })}
    >
      {leftLabel && <span className="MuiSlider-leftLabel">{leftLabel}</span>}
      <SliderOrigin
        valueLabelDisplay="auto"
        {...tail}
        getAriaValueText={getAriaValueText || valueFormatter}
        valueLabelFormat={valueLabelFormat || valueFormatter}
        disabled={disabled}
        readOnly={readOnly}
      />
      {rightLabel && <span className="MuiSlider-rightLabel">{rightLabel}</span>}
    </div>
  ),
)`
  flex-grow: 9;
  height: 100%;
  display: flex;
  align-items: center;
  margin: auto 0;

  .MuiSlider-root {
    padding: unset;
    height: calc(100% - 5px);
  }

  .MuiSlider-thumb {
    height: 14px;
    width: 14px;
    margin-top: 0;
    background-color: ${({ theme }) => theme.sliderTrack};
    margin-left: ${({ min, max, value }) => {
      if (Array.isArray(value)) {
        // TODO: Fix boundaries for range-select
        return undefined;
      }

      switch (value) {
        case min:
          return '6px';
        case max:
          return '-6px';
        default:
          return undefined;
      }
    }};

    &:hover {
      box-shadow: unset;
    }
  }

  .MuiSlider-rail {
    height: 4px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.sliderRail};
    opacity: unset;
  }

  .MuiSlider-track {
    height: 4px;
    background-color: ${({ theme }) => theme.sliderTrack};
    border: unset;
    border-radius: 8px;
    ${(props) => {
      if (Array.isArray(props.value)) {
        return css`
          border-radius: unset;
        `;
      }

      if (props.value > props.min && props.value < props.max) {
        return css`
          border-top-right-radius: unset;
          border-bottom-right-radius: unset;
        `;
      }

      return undefined;
    }}
  }

  .MuiSlider-valueLabelLabel {
    z-index: 0;
    font-size: 12px;
    background: transparent;
    color: ${({ theme }) => theme.sliderValueColor};
  }

  .MuiSlider-leftLabel {
    font-size: 12px;
    text-transform: uppercase;
    white-space: nowrap;
    margin-right: 5px;
  }

  .MuiSlider-rightLabel {
    font-size: 12px;
    text-transform: uppercase;
    white-space: nowrap;
    margin-left: 5px;
  }

  .MuiSlider-markActive {
    height: 4px;
    width: 2px;
    background-color: ${({ theme }) => theme.sliderTrack} !important;
  }

  .MuiSlider-mark {
    height: 4px;
    width: 2px;
    background-color: ${({ theme }) => theme.sliderTrack};
  }

  &.readOnly * {
    pointer-events: none;
  }

  &.disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }

  .MuiSlider-valueLabelOpen {
    background-color: ${({ theme }) => theme.sliderTrack};
  }
`;

const SliderField = (props) => {
  const {
    className,
    input: { value, onChange, ...inputTail },
    showTime,
    type,
    placeholder,
    label,
    helperText,
    focus,
    meta: { touched, error, dirty, submitFailed },
    disabled,
    required,
    isRangeSlider = true,
    showInputField,
    inputHelperText,
    valueLabelFormat,
    min,
    max,
    precision,
    ...tail
  } = props;

  const invalid = error && (dirty || submitFailed) && touched;

  const fixedValue = useMemo(() => {
    if (isRangeSlider) {
      return makeArr(value || [0, 0]);
    }
    return typeof value === 'number' ? value : 0;
  }, [value]);

  const handleSliderChange = useCallback(
    (event, newValue) => onChange(newValue, event),
    [onChange],
  );

  const handleInputChange = useCallback(
    (val) => onChange(val ?? min),
    [min, onChange],
  );

  return (
    <FormField
      label={label}
      error={error}
      invalid={invalid}
      disabled={disabled}
      required={required}
      helperText={!(showInputField && inputHelperText) ? helperText : ''}
    >
      <Row>
        <Row>
          <Slider
            {...inputTail}
            {...tail}
            className={className}
            value={fixedValue ?? min}
            onChange={handleSliderChange}
            isRangeSlider={isRangeSlider}
            valueLabelFormat={valueLabelFormat}
            min={min}
            max={max}
          />

          {showInputField && (
            <NumberInputContainer>
              <StyledNumberInput
                {...tail}
                className={classNames(className, { invalid })}
                value={fixedValue ?? min}
                onChange={handleInputChange}
                controls={false}
                precision={precision ?? 0}
                padInputField={false}
                type="number"
                min={min}
                max={max}
              />
            </NumberInputContainer>
          )}
        </Row>
        {showInputField && inputHelperText && (
          <DescriptionRow>
            <Fragment>
              <SliderDescription>{helperText}</SliderDescription>
              <InputDescription>{inputHelperText}</InputDescription>
            </Fragment>
          </DescriptionRow>
        )}
      </Row>
    </FormField>
  );
};

export { SliderField };
export default Slider;
