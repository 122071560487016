import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';

import { selectors as globalFiltersSelectors } from '@/redux/globalFilters';

import { usePageTabs } from '+components/PageTabs';
import { useGlobalFilters } from '+hooks';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useStatsRequest from '+hooks/useStatsRequest';
import { makeId } from '+utils';

export const useIpRateData = (event) => {
  const [, activePageTab] = usePageTabs();
  const autoRefresh = useSelector(
    globalFiltersSelectors.getAutoRefresh(activePageTab?.id),
  );
  const filtersManualRefresher = useSelector(
    globalFiltersSelectors.getRefresherManualOnly(activePageTab?.id),
  );

  const [filters] = useGlobalFilters(ContextTypes.alerts);

  const seriesId = useMemo(
    () => `ips-rate-${event?.id || makeId()}`,
    [event?.id],
  );

  const period = useMemo(() => {
    const now = Math.floor(Date.now() / 1000);
    const start = event?.start || now;
    const end = event?.end;

    return {
      start: (start - 120) * 1000,
      end: end ? Math.min(end + 120, now) * 1000 : undefined,
    };
  }, [event?.start, event?.end]);

  const request = useMemo(
    () => ({
      seriesId,
      params: {
        start: period.start,
        end: period.end,
        format: 'keymap',
        series: [
          {
            metric: 'total_score',
            field: ['ip', !!filters.customers?.length && 'customer'].filter(
              Boolean,
            ),
            name: 'event-details-ip-rate',
            size: 1000,
          },
        ],
        customers: filters.customers,
      },
    }),
    [JSON.stringify(filters.customers)],
  );

  const { series, isFetching } = useStatsRequest({
    context: ContextTypes.ip,
    requestType: StatsRequest.Types.agg,
    request,
    clearIfRequestChanged: false,
    refresher: filtersManualRefresher,
    stopPollingHeartbeat: !!event.end || !autoRefresh,
  });

  useLoadingIndicator(isFetching);

  return useMemo(() => {
    const { buckets } = series?.[0] || {};

    return (buckets || []).reduce((acc, { ip, count: value }) => {
      acc[ip] = value;
      return acc;
    }, {});
  }, [series]);
};
