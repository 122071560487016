import cloneDeep from 'lodash.clonedeep';

import { ContextTypes } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';
import { TimeDuration, TimePeriods } from '@/models/TimePeriods';

import { createSelector, createSlice } from '@/redux/util';

import getMetricFieldName from '+utils/getMetricFieldName';

const paramMap = {
  context: (value) => {
    if (!value) {
      return null;
    }
    return `context:${value}`;
  },
  nql: (value) => {
    if (!value?.filter(Boolean).length) {
      return null;
    }
    return `nql:${value[0]}`;
  },
  period: (value, filters) => {
    if (!value) {
      return null;
    }
    const periodParams = [`period_type:${value.type}`];
    if (filters.period?.type !== CustomType) {
      periodParams.push(`period_value:${value.value}`);
    }
    return periodParams;
  },
  from: (value, filters) => {
    if (!value) {
      return null;
    }
    if (filters.period?.type !== CustomType) {
      return null;
    }
    return `from:${value}`;
  },
  to: (value, filters) => {
    if (!value) {
      return null;
    }
    if (filters.period?.type !== CustomType) {
      return null;
    }
    return `to:${value}`;
  },
  autoRefresh: (value) => {
    if (value == null) {
      return null;
    }
    return `auto_refresher:${value}`;
  },
  labelContext: (value) => [
    `labels_show:${value.show}`,
    `labels_ip:${value.ip}`,
  ],
  metric: (value) => {
    if (!value) {
      return null;
    }
    return `metric:${value}`;
  },
  customers: (value) => {
    if (!value?.filter(Boolean).length) {
      return null;
    }
    return `customers:${value.join(',')}`;
  },
};

export const makeFilterUrlParamStr = (filters = {}) => {
  const params = [];

  Object.entries(filters).forEach(([key, value]) => {
    const param = paramMap[key]?.(value, filters);
    if (param) {
      if (Array.isArray(param)) {
        params.push(...param);
      } else {
        params.push(param);
      }
    }
  });

  return `(${params.sort().join(',')})`;
};

export const parseFilterUrlParamStr = (param) => {
  const filters = {};

  if (!param || param.length < 2) {
    return filters;
  }

  const parts = param.slice(1, -1).split(',');

  const handlers = {
    context: (value) => {
      filters.context = value;
    },
    nql: (value) => {
      filters.nql = [value].filter(Boolean);
    },
    period_type: (value) => {
      filters.period = filters.period || {};
      filters.period.type = value === CustomType ? CustomType : +value;
    },
    period_value: (value) => {
      filters.period = filters.period || {};
      filters.period.value = +value;
    },
    from: (value) => {
      filters.from = +value;
    },
    to: (value) => {
      filters.to = +value;
    },
    auto_refresher: (value) => {
      filters.autoRefresh = value === 'true';
    },
    labels_show: (value) => {
      filters.labelContext = filters.labelContext || {};
      filters.labelContext.show = value === 'true';
    },
    labels_ip: (value) => {
      filters.labelContext = filters.labelContext || {};
      filters.labelContext.ip = value;
    },
    metric: (value) => {
      filters.metric = value;
    },
    customers: (value) => {
      filters.customers = value.split(',').filter(Boolean);
    },
  };

  parts.forEach((part) => {
    const [key, ...valueParts] = part.split(':');
    const value = valueParts.join('');
    if (handlers[key]) {
      handlers[key](value);
    }
  });

  if ((filters.from || filters.to) && !filters.period?.type) {
    filters.period = { type: CustomType };
  }

  if (filters.period?.type === CustomType) {
    delete filters.period?.value;
  } else {
    delete filters.from;
    delete filters.to;
    if (!filters.period?.value) {
      delete filters.period;
    }
  }

  if (!filters.labelContext?.show || !filters.labelContext?.ip) {
    delete filters.labelContext;
  }

  return filters;
};

const defaultPeriod = TimePeriods[TimeDuration.minute];

const initialState = {
  defaultFilters: {
    period: {
      type: TimeDuration.hour,
      value: 4,
    },
    from: Date.now() - defaultPeriod.period,
    to: Date.now(),
    startIsMin: false,
    endIsNow: false,
    context: ContextTypes.flow,
    // TODO: Move metric, nql and intersect to one object with [context] fields
    metricFlow: 'bitrate',
    metricAlerts: 'alertrate',
    metricBlocks: 'blockrate',
    metricDns: 'queries',
    metricTraffic: 'bitrate',
    nqlFlow: [],
    nqlAlerts: [],
    nqlBlocks: [],
    intersectFlow: [],
    intersectAlerts: [],
    intersectBlocks: [],
    labelContext: { show: true, ip: 'name', port: 'name' },
    customers: [],
    refresher: null,
    refresherAuto: null,
    refresherManual: null,
    autoRefresh: true,
    tabId: null,
  },
  tab: {},
};

const slice = createSlice({
  name: 'globalFilters',
  initialState,

  reducers: {
    setDefaultFilters(state, { payload }) {
      state.defaultFilters = { ...state.defaultFilters, ...payload };
    },

    changeFilter(state, { payload: { tabId: tabIdProp, ...payload } }) {
      const tabId = tabIdProp || window.NetoActivePageTabID;

      if (!tabId || !payload) {
        return;
      }

      if (!state.tab[tabId]) {
        state.tab[tabId] = cloneDeep(state.defaultFilters);
      }

      const override = {};

      if (payload.context && !ContextTypes[payload.context]) {
        override.context = ContextTypes.flow;
      }

      if (payload.period) {
        override.period = {
          ...state.tab[tabId].period,
          ...payload.period,
        };
      }

      if (payload.labelContext) {
        override.labelContext = {
          ...state.tab[tabId].labelContext,
          ...payload.labelContext,
        };
      }

      state.tab[tabId] = {
        ...state.tab[tabId],
        ...payload,
        ...override,
        tabId,
      };
    },

    resetFilter(state, { payload: { tabId: tabIdProp } }) {
      const tabId = tabIdProp || window.NetoActivePageTabID;

      if (!tabId) {
        return;
      }

      state.tab[tabId] = { ...cloneDeep(state.defaultFilters), tabId };
    },

    setMetric(state, { payload: { metric, context } }) {
      const tabId = window.NetoActivePageTabID;
      if (!state.tab[tabId]) {
        state.tab[tabId] = { ...cloneDeep(state.defaultFilters), tabId };
      }

      const fixedContext = context || state.tab[tabId].context;
      if (!ContextTypes[fixedContext]) {
        return;
      }

      const metricField = getMetricFieldName(fixedContext);
      state.tab[tabId][metricField] = metric;
    },

    setAutoRefresh(state, { payload }) {
      const tabId = window.NetoActivePageTabID;
      if (!state.tab[tabId]) {
        state.tab[tabId] = { ...cloneDeep(state.defaultFilters), tabId };
      }
      state.tab[tabId].autoRefresh = payload;
    },

    refreshRefresherAuto(state) {
      const tabId = window.NetoActivePageTabID;
      if (!state.tab[tabId]) {
        state.tab[tabId] = { ...cloneDeep(state.defaultFilters), tabId };
      }

      const now = Date.now();
      state.tab[tabId].refresher = now;
      state.tab[tabId].refresherAuto = now;
    },

    refreshRefresherManual(state) {
      const tabId = window.NetoActivePageTabID;
      if (!state.tab[tabId]) {
        state.tab[tabId] = { ...cloneDeep(state.defaultFilters), tabId };
      }

      const now = Date.now();
      state.tab[tabId].refresher = now;
      state.tab[tabId].refresherManual = now;
    },
  },

  sagas: () => ({}),

  selectors: (selector) => {
    const getDefaultFilters = createSelector(
      [selector],
      (state) => state.defaultFilters,
    );

    const getFilters = createSelector([selector], (state) => {
      const tabId = window.NetoActivePageTabID;
      return state.tab[tabId] || state.defaultFilters;
    });

    const getTabFilters = (tabId) =>
      createSelector(
        [selector],
        (state) => state.tab[tabId] || state.defaultFilters,
      );

    const getRefresher = (tabId) =>
      createSelector(
        [selector],
        (state) => (state.tab[tabId] || state.defaultFilters).refresher,
      );

    const getRefresherManualOnly = (tabId) =>
      createSelector(
        [selector],
        (state) => (state.tab[tabId] || state.defaultFilters).refresherManual,
      );

    const getAutoRefresh = (tabId) =>
      createSelector(
        [selector],
        (state) => (state.tab[tabId] || state.defaultFilters).autoRefresh,
      );

    return {
      getDefaultFilters,
      getFilters,
      getTabFilters,
      getRefresher,
      getRefresherManualOnly,
      getAutoRefresh,
    };
  },
});

export const { selectors, actions } = slice;

export default slice;
