/* eslint-disable no-nested-ternary, react/prop-types, react/no-array-index-key */
import { memo, useCallback } from 'react';

import {
  CloudProvidersSourceTypes,
  DevicesSourceTypes,
} from '@/models/SourceTypes';
import { UnixTimestampFields } from '@/models/UnixTimestampFields';

import LabelTrack from '@/pages/Models/shared/LabelTrack';

import { tcpflagsintDataExtractor } from '+components/charts/common/formatters';
import AlertTypeLabel from '+components/Labels/AlertTypeLabel';
import ContextLabel from '+components/Labels/ContextLabel';
import GenericLabel from '+components/Labels/GenericLabel';
import GeoLabel from '+components/Labels/GeoLabel';
import OwnerAsLabel from '+components/Labels/OwnerAsLabel';
import TCPFlagLabel from '+components/Labels/TCPFlagLabel';
import CellAlgorithmName from '+components/Table/Cells/CellAlgorithmName';
import CellPluginName from '+components/Table/Cells/CellPluginName';
import CellResponsePolicyName from '+components/Table/Cells/CellResponsePolicyName';
import CellTrafficType from '+components/Table/Cells/CellTrafficType';
import {
  durationFormatter,
  flowrateFormatter,
  numberFormatter,
  protocolIntKeyword,
  timestampFormatter,
} from '+components/Table/Cells/formatters';
import { getRowOriginal } from '+components/Table/Columns/utils';
import Tag from '+components/Tag';
import useUIProperty from '+hooks/useUIProperty';
import { DateFormat } from '+utils/dayjs';
import { formatBitRate } from '+utils/format';
import makeArr from '+utils/makeArr';

import CellCaterithm from '../CellCaterithm';
import CellFlowKey from '../CellFlowKey';
import CellGeo from '../CellGeo';
import CellIp from '../CellIp';
import CellIpLabel from '../CellIpLabel';
import CellNqlSearch from '../CellNqlSearch';
import CellOwnerAs from '../CellOwnerAs';
import CellPort from '../CellPort';
import CellPortLabel from '../CellPortLabel';
import CellProtocol from '../CellProtocol';
import CellSeverity from '../CellSeverity';
import CellSite from '../CellSite';
import CellTag from '../CellTag';
import CellTechnique from '../CellTechnique';
import CellTrafficRecord from '../CellTrafficRecord';
import CellTrafficSourceName from '../CellTrafficSourceName';
import CellWrapper from '../CellWrapper';
import { HeaderSubheader, HeaderSubheaderCell } from '../HeaderSubheaderCell';
import ShowMoreWrapper from './components/ShowMoreWrapper';

const getLabelFieldMeta = (field) => {
  const [, , context, direction] = field.split('.');
  return { context, direction };
};

const getLabelFieldData = (fieldMeta, data) => {
  const allDirections = ['src', 'dst'];

  let contexts;
  let directions;
  let labels;

  if (fieldMeta.context && fieldMeta.direction) {
    // label.ip.<context>.<direction> - value will be "[label1]"
    contexts = [fieldMeta.context];
    directions = [fieldMeta.direction];
    labels = {
      [fieldMeta.context]: {
        [fieldMeta.direction]: data,
      },
    };
  }

  if (fieldMeta.context && !fieldMeta.direction) {
    // label.ip.<context> - value will be "{ dst: [label1], src: [label1] }"
    contexts = [fieldMeta.context];
    directions = allDirections;
    labels = {
      [fieldMeta.context]: data,
    };
  }

  if (!fieldMeta.context && !fieldMeta.direction) {
    // label.ip - value will be "{ name: { dst: [label1], src: [label1] } }"
    contexts = Object.keys(data || {});
    directions = allDirections;
    labels = data;
  }

  return {
    contexts,
    directions,
    labels,
  };
};

// eslint-disable-next-line react/prop-types
const Cell = memo(({ field, value, original, options }) => {
  const [hideNav] = useUIProperty('hideNav');

  const onShowMoreLabelsClick = useCallback((e, showMoreContainerElement) => {
    const cellElement = showMoreContainerElement.children?.[0];
    if (!cellElement) {
      return;
    }
    const event = new MouseEvent('click', {
      bubbles: true,
      clientX: e.clientX,
      clientY: e.clientY,
    });
    cellElement.dispatchEvent(event);
  }, []);

  const valueLowerCase = String(value).toLowerCase();
  if (
    !field ||
    valueLowerCase === 'unknown' ||
    valueLowerCase.includes('unique')
  ) {
    return <CellWrapper clickable={false}>{String(value ?? '')}</CellWrapper>;
  }

  if (UnixTimestampFields.includes(field)) {
    return (
      <CellWrapper clickable={false}>
        {timestampFormatter(
          value,
          field === 'flowrtime' ? DateFormat.millisecond : undefined,
        )}
      </CellWrapper>
    );
  }

  // ip labels
  if (field.startsWith('label.ip')) {
    const fieldMeta = getLabelFieldMeta(field);
    const { contexts, directions, labels } = getLabelFieldData(
      fieldMeta,
      value,
    );
    const sharedIp = original[`${fieldMeta.direction ?? ''}ip`];
    const needToDisplayCell = contexts.some((context) =>
      directions.some((direction) => !!labels?.[context]?.[direction]?.length),
    );
    return !needToDisplayCell ? null : (
      <ShowMoreWrapper
        gap={5}
        onShowMore={
          options?.disabled || sharedIp == null || hideNav
            ? null
            : onShowMoreLabelsClick
        }
      >
        {contexts.flatMap((context) =>
          directions.flatMap((direction) => {
            const ipFieldName = `${direction}ip`;
            const ipFieldValue = original[ipFieldName];
            return labels?.[context]?.[direction]?.map((label) => (
              <ContextLabel
                key={`${context}-${direction}-${label}`}
                context={context}
                clickable={!options?.disabled && !hideNav}
              >
                <CellIpLabel
                  field={field}
                  value={label}
                  ipFieldName={ipFieldName}
                  ipFieldValue={ipFieldValue}
                  original={original}
                  disabled={options?.disabled}
                  useDataValueInPropertiesTray={
                    options?.useDataValueInPropertiesTray
                  }
                />
              </ContextLabel>
            ));
          }),
        )}
      </ShowMoreWrapper>
    );
  }

  // port labels
  if (field.startsWith('label.port')) {
    const fieldMeta = getLabelFieldMeta(field);
    const { contexts, directions, labels } = getLabelFieldData(
      fieldMeta,
      value,
    );
    const sharedPort = original[`${fieldMeta.direction ?? ''}port`];
    const needToDisplayCell = contexts.some((context) =>
      directions.some((direction) => !!labels?.[context]?.[direction]?.length),
    );
    return !needToDisplayCell ? null : (
      <ShowMoreWrapper
        gap={5}
        onShowMore={
          options?.disabled || sharedPort == null || hideNav
            ? null
            : onShowMoreLabelsClick
        }
      >
        {contexts.flatMap((context) =>
          directions.flatMap((direction) => {
            const portFieldName = `${direction}port`;
            const portFieldValue = original[portFieldName];
            return labels?.[context]?.[direction]?.map((label) => (
              <ContextLabel
                key={`${context}-${direction}-${label}`}
                context={portFieldValue}
                clickable={!options?.disabled && !hideNav}
              >
                <CellPortLabel
                  field={field}
                  value={label}
                  portFieldName={portFieldName}
                  portFieldValue={portFieldValue}
                  original={original}
                  disabled={options?.disabled}
                  useDataValueInPropertiesTray={
                    options?.useDataValueInPropertiesTray
                  }
                />
              </ContextLabel>
            ));
          }),
        )}
      </ShowMoreWrapper>
    );
  }

  switch (field) {
    // iprep categories are not DM categories
    // case 'srciprep.categories':
    // case 'dstiprep.categories':
    case 'category':
    case 'categories':
    case 'algorithm':
    case 'algorithms': {
      let _field = field;
      if (field === 'categories') {
        _field = 'category';
      }
      if (field === 'algorithms') {
        _field = 'algorithm';
      }
      const arr = makeArr(value);
      return !arr.length ? null : (
        <ShowMoreWrapper>
          {arr.map((item) => (
            <CellCaterithm
              key={item}
              field={_field}
              value={item}
              original={original}
              renderer={HeaderSubheader}
              disabled={options?.disabled}
              withoutDescription={options?.withoutDescription}
            />
          ))}
        </ShowMoreWrapper>
      );
    }

    case 'caterithms': {
      let _field;
      const categories = makeArr(value.categories);
      const algorithms = makeArr(value.algorithms);

      if (categories.includes('all') && algorithms.includes('all')) {
        return (
          <Tag color="secondary" className="text-secondary">
            All
          </Tag>
        );
      }

      let caterithms;
      if (categories.length > 0 && !categories.includes('all')) {
        _field = 'category';
        caterithms = categories;
      } else if (algorithms.length > 0 && !algorithms.includes('all')) {
        _field = 'algorithm';
        caterithms = algorithms;
      } else {
        // should never reach this point.  but if somehow we did, just spit out all non-'all' values
        caterithms = categories.concat(algorithms);
      }

      return !caterithms.length ? null : (
        <ShowMoreWrapper>
          {caterithms.map((caterithm) => (
            <CellCaterithm
              key={caterithm}
              field={_field}
              value={caterithm}
              original={original}
              renderer={HeaderSubheader}
            />
          ))}
        </ShowMoreWrapper>
      );
    }

    case 'srcgeo':
    case 'dstgeo':
    case 'geo': // event details page
      return !value?.countrycode ? null : (
        <ShowMoreWrapper
          gap={5}
          onShowMore={hideNav ? null : onShowMoreLabelsClick}
        >
          <GeoLabel countrycode={value.countrycode} clickable={!hideNav}>
            <CellGeo
              field={field}
              value={value}
              original={original}
              hideUnderline
            />
          </GeoLabel>
        </ShowMoreWrapper>
      );

    case 'answers.rdata':
    case 'datasrc':
    case 'datasrcs':
    case 'flowsrcname': // flow table
    case 'flowsrcnames': {
      // alerts table
      const arr = makeArr(value);
      return !arr.length ? null : (
        <ShowMoreWrapper>
          {arr.map((name) => {
            let type;
            if (DevicesSourceTypes[original?.flowtype]) {
              type = 'device';
            } else if (CloudProvidersSourceTypes[original?.flowtype]) {
              type = 'cloud';
            }
            return (
              <CellTrafficSourceName
                key={`${name}_${type}`}
                field={field}
                value={name}
                original={original}
                type={type}
                disabled={options?.disabled}
              />
            );
          })}
        </ShowMoreWrapper>
      );
    }

    case 'dstip':
    case 'srcip':
    case 'flowsrcip':
    case 'nexthop': // flows table
    case 'ipinfo.ip': // alerts table
    case 'ip': // event details ip info table
    case 'addresslocal': // BGP table
    case 'addressremote': // BGP table
    case 'ipAddress': {
      if (!value) {
        return null;
      }
      if (!options?.showAsLabel) {
        return (
          <CellIp
            field={field}
            value={value}
            original={original}
            fetchLabels={options?.fetchLabels}
            disabled={options?.disabled}
          />
        );
      }
      return (
        <ShowMoreWrapper
          gap={5}
          onShowMore={
            options?.disabled || hideNav ? null : onShowMoreLabelsClick
          }
        >
          <GenericLabel clickable={!options?.disabled && !hideNav}>
            <CellIp
              field={field}
              value={value}
              original={original}
              disabled={options?.disabled}
              fetchLabels={options?.fetchLabels}
              hideUnderline
            />
          </GenericLabel>
        </ShowMoreWrapper>
      );
    }

    case 'dstname': // deprecated field
    case 'srcname': // deprecated field
    case 'dstipname':
    case 'srcipname':
    case 'ipname': // allowlists form
    case 'addresslocalname': // BGP table
    case 'addressremotename': // BGP table
    case 'ipAddressname': {
      // user profile/auth logs table
      let ipFieldName = field.slice(0, -4);
      ipFieldName = `${ipFieldName}${
        ['dstname', 'srcname'].includes(field) ? 'ip' : ''
      }`;
      const ipFieldValue = original[ipFieldName];
      const context = options?.context || 'name';
      return !value?.length ? null : (
        <ShowMoreWrapper
          gap={5}
          onShowMore={
            options?.disabled || !ipFieldValue || hideNav
              ? null
              : onShowMoreLabelsClick
          }
        >
          {value.map((label) => (
            <ContextLabel
              key={label}
              context={context}
              clickable={!options?.disabled && !hideNav}
            >
              <CellIpLabel
                field={field}
                value={label}
                ipFieldName={ipFieldName}
                ipFieldValue={ipFieldValue}
                original={original}
                useDataValueInPropertiesTray={
                  options?.useDataValueInPropertiesTray
                }
                disabled={options?.disabled}
              />
            </ContextLabel>
          ))}
        </ShowMoreWrapper>
      );
    }

    case 'dstips': // events page table
    case 'srcips': {
      // events page table
      const arr = makeArr(value);
      return !arr.length ? null : (
        <ShowMoreWrapper>
          {arr.map((val, i) => (
            <CellIp
              key={`${val}_${i}`}
              field={field}
              value={val}
              original={original}
              fetchLabels={options?.fetchLabels}
            />
          ))}
        </ShowMoreWrapper>
      );
    }

    case 'dstipnames': // events page table
    case 'srcipnames': {
      // events page table
      const arr = makeArr(value);
      const ipFieldName = ['dstipnames', 'srcipnames'].includes(field)
        ? `${field.slice(0, -5)}s`
        : field.slice(0, -5);
      const ipsFieldValue = original[ipFieldName] || [];
      return !arr.length ? null : (
        <ShowMoreWrapper gap={5}>
          {arr.reduce((acc, item, i) => {
            if (!item?.length) {
              return acc;
            }
            const ipFieldValue = ipsFieldValue[i];
            const context = 'name';
            return [
              ...acc,
              ...item.map((label) => (
                <ContextLabel
                  key={`${label}_${i}`}
                  context={context}
                  clickable={!hideNav}
                >
                  <CellIpLabel
                    field={field}
                    value={label}
                    ipFieldName={ipFieldName}
                    ipFieldValue={ipFieldValue}
                    original={original}
                    useDataValueInPropertiesTray={
                      options?.useDataValueInPropertiesTray
                    }
                  />
                </ContextLabel>
              )),
            ];
          }, [])}
        </ShowMoreWrapper>
      );
    }

    case 'srcport':
    case 'dstport': {
      if (value == null) {
        return null;
      }
      if (!options?.showAsLabel) {
        return (
          <CellPort
            field={field}
            value={value}
            original={original}
            fetchLabels={options?.fetchLabels}
          />
        );
      }
      return (
        <ShowMoreWrapper
          gap={5}
          onShowMore={
            options?.disabled || hideNav ? null : onShowMoreLabelsClick
          }
        >
          <GenericLabel clickable={!options?.disabled && !hideNav}>
            <CellPort
              field={field}
              value={value}
              original={original}
              disabled={options?.disabled}
              fetchLabels={options?.fetchLabels}
              hideUnderline
            />
          </GenericLabel>
        </ShowMoreWrapper>
      );
    }

    case 'port': {
      const labelFieldName = `${field}name`;
      const labels = original[labelFieldName];
      return value == null ? null : (
        <HeaderSubheader
          header={
            <CellPort
              field={field}
              value={value}
              original={original}
              fetchLabels={options?.fetchLabels}
            />
          }
          subHeader={
            !labels?.length ? null : (
              <ShowMoreWrapper gap={5}>
                {labels.map((label) => (
                  <ContextLabel key={label} context={value}>
                    {label}
                  </ContextLabel>
                ))}
              </ShowMoreWrapper>
            )
          }
        />
      );
    }

    case 'portname':
    case 'srcportname':
    case 'dstportname': {
      const portFieldName = field.slice(0, -4);
      const portFieldValue = original[portFieldName];
      return !value?.length ? null : (
        <ShowMoreWrapper
          gap={5}
          onShowMore={
            options?.disabled || portFieldValue == null || hideNav
              ? null
              : onShowMoreLabelsClick
          }
        >
          {value.map((label) => (
            <ContextLabel
              key={label}
              context={portFieldValue}
              clickable={!options?.disabled && !hideNav}
            >
              <CellPortLabel
                field={field}
                value={label}
                portFieldName={portFieldName}
                portFieldValue={portFieldValue}
                original={original}
                disabled={options?.disabled}
                useDataValueInPropertiesTray={
                  options?.useDataValueInPropertiesTray
                }
              />
            </ContextLabel>
          ))}
        </ShowMoreWrapper>
      );
    }

    case 'srcports': // events page table
    case 'dstports': {
      // events page table
      const arr = makeArr(value);
      return !arr.length ? null : (
        <ShowMoreWrapper>
          {arr.map((val, i) => (
            <CellPort
              key={`${val}_${i}`}
              field={field}
              value={val}
              original={original}
              fetchLabels={options?.fetchLabels}
            />
          ))}
        </ShowMoreWrapper>
      );
    }

    case 'srcportnames': // events page table
    case 'dstportnames': {
      // events page table
      const arr = makeArr(value);
      const portFieldName = `${field.slice(0, -5)}s`;
      const portsFieldValue = original[portFieldName] || [];
      return !arr.length ? null : (
        <ShowMoreWrapper gap={5}>
          {arr.reduce((acc, item, i) => {
            if (!item?.length) {
              return acc;
            }
            const portFieldValue = portsFieldValue[i];
            return [
              ...acc,
              ...item.map((label) => (
                <ContextLabel key={`${label}_${i}`} clickable={!hideNav}>
                  <CellPortLabel
                    field={field}
                    value={label}
                    portFieldName={portFieldName}
                    portFieldValue={portFieldValue}
                    original={original}
                    useDataValueInPropertiesTray={
                      options?.useDataValueInPropertiesTray
                    }
                  />
                </ContextLabel>
              )),
            ];
          }, [])}
        </ShowMoreWrapper>
      );
    }

    case 'labels': {
      const { hasdefault, default: isDefault } = original;
      const item =
        hasdefault != null && isDefault != null
          ? { hasdefault, default: isDefault }
          : null;
      return !value?.length ? null : (
        <ShowMoreWrapper gap={5}>
          {value.map((label) => (
            <ContextLabel key={label} context={item.protocol || item.context}>
              {label}
            </ContextLabel>
          ))}
        </ShowMoreWrapper>
      );
    }

    case 'protocol': {
      return !value ? null : (
        <CellProtocol
          field={field}
          value={value}
          original={original}
          disabled={options?.disabled}
        />
      );
    }

    case 'protocolint': {
      return (
        <HeaderSubheader
          header={
            <CellFlowKey field={field} value={value} original={original} />
          }
          subHeader={protocolIntKeyword(value)}
        />
      );
    }

    case 'srcas':
    case 'dstas':
    case 'dstowneras':
    case 'srcowneras': {
      return value?.number == null ? null : (
        <ShowMoreWrapper
          gap={5}
          onShowMore={hideNav ? null : onShowMoreLabelsClick}
        >
          <OwnerAsLabel number={value.number} clickable={!hideNav}>
            <CellOwnerAs
              field={field}
              value={value}
              original={original}
              hideUnderline
            />
          </OwnerAsLabel>
        </ShowMoreWrapper>
      );
    }

    case 'dstowneras.org':
    case 'dstowneras.number':
    case 'srcowneras.org':
    case 'srcowneras.number':
    case 'srcgeo.countrycode':
    case 'dstgeo.countrycode':
      return <CellFlowKey field={field} value={value} original={original} />;

    case 'tag':
    case 'tags': {
      const arr = makeArr(value).filter(Boolean).sort();
      return !arr.length ? null : (
        <ShowMoreWrapper gap={5}>
          {arr.map((tag) => (
            <CellTag key={tag} field={field} value={tag} original={original} />
          ))}
        </ShowMoreWrapper>
      );
    }

    case 'answers.class':
    case 'query.class':
    case 'answers.type':
    case 'query.type': {
      const arr = Array.from(new Set(makeArr(value).filter(Boolean))).sort();
      return !arr.length ? null : (
        <ShowMoreWrapper gap={5}>
          {arr.map((item) => (
            <CellTag
              key={item}
              field={field}
              value={item}
              original={original}
              disabled={options?.disabled}
            />
          ))}
        </ShowMoreWrapper>
      );
    }

    case 'trafficRecord': {
      return <CellTrafficRecord value={value} />;
    }

    case 'technique': {
      return <CellTechnique original={original} />;
    }

    case 'alerttype':
      return !value ? null : <AlertTypeLabel alerttype={value} active />;

    case 'search':
      return makeArr(value).map((search, i) => (
        <CellNqlSearch
          key={i}
          value={search}
          original={original}
          disabled={options?.disabled}
          context={options?.context}
        />
      ));

    case 'severity': {
      // events table
      const arr = makeArr(value);
      return !arr.length ? null : (
        <ShowMoreWrapper>
          {arr.map((val) => (
            <CellSeverity
              key={val}
              field={field}
              value={val}
              original={original}
              disabled={options?.disabled}
            />
          ))}
        </ShowMoreWrapper>
      );
    }

    case 'thresholds': {
      // tdm table
      const arr = makeArr(value);
      return !arr.length ? null : (
        <ShowMoreWrapper>
          {arr.map((val, index) => (
            <CellSeverity
              key={`${val.severity}-${index}`}
              field="severity"
              value={val.severity}
              original={original}
              disabled={options?.disabled}
            />
          ))}
        </ShowMoreWrapper>
      );
    }

    case 'site':
    case 'region':
    case 'sites':
      return makeArr(value).map((site) => (
        <CellSite
          key={site}
          field={field}
          value={site}
          original={original}
          disabled={options?.disabled}
        />
      ));

    case 'rules': {
      const arr = makeArr(value);
      return !arr.length ? null : (
        <ShowMoreWrapper gap={5}>
          {arr.map((item) => {
            if (typeof item === 'string') {
              return <span key={`rule-key-${item}`}>{item}</span>;
            }

            return (
              <CellResponsePolicyName
                key={item.id}
                id={item.id}
                name={item.name}
                description={item.description}
                disabled={options?.disabled}
              />
            );
          })}
        </ShowMoreWrapper>
      );
    }

    case 'rules.plugins': {
      const arr = makeArr(value);
      return !arr.length ? null : (
        <ShowMoreWrapper gap={5}>
          {arr.map((item) => (
            <CellPluginName
              key={item.id}
              id={item.id}
              name={item.name}
              description={item.description}
              disabled={options?.disabled}
            />
          ))}
        </ShowMoreWrapper>
      );
    }

    case 'tcpflags':
    case 'tcpflagsint': {
      const arr =
        field === 'tcpflags'
          ? Object.entries(value || {})
          : value
            ? tcpflagsintDataExtractor(value).reduce(
                (ac, a) => [...ac, [a, true]],
                [],
              )
            : [];
      return !arr.length ? null : (
        <ShowMoreWrapper gap={5}>
          {arr.reduce((acc, [flag, flagValue]) => {
            if (options?.showAll || flagValue) {
              return [
                ...acc,
                <TCPFlagLabel
                  key={flag}
                  $flag={flagValue && flag.toLowerCase()}
                >
                  {flag.toUpperCase()}
                </TCPFlagLabel>,
              ];
            }
            return acc;
          }, [])}
        </ShowMoreWrapper>
      );
    }

    case 'track_by':
      return !value ? null : <LabelTrack fields={value} />;

    case 'bits':
    case 'packets':
      return (
        <CellWrapper clickable={false}>{flowrateFormatter(value)}</CellWrapper>
      );

    case 'duration':
      return (
        <CellWrapper clickable={false}>
          {durationFormatter(value, options?.unit ?? 'milliseconds')}
        </CellWrapper>
      );

    case 'speed':
    case 'bitrate':
      return (
        <CellWrapper clickable={false}>
          {!value || value <= 0 ? '' : formatBitRate(value)}
        </CellWrapper>
      );

    // booleans
    case 'bogondst':
    case 'bogonsrc':
    case 'dstinternal':
    case 'internal':
    case 'srcinternal':
    case 'provider':
    case 'tcpflags.ack':
    case 'tcpflags.cwr':
    case 'tcpflags.ece':
    case 'tcpflags.fin':
    case 'tcpflags.ns':
    case 'tcpflags.psh':
    case 'tcpflags.rst':
    case 'tcpflags.syn':
    case 'tcpflags.urg':
      return value == null ? (
        value
      ) : (
        <CellWrapper clickable={false}>{value ? 'TRUE' : 'FALSE'}</CellWrapper>
      );

    case 'metrics.count': {
      const arr = makeArr(Object.entries(value || {}));
      return !arr.length ? null : (
        <ShowMoreWrapper>
          {arr.map(([key, val]) => (
            <span key={key}>
              {key}: {val}
            </span>
          ))}
        </ShowMoreWrapper>
      );
    }

    case 'pbratio':
      return value == null ? (
        value
      ) : (
        <CellWrapper clickable={false}>
          {numberFormatter(value, 'en-US', { minimumFractionDigits: 5 })}
        </CellWrapper>
      );

    // custom table widget
    case 'percent': {
      return value == null ? (
        value
      ) : (
        <CellWrapper clickable={false}>
          {`${(value * 100).toLocaleString(undefined, {
            maximumFractionDigits: 1,
          })}%`}
        </CellWrapper>
      );
    }

    case 'context':
    case 'traffictype':
    case 'traffic_type':
    case 'algo_record_type':
      return !value ? null : (
        <CellTrafficType
          field={field}
          value={value}
          original={original}
          disabled={options?.disabled}
        />
      );

    case 'tdm': {
      return (
        <ShowMoreWrapper gap={5}>
          {makeArr(value).map((tdm) => (
            <CellAlgorithmName
              key={tdm.name}
              name={tdm.name}
              description={tdm.description}
              disabled={options?.disabled}
              isNew={options?.isNew}
            />
          ))}
        </ShowMoreWrapper>
      );
    }

    default:
      return (
        <CellWrapper clickable={false}>
          {typeof value === 'number'
            ? numberFormatter(value)
            : String(value ?? '')}
        </CellWrapper>
      );
  }
});

/**
 * @param {string?} field - field name, if not passed or empty, will be used column id
 * @return {function({value?: *}): JSX.Element}
 */
const UniversalCell = (field) => (props) => {
  const { value, column = {}, row = {}, options } = props;
  const original = getRowOriginal(row) ?? {};
  const localField = field || column.duplicateOf || column.id;
  const localOptions = options || column.options || {};

  return (
    <Cell
      value={value}
      field={localField}
      original={original}
      options={localOptions}
    />
  );
};

export {
  UniversalCell,
  ShowMoreWrapper,
  getLabelFieldData,
  getLabelFieldMeta,
  HeaderSubheaderCell,
  HeaderSubheader,
};
