import { call, put } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  createSelector,
  createSlice,
  defaultReducers,
  startFetching,
  stopFetching,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

import { pluralize } from '+utils';

export const initialState = {
  isFetching: false,
  isFetchingRow: {},
  error: '',
  collection: {},
  lastRemoveDate: null,
};

let api;
const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const apiPath = '/blocks';

const slice = createSlice({
  name: 'blocks',
  initialState,

  reducers: {
    ...defaultReducers,
    fetch: (state, { payload: { id } }) => {
      startFetching(state);
      state.isFetchingRow[id] = true;
    },
    fetchBlockSuccess(state, { payload: data }) {
      stopFetching(state);
      state.isFetchingRow[data.id] = false;
      state.collection[data.id] = data;
    },

    removeBlock: startFetching,
    bulkRemoveBlocks: startFetching,
    removeAllBlocks: startFetching,
    removeAllBlocksByPlugin: startFetching,

    success(state) {
      stopFetching(state);
    },

    successRemove(state) {
      stopFetching(state);
      state.lastRemoveDate = Date.now();
    },

    fail(state, { payload: { id, error } }) {
      defaultReducers.fail(state, { payload: error });
      state.isFetchingRow[id] = false;
    },
  },

  sagas: (actions) => ({
    [actions.fetch]: {
      *saga({ payload: { silent = true, id, customer, timestamp } }) {
        initApi();

        try {
          let url = `/fetch/block/${id}`;
          if (customer || timestamp) {
            const params = new URLSearchParams();
            if (customer) {
              params.append('customer', customer);
            }
            if (timestamp) {
              params.append('timestamp', timestamp);
            }
            url += `?${params.toString()}`;
          }
          const response = yield call(api.get, url);
          yield put(actions.fetchBlockSuccess(response.data.data[0]));
        } catch (error) {
          yield put(actions.fail({ id, error }));
          if (!silent) {
            yield put(
              toastActions.error({
                message: 'Error fetching block',
                details: error.message,
              }),
            );
          }
        }
      },
    },

    [actions.removeBlock]: {
      *saga({ payload: block }) {
        initApi();

        try {
          yield call(api.delete, `${apiPath}/${block.plugin.id}/${block.id}`);
          yield put(actions.successRemove());
          yield put(
            toastActions.success(
              `Request to remove ${
                block.srcip || 'Unknown SRC IP'
              } block from plugin ${
                block.plugin.name
              } has been accepted by the server, block will be removed shortly`,
            ),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error clearing block',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkRemoveBlocks]: {
      *saga({ payload: data }) {
        initApi();

        try {
          const response = yield call(api.put, `${apiPath}/bulkdelete`, {
            blocks: data,
          });
          const { successes, errors } = response.data;
          yield put(actions.successRemove());
          if (successes?.length) {
            yield put(
              toastActions.success(
                `The request to remove ${successes.length} ${pluralize(
                  successes.length,
                  'block',
                )} has been queued and ${pluralize(
                  successes.length,
                  'it',
                  'they',
                )} will be removed shortly`,
              ),
            );
          }
          if (errors?.length) {
            yield put(
              toastActions.error(`Error removing ${errors.length} blocks`),
            );
          }
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error clearing blocks',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.removeAllBlocks]: {
      *saga() {
        initApi();

        try {
          yield call(api.delete, apiPath);
          yield put(actions.successRemove({}));
          yield put(
            toastActions.success(
              'The request to remove all active blocks has been queued and they will be removed shortly',
            ),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error clearing the blocklist',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.removeAllBlocksByPlugin]: {
      *saga({ payload: pluginid }) {
        initApi();

        try {
          yield call(api.delete, `${apiPath}/${pluginid}}`);
          yield put(actions.successRemove());
          yield put(
            toastActions.success(
              'Request to remove plugin blocklist has been queued, it will be removed shortly',
            ),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error clearing the plugin blocklist',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector([getState], (state) => state.isFetching),

    isFetchingRecord: (id) =>
      createSelector([getState], (state) => state.isFetchingRow[id]),

    getRecord: (id) =>
      createSelector([getState], (state) => state.collection[id]),

    getLastRemoveDate: createSelector(
      [getState],
      (state) => state.lastRemoveDate,
    ),
  }),
});

// For convenience
export const { actions, selectors } = slice;

export default slice;
