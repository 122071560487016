import PropTypes from '+prop-types';
import { memo, useCallback } from 'react';

import { ContextTypes, ContextTypesLabels } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';

import RealTimeTraffic from '@/pages/RealTimeTraffic';

import Button, { ButtonVariants } from '+components/Button';
import { Col, LayoutTypes, Row } from '+components/Layout';
import { usePageTabs } from '+components/PageTabs';
import { getFlowDataFromRecord } from '+utils/getFlowDataFromRecord';
import { getSearchUrl } from '+utils/getSearchUrl';
import makeArr from '+utils/makeArr';

import { TableContainer } from './TableContainer';

const allowedOnlyFlow = new Set([ContextTypes.flow]);
const allowedOnlyDns = new Set([ContextTypes.dns]);

const ContextLabels = {
  ...ContextTypesLabels,
  [ContextTypes.flow]: 'Flows',
};

export const TrafficTable = memo((props) => {
  const { event, isSubAccountRecord } = props;

  const [, , pageTabMethods] = usePageTabs();

  const eventContext = event?.traffic_type || ContextTypes.flow;

  const onSearch = useCallback(() => {
    const { nql, from, to, endIsNow } = getFlowDataFromRecord({
      record: event,
      type: ContextTypes.alerts,
    });

    const nqlAsArr = makeArr(nql);

    const url = getSearchUrl({
      context: eventContext,
      autoRefresh: false,
      from,
      to: endIsNow ? 'now' : to,
      period: {
        type: CustomType,
      },
      nql: eventContext === ContextTypes.dns ? nqlAsArr[0] : nqlAsArr,
      customer: isSubAccountRecord ? event?.customer : undefined,
    });
    pageTabMethods.add(url);
  }, [event, eventContext, isSubAccountRecord]);

  return (
    <Row $type={LayoutTypes.card}>
      <Col>
        <Row
          $type={LayoutTypes.titleLarge}
          justifyContent="space-between"
          alignItems="center"
        >
          {ContextLabels[eventContext]}
          <Button variant={ButtonVariants.link} onClick={onSearch}>
            Full Search
          </Button>
        </Row>

        <TableContainer item container={false}>
          <RealTimeTraffic
            globalFiltersDisabled
            pageTitleDisabled
            minRows={1}
            defaultContext={eventContext}
            allowedContexts={
              eventContext === ContextTypes.dns
                ? allowedOnlyDns
                : allowedOnlyFlow
            }
          />
        </TableContainer>
      </Col>
    </Row>
  );
});

TrafficTable.displayName = 'TrafficTable';

TrafficTable.propTypes = {
  event: PropTypes.object,
  isSubAccountRecord: PropTypes.bool,
};

TrafficTable.defaultProps = {
  event: undefined,
  isSubAccountRecord: false,
};
