import PropTypes from '+prop-types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import MenuColumnPropertiesTrayIcon from 'mdi-react/ChartAreasplineIcon';

import { ContextTypes } from '@/models/ContextTypes';

import { selectors as customerSelectors } from '@/redux/api/customer';

import { usePageTabs } from '+components/PageTabs';
import useUIProperty from '+hooks/useUIProperty';
import { getRecordDetailsUrl } from '+utils/getRecordDetailsUrl';

import MenuColumnButton from './MenuColumnButton';

const MenuColumnPropertiesTrayTrigger = (props) => {
  const { title, dataType, recordType, value, additionalMenuOptions } = props;
  const { id } = value || {};
  const [hideNav] = useUIProperty('hideNav');
  const context = recordType || ContextTypes.flow;
  const [, setGlobalContextMenu] = useUIProperty('globalContextMenu', null);
  const [, , pageTabMethods] = usePageTabs();

  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const isSubAccountRecord =
    value?.customer && value?.customer !== customer?.shortname;

  const onClick = useCallback(() => {
    const url = getRecordDetailsUrl({
      context,
      id,
      customer: isSubAccountRecord ? value?.customer : undefined,
      timestamp:
        context === ContextTypes.blocks ? value?.start : value?.timestamp,
    });
    pageTabMethods.add(url);
  }, [context, id, isSubAccountRecord, value, context]);

  const onContextMenu = useCallback(
    (e) => {
      setGlobalContextMenu({
        data: [
          {
            title,
            dataType,
            recordType,
            value,
            customer: value?.customer,
            additionalMenuOptions,
          },
        ],
        event: e,
      });
    },
    [title, dataType, recordType, value, additionalMenuOptions],
  );

  return (
    <MenuColumnButton
      disabled={hideNav}
      onClick={hideNav ? null : onClick}
      onContextMenu={hideNav ? null : onContextMenu}
      data-tracking={`property-tray-trigger-${String(recordType)
        .toLowerCase()
        .replace(/\s+/g, '-')}`}
    >
      <MenuColumnPropertiesTrayIcon size={16} />
    </MenuColumnButton>
  );
};

MenuColumnPropertiesTrayTrigger.propTypes = {
  title: PropTypes.string,
  dataType: PropTypes.string.isRequired,
  recordType: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  additionalMenuOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

MenuColumnPropertiesTrayTrigger.defaultProps = {
  title: null,
  additionalMenuOptions: null,
};

export { MenuColumnPropertiesTrayIcon };
export default MenuColumnPropertiesTrayTrigger;
