import { css } from 'styled-components';

import text from './text';

export default ({
  textColor,
  background,
  borderColor,
  textColorDisabled,
  backgroundDisabled,
  borderColorDisabled,
}) => css`
  ${text({
    textColor,
    background,
    borderColor,
    textColorDisabled,
    backgroundDisabled,
    borderColorDisabled,
  })};
  padding: unset;
  text-decoration: underline dotted;
  text-underline-offset: 15%;
  border: unset;
  height: unset;
  font-size: 12px;
  line-height: 16px;
  border-radius: 0;
`;
