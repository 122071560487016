/* eslint-disable react/no-array-index-key, react/prop-types */
import { Fragment } from 'react';

import styled from 'styled-components';

import Stack from '@mui/material/Stack';

import FlagIcon from 'mdi-react/FlagIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';

import getColorScale from '@/pages/Events/getColorScale';

import { ShowMoreWrapper, UniversalCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  GeoColumnFactory,
  LabelOrIpColumnFactory,
  NumberColumnFactory,
  OwnerAsColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import {
  BooleanColumnFilter,
  SelectColumnFilter,
} from '+components/Table/Filters';
import Tag from '+components/Tag';
import { formatNumber } from '+utils';

import { Actions } from './Actions';

const TotalValue = styled(Tag).attrs({ outlined: false, color: 'primary' })`
  background-color: ${({ $color }) => $color} !important;
  border-color: ${({ $color }) => $color} !important;
`;

const ipTypeIcon = {
  src: <MapMarkerIcon size={12} style={{ marginLeft: -4 }} />,
  dst: <FlagIcon size={12} style={{ marginLeft: -3, marginRight: -1 }} />,
};

const columnsCollection = ({
  labelContext,
  scoreMinMax,
  context,
  isSubAccountRecord,
}) => {
  const colorScale = getColorScale(scoreMinMax);

  return [
    BaseColumnFactory({
      id: 'ip_type',
      accessor: ({ dstip }) => (dstip ? 'dst' : 'src'),
      Header: 'Type',
      maxWidth: 65,
      getCellProps: () => ({ style: { justifyContent: 'center' } }),
      Cell: ({ value }) => (
        <Tag outlined={false} tag={Stack} gap="4px" direction="row">
          {ipTypeIcon[value]} {value}
        </Tag>
      ),
      Filter: SelectColumnFilter({
        fixedOptions: ['all', 'dst', 'src'],
        sort: false,
      }),
      filter: 'selectFilter',
      disableHide: true,
    }),
    LabelOrIpColumnFactory({
      Header: 'IP',
      dataFieldName: 'ip',
      labelFieldName: 'ipname',
      maxWidth: 100,
      showLabel: labelContext.show,
      context: labelContext.ip,
      fetchLabels: true,
      disableHide: true,
    }),
    BaseColumnFactory({
      accessor: 'bogon',
      Header: 'Bogon',
      maxWidth: 65,
      getCellProps: () => ({ style: { justifyContent: 'center' } }),
      Cell: ({ value }) =>
        value ? (
          <Tag color="danger" outlined={false}>
            YES
          </Tag>
        ) : (
          <Tag outlined={false}>NO</Tag>
        ),
      Filter: BooleanColumnFilter({
        true: 'Yes',
        false: 'No',
      }),
      filter: 'booleanFilter',
      disableHide: true,
    }),
    GeoColumnFactory({
      Header: 'Geo',
      field: 'geo',
      maxWidth: 100,
      Cell: (cell) => {
        const original = getRowOriginal(cell?.row);
        return UniversalCell(original?.dstip ? 'dstgeo' : 'srcgeo')(cell);
      },
      disableHide: true,
    }),
    OwnerAsColumnFactory({
      Header: 'AS',
      field: 'as',
      maxWidth: 150,
      Cell: (cell) => {
        const original = getRowOriginal(cell?.row);
        return UniversalCell(original?.dstip ? 'dstas' : 'srcas')(cell);
      },
      disableHide: true,
    }),
    BaseColumnFactory({
      accessor: 'rdns',
      Header: 'Reverse DNS',
      maxWidth: 300,
      Cell: ({ value }) =>
        !value?.length ? null : (
          <ShowMoreWrapper>
            {value.map((item) => (
              <span key={item}>{item}</span>
            ))}
          </ShowMoreWrapper>
        ),
      disableHide: true,
    }),
    BaseColumnFactory({
      accessor: 'iprep.categories',
      Header: 'IP Reputation',
      maxWidth: 120,
      Cell: ({ value }) => (
        <Fragment>
          {!value?.length && <Tag outlined={false}>None</Tag>}
          {value?.length && (
            <ShowMoreWrapper>
              {value.map((item) => (
                <span key={item}>{item}</span>
              ))}
            </ShowMoreWrapper>
          )}
        </Fragment>
      ),
      disableHide: true,
    }),
    NumberColumnFactory({
      Header: 'Total Score',
      field: 'totalScore',
      maxWidth: 100,
      textAlign: 'flex-start',
      Cell: ({ value }) =>
        value != null && (
          <TotalValue $color={colorScale(value)}>
            {formatNumber(value, 1, '', true)}
          </TotalValue>
        ),
      disableHide: true,
    }),
    BaseColumnFactory({
      Header: 'Actions',
      accessor: 'ip',
      maxWidth: 200,
      Cell: ({ row }) => (
        <Actions
          original={getRowOriginal(row)}
          isSubAccountRecord={isSubAccountRecord}
          context={context}
        />
      ),
      disableHide: true,
    }),
  ];
};

export const getColumns = columnsCollection;
