import PropTypes from '+prop-types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import LabelTypes from '@/models/LabelTypes';

import { Col, NoData } from '+components/Layout';
import { Tab } from '+components/Tabs';

import { Context } from '../../../MenuWrapper';
import { BodyContainer } from '../BodyComponents';
import TabContent from '../TabContent';
import Tabs from '../Tabs';
import TabsContainer from '../TabsContainer';
import Events from './components/Events';
import IpIntelligenceWrapper from './components/IpIntelligenceWrapper';
import Labels from './components/Labels';

const FieldBody = (props) => {
  const { padding, activeData, isOpen } = props;

  const { onLabelClick, onLabelEdit } = useContext(Context);

  const [currentTab, setCurrentTab] = useState(null);

  const tabs = useMemo(
    () => ({
      ...([LabelTypes.ip, LabelTypes.port].includes(activeData?.fieldType)
        ? {
            labels: {
              value: 'labels',
              label: 'Labels',
            },
          }
        : {
            info: {
              value: 'info',
              label: 'Info',
            },
          }),
      ...(activeData?.fieldType === LabelTypes.ip && {
        ip_intell: {
          value: 'ip_intell',
          label: 'IP Intelligence',
        },
      }),
      ...(activeData?.eventNql && {
        events: {
          value: 'events',
          label: 'Events',
        },
      }),
    }),
    [activeData],
  );

  const onTabChange = useCallback((_, value) => {
    setCurrentTab(tabs[value]);
  }, []);

  useEffect(() => {
    setCurrentTab(tabs.labels ? tabs.labels : tabs.info);
  }, [tabs]);

  return (
    <BodyContainer $padding={padding} $isOpen={isOpen}>
      {isOpen && tabs[currentTab?.value] && (
        <TabsContainer>
          <Tabs
            $padding={padding}
            value={currentTab.value}
            onChange={onTabChange}
          >
            {tabs.labels && (
              <Tab label={tabs.labels.label} value={tabs.labels.value} />
            )}
            {tabs.info && (
              <Tab label={tabs.info.label} value={tabs.info.value} />
            )}
            {tabs.ip_intell && (
              <Tab label={tabs.ip_intell.label} value={tabs.ip_intell.value} />
            )}
            {tabs.events && (
              <Tab label={tabs.events.label} value={tabs.events.value} />
            )}
          </Tabs>

          {currentTab.value === tabs.info?.value && (
            <TabContent>
              {activeData?.info ? (
                <Col paddingLeft={padding} paddingRight={padding}>
                  {activeData.info}
                </Col>
              ) : (
                <NoData padding={padding}>No data to display</NoData>
              )}
            </TabContent>
          )}

          {currentTab.value === tabs.labels?.value && (
            <TabContent>
              <Labels
                padding={padding}
                activeData={activeData}
                onLabelClick={onLabelClick}
                onLabelEdit={onLabelEdit}
              />
            </TabContent>
          )}

          {currentTab.value === tabs.ip_intell?.value && (
            <TabContent>
              <IpIntelligenceWrapper
                padding={padding}
                activeData={activeData}
              />
            </TabContent>
          )}

          {currentTab.value === tabs.events?.value && (
            <TabContent>
              <Events padding={padding} activeData={activeData} />
            </TabContent>
          )}
        </TabsContainer>
      )}
    </BodyContainer>
  );
};

FieldBody.propTypes = {
  padding: PropTypes.string.isRequired,
  activeData: PropTypes.shape({
    info: PropTypes.any,
    fieldType: PropTypes.string,
    value: PropTypes.any,
    eventNql: PropTypes.string,
    customer: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
};

FieldBody.defaultProps = {
  activeData: null,
  isOpen: false,
};

export default FieldBody;
