import PropTypes from '+prop-types';
import { memo, useState } from 'react';

import styled from 'styled-components';

import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

import IconButton from '+components/IconButton';
import useEvent from '+hooks/useEvent';
import { propsSelectors as themeTable } from '+theme/slices/table';

const PrevNextButton = styled(IconButton)`
  color: #999999;
  background: ${themeTable.colorPaginationButtonBackground};
  transition:
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    color: black;
    background: ${({ theme }) => theme.primary};
  }

  &.Mui-disabled {
    color: #999999;
    background: ${themeTable.colorPaginationButtonBackground};
    opacity: 0.38;
  }
`;

export const Pagination = memo((props) => {
  const { max, onChange } = props;
  const [index, setIndex] = useState(0);

  const onPrevClick = useEvent(() => {
    const value = Math.max(0, index - 1);
    setIndex(value);
    onChange?.(value);
  }, []);

  const onNextClick = useEvent(() => {
    const value = Math.min(Math.max(0, max - 1), index + 1);
    setIndex(value);
    onChange?.(value);
  }, []);

  const nextIsDisabled = index + 1 >= max;
  const prevIsDisabled = index < 1;

  return (
    max && (
      <Stack direction="row" gap={1} alignItems="center">
        <PrevNextButton disabled={prevIsDisabled} onClick={onPrevClick}>
          <ChevronLeftIcon />
        </PrevNextButton>
        <Typography variant="subtitle1" component="span" fontSize="12px">
          {index + 1} of {max}
        </Typography>
        <PrevNextButton disabled={nextIsDisabled} onClick={onNextClick}>
          <ChevronRightIcon />
        </PrevNextButton>
      </Stack>
    )
  );
});

Pagination.displayName = 'Pagination';

Pagination.propTypes = {
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

Pagination.defaultProps = {
  onChange: undefined,
};
