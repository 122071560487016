import { useCallback } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { heatmapColors } from '+hooks/mitreHooks';
import { pluralize } from '+utils';

const Technique = styled.div`
  padding: 5px;
  background-color: ${({ theme }) => theme.mitreTechniqueBackground};
  margin-bottom: 1px;
  margin-right: 1px;
  font-size: 12px;

  ${({ heatmapindex }) =>
    heatmapindex > 0 &&
    css`
      background-color: ${heatmapColors[heatmapindex - 1]} !important;
      > div {
        color: #fff !important;
        text-shadow: 0 0 1px #000;
        span {
          color: #fff !important;
          text-shadow: 0 0 1px #000;
        }
      }
    `}

  &.hasEvents {
    cursor: pointer;
    background-color: ${({ theme }) => theme.mitreTechniqueBackgroundHasEvents};

    .text {
      display: inline-block;
    }
  }

  &.selected {
    > div {
      color: #fff !important;
      text-shadow: 0 0 1px #000;

      span {
        color: #fff !important;
        text-shadow: 0 0 1px #000;
      }
    }
  }
`;

const TechniqueId = styled.div`
  font-weight: 700;
  font-size: 10px;
  text-transform: uppercase;
`;

const TechniqueName = styled.div``;

const TechniqueEvents = styled.div`
  margin-right: 1px;

  > .count {
    font-weight: 700;
  }

  > .text {
    display: none;
    font-size: 10px;
    margin-left: 4px;
  }
`;

const MITRETechnique = (props) => {
  const { technique, onTechniqueClick, selectedTechnique, heatmapIndex } =
    props;
  const eventCount = technique.events?.length;

  const disableHeatmap =
    !!selectedTechnique && selectedTechnique !== technique.technique_id;

  const onClick = useCallback(() => {
    if (eventCount > 0) {
      onTechniqueClick?.(technique);
    }
  }, [eventCount, onTechniqueClick, technique]);

  return (
    <Technique
      className={classNames({
        hasEvents: eventCount > 0,
        selected: selectedTechnique === technique.technique_id,
      })}
      heatmapindex={disableHeatmap ? 0 : heatmapIndex}
      onClick={onClick}
    >
      <TechniqueId>{technique.technique_id}</TechniqueId>
      <TechniqueName>{technique.technique_name}</TechniqueName>
      {eventCount > 0 && (
        <TechniqueEvents>
          <span className="count">{eventCount > 0 ? eventCount : '-'}</span>
          <span className="text">{pluralize(eventCount, 'Event')}</span>
        </TechniqueEvents>
      )}
    </Technique>
  );
};

MITRETechnique.propTypes = {
  technique: PropTypes.shape({
    technique_name: PropTypes.string,
    technique_id: PropTypes.string,
    events: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onTechniqueClick: PropTypes.func,
  selectedTechnique: PropTypes.string,
  heatmapIndex: PropTypes.number.isRequired,
};

MITRETechnique.defaultProps = {
  selectedTechnique: '',
  onTechniqueClick: () => {},
};

export default MITRETechnique;
