export const LayoutTypes = {
  cardLegacy: 'cardLegacy',
  card: 'card',
  cardContent: 'cardContent',
  title: 'title',
  titleLarge: 'titleLarge',
  field: 'field',
  fieldName: 'fieldName',
  fieldValue: 'fieldValue',
  fieldDescription: 'fieldDescription',
};
