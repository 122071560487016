import PropTypes from '+prop-types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isIP } from 'is-ip';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { actions } from '@/redux/api/labels/ips';

import makeArr from '+utils/makeArr';

import CellWrapper from './CellWrapper';

// Generic Context Menu for IP Addresses
const CellIp = (props) => {
  const {
    field,
    value,
    original,
    clickable,
    disabled,
    hideUnderline,
    fetchLabels,
  } = props;

  const dispatch = useDispatch();

  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const stream = useMemo(
    () => ({
      srcip: original?.srcip,
      srcport: original?.srcport,
      dstip: original?.dstip,
      dstport: original?.dstport,
      protocol: original?.protocol,
    }),
    [original],
  );

  useEffect(() => {
    if (!fetchLabels || !value || !isIP(value)) {
      return;
    }
    const arr = makeArr(original?.customer).filter(Boolean);
    if (!arr.length) {
      arr.push(''); // we need to fetch labels for the current customer
    }
    arr.forEach((originalCustomer) => {
      const isSubAccountRequest =
        originalCustomer && originalCustomer !== customer?.shortname;
      if (isSubAccountRequest) {
        const namespace = `CellIp - ${value} - ${originalCustomer}`;
        dispatch(
          actions.fetchIpLabelsByIp(
            {
              ip: value,
              customer: originalCustomer,
            },
            namespace,
          ),
        );
      } else {
        const namespace = `CellIp - ${value}`;
        dispatch(actions.fetchIpLabelsByIp({ ip: value }, namespace));
      }
    });
  }, [fetchLabels, value, original?.customer, customer?.shortname]);

  return (
    <CellWrapper
      customer={original?.customer}
      field={field}
      value={value}
      stream={stream}
      clickable={clickable}
      disabled={disabled}
      hideUnderline={hideUnderline}
      showMenuOnPrimaryClick={false}
    >
      <span>{value}</span>
    </CellWrapper>
  );
};

CellIp.propTypes = {
  field: PropTypes.string,
  value: PropTypes.string.isRequired,
  original: PropTypes.shape(),
  clickable: PropTypes.bool,
  disabled: PropTypes.bool,
  hideUnderline: PropTypes.bool,
  fetchLabels: PropTypes.bool,
};

CellIp.defaultProps = {
  field: '',
  original: undefined,
  clickable: true,
  disabled: false,
  hideUnderline: false,
  fetchLabels: false,
};

export default CellIp;
