import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import ContentDuplicateIcon from 'mdi-react/ContentDuplicateIcon';
import FileCodeOutlineIcon from 'mdi-react/FileCodeOutlineIcon';
import LayersIcon from 'mdi-react/LayersIcon';
import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import UndoIcon from 'mdi-react/UndoIcon';

import RoutePaths from '@/models/RoutePaths';

import {
  selectors as contextSelectors,
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

import { ButtonVariants } from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import { Dropdown, DropdownItem } from '+components/Dropdown';
import { ActionsContainer } from '+components/Layout';
import RecordModal from '+components/RecordModal';
import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import * as toast from '+components/toast';
import useFocusOnRowId from '+hooks/useFocusOnRowId';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import { pluralize } from '+utils';

import CustomConfirmDeleteModal from '../shared/CustomConfirmDeleteModal';
import { Columns, getColumns } from './Columns';

export const TableTypes = {
  none: 'Algorithms_CommonTable',
  system: 'Algorithms_CommonTableSystem',
  systemCustomer: 'Algorithms_CommonTableSystemCustomer',
};

const ActionTypes = {
  reset: 'reset',
  remove: 'remove',
  enable: 'Enable',
  disable: 'Disable',
  confirmDelete: 'confirmDelete',
  enableBypassPolicies: 'enableBypassPolicies',
  disableBypassPolicies: 'disableBypassPolicies',
};

const rootPath = `${RoutePaths.models}`;

const sortBy = [{ id: 'name', desc: false }];

const CommonTable = (props) => {
  const {
    rows,
    type = TableTypes.none,
    permissions,
    isDefaultCustomer,
    noDataText,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isFetching, customError } = useSelector(rulesSelectors.getState);
  const categories = useSelector(rulesSelectors.getCategories);
  const algorithms = useSelector(rulesSelectors.getAlgorithms);
  const ccms = useSelector(contextSelectors.getCCMs);

  const [, setRowId] = useFocusOnRowId(type);

  const [modal, setModal] = useState({});
  const [viewRawRecord, setViewRawRecord] = useState(null);
  const [currentModel, setCurrentModel] = useState('');
  const [showBulkEnableModal, toggleBulkEnableModal] = useToggle(false);
  const [showBulkDisableModal, toggleBulkDisableModal] = useToggle(false);
  const [showEnableBypassPoliciesModal, toggleEnableBypassPoliciesModal] =
    useToggle(false);
  const [showDisableBypassPoliciesModal, toggleDisableBypassPoliciesModal] =
    useToggle(false);

  const [selected, setSelected] = useState([]);

  useLoadingIndicator(isFetching);

  const onAddButtonClick = useCallback(
    (modelType) => () => {
      let pathname = '';
      if (modelType === 'TDM') {
        const path = RoutePaths.modelsDetection;
        pathname = `${path}/add`;
      } else {
        pathname = `${RoutePaths.modelsContext}/add`;
      }
      navigate(pathname);
    },
    [],
  );

  const toggleModal = useCallback(
    (id = 0, name = '', action = '', object = {}) => {
      setModal((prev) => ({
        show: !!id && !prev.show,
        id,
        name,
        action,
        object,
      }));
    },
    [],
  );

  const onToggleEnabled = useCallback((row, newEnabled) => {
    toggleModal(
      row.id,
      row.name,
      newEnabled ? ActionTypes.enable : ActionTypes.disable,
      row,
    );
  }, []);

  const onConFirmBulkEnable = useCallback(() => {
    const networkModels = selected.filter(
      (id) => algorithms[id]?.algo_type === 'TDM' && !algorithms[id].enabled,
    );
    const contextModels = selected.filter(
      (id) => ccms[id]?.algo_type === 'CDM' && !ccms[id].enabled,
    );

    if (!networkModels.length && !contextModels.length) {
      toast.info('All selected detection models are already enabled');
      return;
    }

    dispatch(
      rulesActions.bulkEnableModels({
        algorithms: networkModels,
        ccms: contextModels,
      }),
    );
  }, [selected, algorithms, ccms]);

  const onConfirmBulkDisable = useCallback(() => {
    const networkModels = selected.filter(
      (id) => algorithms[id]?.algo_type === 'TDM' && algorithms[id].enabled,
    );
    const contextModels = selected.filter(
      (id) => ccms[id]?.algo_type === 'CDM' && ccms[id].enabled,
    );

    if (!networkModels.length && !contextModels.length) {
      toast.info('All selected detection models are already disabled');
      return;
    }

    dispatch(
      rulesActions.bulkDisableModels({
        algorithms: networkModels,
        ccms: contextModels,
      }),
    );
  }, [selected, algorithms, ccms]);

  const onConfirmBulkEnableBypassPolicies = useCallback(() => {
    const networkModels = selected
      .filter(
        (id) =>
          algorithms[id]?.algo_type === 'TDM' && !algorithms[id].bypassrule,
      )
      .map((id) => ({ ...algorithms[id], bypassrule: true }));

    if (!networkModels.length) {
      toast.info(
        'All selected detection models are already bypassing policies',
      );
      return;
    }

    dispatch(rulesActions.bulkUpdateAlgorithms(networkModels));
  }, [selected, algorithms]);

  const onConfirmBulkDisableBypassPolicies = useCallback(() => {
    const networkModels = selected
      .filter(
        (id) =>
          algorithms[id]?.algo_type === 'TDM' && algorithms[id].bypassrule,
      )
      .map((id) => ({ ...algorithms[id], bypassrule: false }));

    if (!networkModels.length) {
      toast.info('No selected detection models are bypassing policies');
      return;
    }

    dispatch(rulesActions.bulkUpdateAlgorithms(networkModels));
  }, [selected, algorithms]);

  const onConfirmReset = useCallback(() => {
    if (modal.id && modal.object?.algo_type === 'TDM') {
      dispatch(rulesActions.resetAlgorithm(modal.id));
    }
    if (modal.id && modal.object?.algo_type === 'CDM') {
      dispatch(rulesActions.resetCCM(modal.id));
    }
    setModal({});
  }, [dispatch, modal]);

  const onConfirmDelete = useCallback(() => {
    if (modal.id && modal.name) {
      setCurrentModel(modal.name);
    }
    if (modal.id && modal.object?.algo_type === 'TDM') {
      dispatch(rulesActions.deleteAlgorithm(modal.id));
    }
    if (modal.id && modal.object?.algo_type === 'CDM') {
      dispatch(rulesActions.deleteCCM(modal.id));
    }
  }, [dispatch, modal]);

  const onConfirmEnable = useCallback(() => {
    if (modal?.id && modal.object?.algo_type === 'TDM') {
      if (modal.action === ActionTypes.enable) {
        dispatch(rulesActions.enableAlgorithm(modal.id));
      } else {
        dispatch(rulesActions.disableAlgorithm(modal.id));
      }
    }
    if (modal?.id && modal.object?.algo_type === 'CDM') {
      if (modal.action === ActionTypes.enable) {
        dispatch(rulesActions.enableCCM(modal.id));
      } else {
        dispatch(rulesActions.disableCCM(modal.id));
      }
    }
    setModal({});
  }, [dispatch, modal]);

  const onToggleBypassRule = useCallback((row, newBypassing) => {
    toggleModal(
      row.id,
      row.name,
      newBypassing
        ? ActionTypes.enableBypassPolicies
        : ActionTypes.disableBypassPolicies,
      row,
    );
  }, []);

  const onConfirmBypassPolicies = useCallback(() => {
    if (modal?.id) {
      dispatch(
        rulesActions.updateAlgorithm({
          ...modal.object,
          bypassrule: modal.action === ActionTypes.enableBypassPolicies,
        }),
      );
    }
    setModal({});
  }, [dispatch, modal]);

  const onToggle = useCallback(() => {
    setModal({});
  }, []);

  const onCustomConfirmClose = useCallback(() => {
    dispatch(rulesActions.removeCustomError());
    setModal({});
  }, []);

  const cxActionMenu = useCallback(
    (id, item) => {
      const isDeletable = !item.system || isDefaultCustomer;
      const isResettable = item.system && !item.systemdefault;
      const showOverridesOption =
        item.algo_type === 'TDM' && !item?.autothreshold;
      const showAutoThresholdsOption =
        item.algo_type === 'TDM' && item?.autothreshold;
      const routePath = RoutePaths.modelsDetection;
      const modelType =
        item.algo_type === 'TDM'
          ? routePath.pageName
          : RoutePaths.modelsContext.pageName;
      const items = [
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          onClick: () => {
            setRowId(id);
            navigate(`${rootPath}/${modelType}/${id}`);
          },
        },
        {
          icon: <ContentDuplicateIcon />,
          text: 'Create As New',
          disabled: !permissions?.create,
          onClick: () => {
            setRowId(id);
            navigate(`${rootPath}/${modelType}/add?init=${item.id}`);
          },
        },
        showOverridesOption && {
          icon: <LayersIcon />,
          text: 'Threshold Overrides',
          onClick: () => {
            setRowId(id);
            navigate(
              `${rootPath}/${modelType}/${id}/${RoutePaths.modelOverrides}`,
            );
          },
        },
        showAutoThresholdsOption && {
          icon: <LayersIcon />,
          text: 'Auto Thresholding',
          onClick: () => {
            setRowId(id);
            navigate(
              `${rootPath}/${modelType}/${id}/${RoutePaths.modelAutoThresholds}`,
            );
          },
        },
        {
          icon: <FileCodeOutlineIcon />,
          text: 'View Raw Record',
          onClick: () => {
            setViewRawRecord(item);
          },
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled: !isDeletable || !permissions?.delete,
          onClick: () => {
            toggleModal(id, item.name, ActionTypes.remove, item);
          },
        },
        isResettable && {
          icon: <UndoIcon />,
          text: 'Reset Customization',
          disabled: !permissions?.update,
          onClick: () => {
            toggleModal(id, item.name, ActionTypes.reset, item);
          },
        },
      ].filter(Boolean);
      return (
        <MenuColumnContextMenu
          title={item.name}
          items={items}
          dataTracking="tdm"
        />
      );
    },
    [toggleModal, setRowId, permissions, isDefaultCustomer],
  );

  const categoriesHash = useMemo(
    () =>
      categories?.reduce((acc, item) => ({ ...acc, [item.name]: item }), {}),
    [categories],
  );

  const columns = useMemo(
    () =>
      getColumns(
        [
          Columns.rowSelector,
          Columns.system,
          type !== TableTypes.none && Columns.systemdefault,
          Columns.algoRecordType,
          Columns.name,
          Columns.categories,
          Columns.search_by,
          Columns.thresholds,
          Columns.track_by,
          type === TableTypes.system && Columns.beta,
          Columns.algoType,
          Columns.enabled,
          Columns.menu,
        ].filter(Boolean),
        {
          categories: categoriesHash,
          permissions,
          cxActionMenu,
          onToggleEnabled,
        },
      ),
    [type, permissions, cxActionMenu, onToggleEnabled, categoriesHash],
  );

  const availableColumns = useMemo(() => {
    const availablecols = [
      Columns.bypassrule,
      Columns.created,
      Columns.updated,
      Columns.autoThresholds,
    ];

    return getColumns(availablecols, {
      permissions,
      onToggleBypassRule,
    });
  }, [type, permissions, cxActionMenu, onToggleBypassRule]);

  const onSelectedRowsChange = useCallback((selectedRowIds) => {
    setSelected((prev) => {
      const next = Object.entries(selectedRowIds || {})
        .map(([key, value]) => (value ? key : null))
        .filter(Boolean);

      if (!prev.length && !next.length) {
        return prev;
      }

      return next;
    });
  }, []);

  const getIsRowSelectorDisabled = useCallback(
    () => !permissions?.update,
    [permissions],
  );

  useEffect(() => {
    setModal(
      customError
        ? {
            show: true,
            id: customError.id,
            name: '',
            action: ActionTypes.confirmDelete,
            object: algorithms[customError.id] || ccms[customError.id],
          }
        : {},
    );
  }, [customError, algorithms, ccms]);

  const modalToggle =
    modal.action === ActionTypes.enable || modal.action === ActionTypes.disable;
  const modalToggleBypassPolicies =
    modal.action === ActionTypes.enableBypassPolicies ||
    modal.action === ActionTypes.disableBypassPolicies;

  return (
    <Fragment>
      <ActionsContainer>
        <Dropdown
          caption="Add Model"
          disabled={!permissions?.create}
          testId="add-model-button"
        >
          <DropdownItem
            onClick={onAddButtonClick('TDM')}
            short
            testId="add-detection-model-button"
          >
            Detection Model
          </DropdownItem>
          <DropdownItem
            onClick={onAddButtonClick('CDM')}
            short
            testId="add-context-model-button"
          >
            Context Model
          </DropdownItem>
        </Dropdown>

        <Dropdown
          caption="Update Selected"
          variant={ButtonVariants.outlined}
          disabled={!selected.length || !permissions?.update}
        >
          <DropdownItem header>Detection Model</DropdownItem>
          <DropdownItem onClick={toggleBulkEnableModal} short>
            Enable
          </DropdownItem>
          <DropdownItem onClick={toggleBulkDisableModal} short>
            Disable
          </DropdownItem>
          <DropdownItem header>Bypass Policies</DropdownItem>
          <DropdownItem onClick={toggleEnableBypassPoliciesModal} short>
            Enable
          </DropdownItem>
          <DropdownItem onClick={toggleDisableBypassPoliciesModal} short>
            Disable
          </DropdownItem>
        </Dropdown>
      </ActionsContainer>

      <Table
        id={type}
        columns={columns}
        data={rows}
        sortBy={sortBy}
        noDataText={noDataText}
        availableColumns={availableColumns}
        getIsRowSelectorDisabled={getIsRowSelectorDisabled}
        onSelectedRowsChange={onSelectedRowsChange}
        autoResetSelectedRows
        testId="models-table"
      />

      {modal.show && modal.action === ActionTypes.reset && (
        <ConfirmModal
          item={modal.name || modal.id}
          confirmButtonText="Reset"
          whyAsking="This will undo any customization for this system detection model and restore the system default values."
          isDisabled={isFetching}
          onConfirm={onConfirmReset}
          onToggle={onToggle}
          isOpen
        />
      )}

      {customError &&
        modal.show &&
        modal.action === ActionTypes.confirmDelete && (
          <CustomConfirmDeleteModal
            item={currentModel}
            onRetry={onConfirmDelete}
            confirmButtonColor="danger"
            onClose={onCustomConfirmClose}
            customError={customError}
            isOpen
          />
        )}

      {modal.show && modal.action === ActionTypes.remove && (
        <ConfirmModal
          item={modal.name || modal.id}
          isDisabled={isFetching}
          onConfirm={onConfirmDelete}
          onToggle={onToggle}
          isOpen
          testId="delete-model-modal"
        />
      )}

      {modal.show && modalToggle && (
        <ConfirmModal
          confirmButtonText={modal.action}
          whyAsking=""
          confirmButtonColor="primary"
          item={modal.name || modal.id}
          isDisabled={isFetching}
          onConfirm={onConfirmEnable}
          onToggle={onToggle}
          isOpen
        />
      )}

      {modal.show && modalToggleBypassPolicies && (
        <ConfirmModal
          actionText={
            modal.action === ActionTypes.enableBypassPolicies
              ? 'bypass policies for'
              : 'stop bypassing policies for'
          }
          confirmButtonText={
            modal.action === ActionTypes.enableBypassPolicies
              ? 'bypass policies'
              : 'stop bypassing policies'
          }
          whyAsking={`Alerts will ${
            modal.action === ActionTypes.enableBypassPolicies ? 'not' : ''
          } be processed by policies and integrations.`}
          confirmButtonColor="primary"
          item={modal.name || modal.id}
          isDisabled={isFetching}
          onConfirm={onConfirmBypassPolicies}
          onToggle={onToggle}
          isOpen
        />
      )}

      {(showBulkEnableModal || showBulkDisableModal) && (
        <ConfirmModal
          item={`${selected.length} Detection ${pluralize(selected.length, 'Model')}`}
          confirmButtonText={showBulkEnableModal ? 'enable' : 'disable'}
          confirmButtonColor="primary"
          whyAsking=""
          onToggle={
            showBulkEnableModal ? toggleBulkEnableModal : toggleBulkDisableModal
          }
          onConfirm={
            showBulkEnableModal ? onConFirmBulkEnable : onConfirmBulkDisable
          }
          isOpen
        />
      )}

      {(showEnableBypassPoliciesModal || showDisableBypassPoliciesModal) && (
        <ConfirmModal
          item={`${selected.length} Detection ${pluralize(selected.length, 'Model')}`}
          actionText={
            showEnableBypassPoliciesModal
              ? 'bypass policies for'
              : 'stop bypassing policies for'
          }
          confirmButtonText={
            showEnableBypassPoliciesModal
              ? 'bypass policies'
              : 'stop bypassing policies'
          }
          whyAsking={`Alerts will ${
            showEnableBypassPoliciesModal ? 'not' : ''
          } be processed by policies and integrations.`}
          confirmButtonColor="primary"
          onToggle={
            showEnableBypassPoliciesModal
              ? toggleEnableBypassPoliciesModal
              : toggleDisableBypassPoliciesModal
          }
          onConfirm={
            showEnableBypassPoliciesModal
              ? onConfirmBulkEnableBypassPolicies
              : onConfirmBulkDisableBypassPolicies
          }
          isOpen
        />
      )}

      {!!viewRawRecord && (
        <RecordModal
          title={`Detection Model Record — ${viewRawRecord.name}`}
          data={viewRawRecord}
          onToggle={() => setViewRawRecord(null)}
          isOpen
        />
      )}
    </Fragment>
  );
};

CommonTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  type: PropTypes.oneOf(Object.values(TableTypes)),
  permissions: PropTypes.shape(),
  isDefaultCustomer: PropTypes.bool,
  noDataText: PropTypes.string,
};

CommonTable.defaultProps = {
  type: TableTypes.none,
  permissions: null,
  isDefaultCustomer: false,
  noDataText: undefined,
};

export default CommonTable;
