const getPluralizer = (locale) => {
  const pluralRules = new Intl.PluralRules(locale);

  return (count, singular, plural = '') => {
    const pluralCategory = pluralRules.select(count);

    if (pluralCategory === 'one') {
      return singular;
    }

    return plural || `${singular}s`;
  };
};

export const pluralize = getPluralizer('en-US');
