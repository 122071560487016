import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { useDebounce, useToggle } from 'react-use';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  defaultPaginationParams,
  getURLIndex,
  actions as ipLabelsActions,
  selectors as ipLabelsSelectors,
  splitId,
} from '@/redux/api/labels/ips';

import Button, { ButtonVariants } from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import { ActionsContainer } from '+components/Layout';
import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import {
  createFilterValue,
  FilterOperator,
} from '+components/Table/FilterTypeFactories';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import { pluralize } from '+utils';

import { Columns, getColumns } from './components/columns';
import ImportCsvForm from './components/ImportCsvForm';
import IpLabelForm from './components/IpLabelForm';

const tableId = 'IpLabels';
const defaultIpLabel = {
  ip: '',
  context: 'name',
  labels: [],
};

const formatTableFilters = (filters) =>
  (filters || []).reduce((acc, { id, value }) => ({ ...acc, [id]: value }), {});

const IpLabels = () => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [pageSize, setPageSize] = useState(defaultPaginationParams.size);
  const [pageIndex, setPageIndex] = useState(defaultPaginationParams.page);
  const [filters, setFilters] = useState([]);
  const [sortBy, setSortBy] = useState(defaultPaginationParams.sort);

  const formattedFilters = useMemo(
    () => formatTableFilters(filters),
    [filters],
  );

  const [paginationParams, setPaginationParams] = useState({
    page: pageIndex,
    size: pageSize,
    sort: sortBy,
    context: formattedFilters?.context || '',
    labels: formattedFilters?.labels || '',
    ip: formattedFilters?.ip || '',
  });

  useDebounce(
    () => {
      setPaginationParams({
        page: pageIndex,
        size: pageSize,
        context: formattedFilters?.context || '',
        labels: formattedFilters?.labels || '',
        ip: formattedFilters?.ip || '',
        sort: sortBy,
      });
    },
    250,
    [pageIndex, pageSize, formattedFilters, sortBy],
  );

  const matchIp = useMatch(`${RoutePaths.labelsIp}/:ip`);
  const matchContext = useMatch(`${RoutePaths.labelsIp}/context/:context`);
  const matchLabel = useMatch(`${RoutePaths.labelsIp}/label/:label`);

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const ipLabels = useSelector(
    ipLabelsSelectors.getIpLabelsPagination(getURLIndex(paginationParams)),
  );
  const totalIpCount = useSelector(
    ipLabelsSelectors.getTotalIpCount(getURLIndex(paginationParams)),
  );
  const lastIpCount = useSelector(ipLabelsSelectors.getLastIpCount);
  const isFetchingIpLabels = useSelector(ipLabelsSelectors.isFetching);
  const labelContexts = useSelector(ipLabelsSelectors.getContexts);

  const permissions = usePermissions(PermissionModel.Resources.label.value);

  const [showImportCsvModal, toggleImportCsvModal] = useToggle(false);
  const [ipLabelToManage, setIpLabelToManage] = useState(null);
  const [ipLabelToDelete, setIpLabelToDelete] = useState(null);
  const [showBulkDeleteModal, toggleBulkDeleteModal] = useToggle(false);

  useEffect(() => {
    if (!labelContexts?.length) {
      dispatch(ipLabelsActions.fetchContexts());
    }
  }, [labelContexts?.length]);

  useLoadingIndicator(isFetchingIpLabels);

  const cxActionMenu = useCallback(
    (id, original) => {
      const items = [
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          onClick: () => setIpLabelToManage(original),
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled: !permissions?.delete,
          onClick: () => setIpLabelToDelete(original),
        },
      ];

      return (
        <MenuColumnContextMenu
          title={original.ip}
          items={items}
          dataTracking="ip-label"
        />
      );
    },
    [permissions],
  );

  const tableColumns = useMemo(() => {
    const preparedColumns = Object.values(Columns);
    return getColumns(preparedColumns, { cxActionMenu, labelContexts });
  }, [cxActionMenu, labelContexts]);

  const tableFilters = useMemo(() => {
    const result = [];
    if (matchIp?.params?.ip) {
      result.push({
        id: 'ip',
        value: createFilterValue({
          value: matchIp?.params?.ip,
          operator: FilterOperator.like,
        }),
      });
    }
    if (matchContext?.params?.context) {
      result.push({
        id: 'context',
        value: createFilterValue({
          value: matchContext?.params?.context,
          operator: FilterOperator.equal,
        }),
      });
    }
    if (matchLabel?.params?.label) {
      result.push({
        id: 'labels',
        value: createFilterValue({
          value: matchLabel?.params?.label,
          operator: FilterOperator.like,
        }),
      });
    }
    return result;
  }, [
    matchIp?.params?.ip,
    matchContext?.params?.context,
    matchLabel?.params?.label,
  ]);

  useEffect(() => {
    setFilters(tableFilters);
  }, [tableFilters]);

  useEffect(() => {
    dispatch(ipLabelsActions.fetchIpLabelsWithPagination(paginationParams));
  }, [paginationParams]);

  const tableData = useMemo(
    () =>
      ipLabels?.filter((item) => {
        if (!item.customer) {
          return true;
        }
        return item.customer === customer?.shortname;
      }),
    [ipLabels, customer?.shortname],
  );

  const onIpLabelImportSubmit = useCallback((values) => {
    toggleImportCsvModal();
    dispatch(ipLabelsActions.bulkUploadFile(values));
  }, []);

  const onIpLabelAddToggle = useCallback(
    (event) => {
      if (!event) {
        return;
      }
      setIpLabelToManage((prevValue) => (prevValue ? null : defaultIpLabel));
    },
    [defaultIpLabel],
  );

  const onIpLabelAddSubmit = useCallback((values) => {
    setIpLabelToManage(null);

    if (values.id) {
      dispatch(ipLabelsActions.updateIpLabel(values));
    } else {
      dispatch(ipLabelsActions.createIpLabel(values));
    }
  }, []);

  const onIpLabelDeleteToggle = useCallback(() => {
    setIpLabelToDelete((prevValue) => (prevValue ? null : ipLabelToManage));
  }, [ipLabelToManage]);

  const onIpLabelDeleteSubmit = useCallback(() => {
    dispatch(ipLabelsActions.removeIpLabel(ipLabelToDelete));
    setIpLabelToDelete(null);
    setIpLabelToManage(null);
  }, [ipLabelToDelete]);

  const onDeleteAllSelection = useCallback(() => {
    toggleBulkDeleteModal();
    const removeData = selected.map((item) => {
      const { ip, context } = splitId(item);
      return { ip, context };
    });
    if (removeData.length) {
      dispatch(ipLabelsActions.bulkRemoveIpLabel(removeData));
    }
  }, [selected]);

  const onSelectedRowsChange = useCallback((selectedRowIds) => {
    setSelected((prev) => {
      const next = Object.entries(selectedRowIds || {})
        .map(([key, value]) => (value ? key : null))
        .filter(Boolean);

      if (!prev.length && !next.length) {
        return prev;
      }

      return next;
    });
  }, []);

  const getIsRowSelectorDisabled = useCallback(
    () => !permissions?.delete,
    [permissions],
  );

  return (
    <Fragment>
      <ActionsContainer>
        <Button onClick={onIpLabelAddToggle} disabled={!permissions?.create}>
          Add Label
        </Button>

        <Button
          variant={ButtonVariants.outlined}
          onClick={toggleImportCsvModal}
          disabled={!permissions?.create}
        >
          Import CSV
        </Button>

        <Button
          variant={ButtonVariants.outlined}
          onClick={toggleBulkDeleteModal}
          disabled={!permissions?.delete || !selected.length}
        >
          Delete Selected
        </Button>
      </ActionsContainer>

      <Table
        id={tableId}
        columns={tableColumns}
        data={tableData}
        filters={tableFilters}
        getIsRowSelectorDisabled={getIsRowSelectorDisabled}
        onSelectedRowsChange={onSelectedRowsChange}
        onPageSizeChange={setPageSize}
        onPageIndexChange={setPageIndex}
        onFiltersChange={setFilters}
        onSortByChange={setSortBy}
        rowsCount={totalIpCount || lastIpCount}
        pageSize={pageSize}
        pageCount={Math.ceil((totalIpCount || lastIpCount) / pageSize)}
        noDataText={isFetchingIpLabels ? 'Loading...' : 'No IP labels found'}
        sortBy={sortBy}
        serverPagination
        disableGroupBy
      />

      {showImportCsvModal && (
        <ImportCsvForm
          initialValues={{}}
          onToggle={toggleImportCsvModal}
          onSubmit={onIpLabelImportSubmit}
          isOpen
        />
      )}

      {!!ipLabelToManage && (
        <IpLabelForm
          initialValues={ipLabelToManage}
          onToggle={onIpLabelAddToggle}
          onSubmit={onIpLabelAddSubmit}
          onDelete={onIpLabelDeleteToggle}
          isOpen
        />
      )}

      {!!ipLabelToDelete && (
        <ConfirmModal
          item={`labels for ${ipLabelToDelete?.ip}`}
          onToggle={onIpLabelDeleteToggle}
          onConfirm={onIpLabelDeleteSubmit}
          toggleOnConfirm={false}
          isOpen
        />
      )}

      {showBulkDeleteModal && (
        <ConfirmModal
          item={`${selected.length} IP ${pluralize(selected.length, 'label')}`}
          confirmButtonText="delete"
          onToggle={toggleBulkDeleteModal}
          onConfirm={onDeleteAllSelection}
          toggleOnConfirm={false}
          isOpen
        />
      )}
    </Fragment>
  );
};

export { IpLabelForm };

export default IpLabels;
