import PropTypes from '+prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import LabelTypes from '@/models/LabelTypes';
import PermissionModel from '@/models/Permission';

import { selectors as customerSelectors } from '@/redux/api/customer';

import { PluginIcon } from '@/shared/img/icon';

import Button, { ButtonVariants } from '+components/Button';
import Flag from '+components/Flag';
import ContextLabel from '+components/Labels/ContextLabel';
import ContextNameLabel from '+components/Labels/ContextNameLabel';
import { Col, NoData, Row } from '+components/Layout';
import { geoValueToTextFormatter } from '+components/Table/Cells/formatters';
import useIpLabels from '+hooks/useIpLabels';
import usePermissions from '+hooks/usePermissions';
import usePortLabels from '+hooks/usePortLabels';
import { isProviderContext, isUserContext } from '+utils/labels';

const EditButton = styled(Button)`
  height: 21px;
  font-size: 12px;
`;

const LabelGroupCol = styled(Col)`
  ${EditButton} {
    visibility: hidden;
  }
  &:hover ${EditButton} {
    visibility: visible;
  }
`;

const unknown = 'Unknown';
const sortById = (a, b) => (a.id > b.id ? 1 : -1);
const getTitle = (item) => item.protocol || item.context;
const isClickable = (type) => [LabelTypes.ip, LabelTypes.port].includes(type);
const getIcon = (type, item, label) =>
  type === LabelTypes.ip && isProviderContext(item.context, label) ? (
    <PluginIcon name={label} size={14} />
  ) : null;

const Labels = (props) => {
  const { padding, activeData, onLabelClick, onLabelEdit } = props;

  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const permissionsLabel = usePermissions(
    PermissionModel.Resources.label.value,
  );

  const optionsIps = useMemo(
    () => ({
      fetchExact:
        activeData?.fieldType === LabelTypes.ip ? activeData?.value : null,
      customer: activeData?.customer,
    }),
    [activeData?.fieldType, activeData?.value, activeData?.customer],
  );

  const optionsPorts = useMemo(
    () => ({
      fetchExact:
        activeData?.fieldType === LabelTypes.port ? activeData?.value : null,
      customer: activeData?.customer,
    }),
    [activeData?.fieldType, activeData?.value, activeData?.customer],
  );

  const { ipLabels, isFetchingIpLabels } = useIpLabels(optionsIps);
  const { portLabels, isFetchingPortLabels } = usePortLabels(optionsPorts);

  /** * LABELS ** */

  const labels = useMemo(() => {
    const isSubAccountRequest =
      activeData?.customer && activeData?.customer !== customer?.shortname;

    // note: user's context labels should be first items in the list
    if (activeData?.fieldType === LabelTypes.ip) {
      const userContextLabels = [];
      const providerContextLabels = [];
      const systemContextLabels = [];

      ipLabels.forEach((item) => {
        // customer can be undefined or with value in the item or in the activeData
        if (
          (!isSubAccountRequest && item.customer) ||
          (isSubAccountRequest && item.customer !== activeData?.customer)
        ) {
          return;
        }
        if (item.ip !== activeData?.value) {
          return;
        }
        if (isUserContext(activeData?.fieldType, item)) {
          userContextLabels.push(item);
          return;
        }
        if (isProviderContext(item.context, item.labels[0])) {
          providerContextLabels.push(item);
          return;
        }
        systemContextLabels.push(item);
      });

      return [
        ...userContextLabels.sort(sortById),
        ...providerContextLabels.sort(sortById),
        ...systemContextLabels.sort(sortById),
      ];
    }

    if (activeData?.fieldType === LabelTypes.port) {
      const userContextLabels = [];
      const providerContextLabels = [];
      const systemContextLabels = [];

      Object.values(portLabels || {}).forEach((item) => {
        // customer can be undefined or with value in the item or in the activeData
        if (
          (!isSubAccountRequest && item.customer) ||
          (isSubAccountRequest && item.customer !== activeData?.customer)
        ) {
          return;
        }
        if (item.port !== +activeData?.value) {
          return;
        }
        if (isUserContext(activeData?.fieldType, item)) {
          userContextLabels.push(item);
        } else {
          systemContextLabels.push(item);
        }
      });

      return [
        ...userContextLabels.sort(sortById),
        ...providerContextLabels.sort(sortById),
        ...systemContextLabels.sort(sortById),
      ];
    }

    if (activeData?.fieldType === LabelTypes.asn) {
      return [
        {
          id: activeData?.value?.number,
          context: activeData?.value?.number,
          labels: [activeData?.value?.org || unknown],
        },
      ];
    }

    if (activeData?.fieldType === LabelTypes.geo) {
      const text = geoValueToTextFormatter(activeData?.value);
      const countrycode = activeData?.value?.countrycode;
      return [
        {
          id: countrycode,
          context: !countrycode ? null : (
            <Flag countryCode={countrycode} style={{}} />
          ),
          labels: [text],
        },
      ];
    }

    return [];
  }, [
    activeData?.fieldType,
    activeData?.value,
    activeData?.customer,
    customer?.shortname,
    ipLabels,
    portLabels,
  ]);

  if (!labels?.length) {
    let noDataText;
    if (![LabelTypes.ip, LabelTypes.port].includes(activeData?.fieldType)) {
      noDataText = 'No labels in the account for this field';
    } else {
      noDataText =
        isFetchingIpLabels || isFetchingPortLabels
          ? 'Loading...'
          : `No labels in the account for this ${
              activeData?.fieldType === LabelTypes.ip ? 'IP Address' : 'Port'
            }`;
    }
    return <NoData padding={padding}>{noDataText}</NoData>;
  }

  return (
    <Col gap="20px" paddingLeft={padding} paddingRight={padding}>
      {labels.map((item) => (
        <LabelGroupCol key={item.id} gap="8px">
          <Row wrap="nowrap" gap="16px">
            <Col container={false} item xs={12}>
              <ContextNameLabel>{getTitle(item)}</ContextNameLabel>
            </Col>

            {isUserContext(activeData?.fieldType, item) && (
              <Col container={false} xs item>
                <EditButton
                  variant={ButtonVariants.text}
                  onClick={onLabelEdit(activeData?.fieldType, item)}
                  disabled={!permissionsLabel?.update || !!activeData?.customer}
                >
                  Edit
                </EditButton>
              </Col>
            )}
          </Row>

          <Row gap="10px" overflow="hidden">
            {[...item.labels]
              .sort((a, b) => a.localeCompare(b))
              .map((label) => (
                <ContextLabel
                  key={`${item.id}-${label}`}
                  icon={getIcon(activeData?.fieldType, item, label)}
                  {...(isClickable(activeData?.fieldType)
                    ? {
                        onClick: onLabelClick(
                          activeData?.fieldType,
                          item,
                          label,
                          activeData?.customer,
                        ),
                        clickable: true,
                      }
                    : {})}
                >
                  {label}
                </ContextLabel>
              ))}
          </Row>
        </LabelGroupCol>
      ))}
    </Col>
  );
};

Labels.propTypes = {
  padding: PropTypes.string.isRequired,
  activeData: PropTypes.shape({
    fieldType: PropTypes.string,
    value: PropTypes.any,
    customer: PropTypes.string,
  }),
  onLabelClick: PropTypes.func.isRequired,
  onLabelEdit: PropTypes.func.isRequired,
};

Labels.defaultProps = {
  activeData: null,
};

export default Labels;
