import PropTypes from '+prop-types';
import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';

import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';

import { ContextTypes } from '@/models/ContextTypes';

import { selectors as deviceSelectors } from '@/redux/api/device';

import { DetectionDrawer } from '@/pages/Models/DetectionDrawer';

import Button, { ButtonVariants } from '+components/Button';
import { Col, LayoutTypes, Row } from '+components/Layout';
import { CellTrafficSourceName } from '+components/Table/Cells';
import SubAccountTag from '+components/Tag/SubAccountTag';
import UniversalField from '+components/UniversalField';

const cardBorderColor = (theme) => theme.cardTitleBorderColor;

const dmOptions = {
  withoutDescription: true,
};

const durationOptions = {
  unit: 'seconds',
};

export const Overview = memo((props) => {
  const { event, isSubAccountRecord } = props;

  const devices = useSelector(deviceSelectors.getDevices);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const nqlSearchOptions = useMemo(
    () => ({
      context: event?.traffic_type || ContextTypes.flow,
    }),
    [event?.traffic_type],
  );

  const fixedDevices = useMemo(
    () =>
      Object.fromEntries(
        Object.values(devices || {}).map((item) => [item.name, item.id]),
      ),
    [devices],
  );

  const onDMDetailsClick = useCallback(() => {
    setDrawerOpen(true);
  }, []);
  const onCloseDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const eventDuration =
    event?.duration || Math.floor(Date.now() / 1000) - event?.timestamp || 0;

  return (
    <Row $type={LayoutTypes.card} flex="1 1 0" minHeight="fit-content">
      <DetectionDrawer
        id={event?.tdm?.id}
        onClose={onCloseDrawer}
        open={drawerOpen}
      />

      <Col>
        <Row
          $type={LayoutTypes.title}
          borderBottom="1px solid"
          borderColor={cardBorderColor}
          justifyContent="space-between"
          alignItems="center"
        >
          Overview
          <Button variant={ButtonVariants.link} onClick={onDMDetailsClick}>
            Detection Model Details
          </Button>
        </Row>
        <Row wrap="nowrap" flex="1 1 0" minHeight="fit-content">
          <Col
            $type={LayoutTypes.cardContent}
            flex="1 1 0"
            borderRight="1px solid"
            borderColor={cardBorderColor}
          >
            {isSubAccountRecord && (
              <Row $type={LayoutTypes.field}>
                <Col $type={LayoutTypes.fieldName}>
                  <Stack direction="row" gap={0.5} alignItems="center">
                    <SubAccountTag icon={<AccountMultipleIcon size={16} />} />{' '}
                    Sub account:
                  </Stack>
                </Col>
                <Col $type={LayoutTypes.fieldValue}>{event.customer}</Col>
              </Row>
            )}
            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Summary:</Col>
              <Col $type={LayoutTypes.fieldValue}>{event.summary}</Col>
            </Row>
            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Detection Model:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                <UniversalField
                  field="algorithm"
                  value={event.algorithm}
                  original={event}
                  options={dmOptions}
                />
              </Col>
            </Row>
            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>NQL Search:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                <UniversalField
                  field="search"
                  original={event}
                  value={event?.search}
                  options={nqlSearchOptions}
                />
              </Col>
            </Row>
            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Categories</Col>
              <Col $type={LayoutTypes.fieldValue} gap="5px">
                {event?.categories?.map((item) => (
                  <UniversalField
                    field="categories"
                    value={item}
                    original={event}
                    key={item}
                    options={dmOptions}
                  />
                ))}
              </Col>
            </Row>
            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Response Policies:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                <UniversalField
                  field="rules"
                  value={event?.rules}
                  original={event}
                />
              </Col>
            </Row>
            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Integrations:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                <UniversalField
                  field="rules.plugins"
                  value={(event?.rules || []).flatMap((rule) => rule.plugins)}
                  original={event}
                />
              </Col>
            </Row>
          </Col>
          <Col $type={LayoutTypes.cardContent} flex="1 1 0">
            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>alerttype:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                <UniversalField
                  field="alerttype"
                  value={event.alerttype}
                  original={event}
                />
              </Col>
            </Row>

            {eventDuration === 0 ? (
              <Row $type={LayoutTypes.field}>
                <Col $type={LayoutTypes.fieldName}>Date:</Col>
                <Col $type={LayoutTypes.fieldValue}>
                  <UniversalField
                    field="start"
                    value={event.start}
                    original={event}
                  />
                </Col>
              </Row>
            ) : (
              <Fragment>
                <Row $type={LayoutTypes.field}>
                  <Col $type={LayoutTypes.fieldName}>start:</Col>
                  <Col $type={LayoutTypes.fieldValue}>
                    <UniversalField
                      field="start"
                      value={event.start}
                      original={event}
                    />
                  </Col>
                </Row>

                <Row $type={LayoutTypes.field}>
                  <Col $type={LayoutTypes.fieldName}>end:</Col>
                  <Col $type={LayoutTypes.fieldValue}>
                    <UniversalField
                      field="end"
                      value={event.end}
                      original={event}
                    />
                  </Col>
                </Row>

                <Row $type={LayoutTypes.field}>
                  <Col $type={LayoutTypes.fieldName}>duration:</Col>
                  <Col $type={LayoutTypes.fieldValue}>
                    <UniversalField
                      field="duration"
                      value={eventDuration}
                      original={event}
                      options={durationOptions}
                    />
                  </Col>
                </Row>
              </Fragment>
            )}

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Type:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                <UniversalField
                  field="traffic_type"
                  value={event.traffic_type || ContextTypes.flow}
                  disabled
                />
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Traffic Sources:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {(event.datasrcs?.length
                  ? event.datasrcs
                  : event.flowsrcnames
                )?.map((name) => {
                  const field = event.datasrcs?.length
                    ? 'datasrcs'
                    : 'flowsrcnames';
                  if (fixedDevices[name]) {
                    return (
                      <CellTrafficSourceName
                        key={`device-${name}`}
                        field={field}
                        value={name}
                        id={fixedDevices[name]}
                        type="device"
                        original={event}
                      />
                    );
                  }
                  return (
                    <CellTrafficSourceName
                      key={`cloud-${name}`}
                      field={field}
                      value={name}
                      type="cloud"
                      original={event}
                    />
                  );
                })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}># of SRC IPs:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {(event.srcips || []).length}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}># of SRC ports:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {(event.srcports || []).length}
              </Col>
            </Row>

            {event.traffic_type === 'flow' && (
              <Fragment>
                <Row $type={LayoutTypes.field}>
                  <Col $type={LayoutTypes.fieldName}># of DST IPs:</Col>
                  <Col $type={LayoutTypes.fieldValue}>
                    {(event.dstips || []).length}
                  </Col>
                </Row>

                <Row $type={LayoutTypes.field}>
                  <Col $type={LayoutTypes.fieldName}># of DST ports:</Col>
                  <Col $type={LayoutTypes.fieldValue}>
                    {(event.dstports || []).length}
                  </Col>
                </Row>
              </Fragment>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

Overview.displayName = 'Overview';

Overview.propTypes = {
  event: PropTypes.shape().isRequired,
  isSubAccountRecord: PropTypes.bool.isRequired,
};
