import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ContextTypes } from '@/models/ContextTypes';

import { Col, NoData } from '+components/Layout';
import { Tab } from '+components/Tabs';
import { useGetContextRecord, useIsFetchingContextRecord } from '+hooks';

import { BodyContainer } from '../BodyComponents';
import TabContent from '../TabContent';
import Tabs from '../Tabs';
import TabsContainer from '../TabsContainer';
import AuditLogOverview from './components/AuditLogOverview';
import BlockOverview from './components/BlockOverview';
import DnsOverview from './components/DnsOverview';
import EventOverview from './components/EventOverview';
import FlowOverview from './components/FlowOverview';
import IpIntelligenceWrapper from './components/IpIntelligenceWrapper';

const preparedTabs = {
  [ContextTypes.flow]: {
    value: 'flow_overview',
    label: 'Overview',
    component: FlowOverview,
    context: ContextTypes.flow,
  },
  [ContextTypes.dns]: {
    value: 'dns_overview',
    label: 'Overview',
    component: DnsOverview,
    context: ContextTypes.dns,
  },
  [ContextTypes.alerts]: {
    value: 'event_overview',
    label: 'Overview',
    component: EventOverview,
    context: ContextTypes.alerts,
  },
  [ContextTypes.blocks]: {
    value: 'block_overview',
    label: 'Overview',
    component: BlockOverview,
    context: ContextTypes.blocks,
  },
  [ContextTypes.audit]: {
    value: 'audit_overview',
    label: 'Overview',
    component: AuditLogOverview,
    context: ContextTypes.audit,
  },
};

const ipIntelTab = {
  value: 'ip_intell',
  label: 'IP Intelligence',
  component: IpIntelligenceWrapper,
  tabContentProps: { $marginTop: '0 !important' },
};

const RecordBody = (props) => {
  const { padding, activeData, isOpen } = props;

  const record = useGetContextRecord(
    activeData?.recordType,
    activeData?.value?.id,
  );
  const isFetching = useIsFetchingContextRecord(
    activeData?.recordType,
    activeData?.value?.id,
  );

  const [currentTab, setCurrentTab] = useState({ value: false });

  const tabs = useMemo(
    () => [preparedTabs[activeData?.recordType], ipIntelTab].filter(Boolean),
    [activeData?.recordType],
  );

  const defaultTab = useMemo(() => tabs[0], [tabs]);

  const onTabChange = useCallback(
    (_, value) => {
      setCurrentTab(
        tabs.find((tab) => tab.value === value) || { value: false },
      );
    },
    [tabs],
  );

  useEffect(() => {
    setCurrentTab(defaultTab);
  }, [activeData, defaultTab]);

  const isEmpty = !record;

  const Component = useMemo(() => {
    if (currentTab?.value === ipIntelTab.value) {
      return currentTab?.component;
    }
    return currentTab?.context === activeData?.recordType
      ? currentTab?.component
      : null;
  }, [currentTab, activeData?.recordType]);

  const componentProps = {
    padding,
    record,
    ...(currentTab?.value === ipIntelTab.value && {
      recordType: activeData?.recordType,
    }),
  };

  const tabContentProps = currentTab?.tabContentProps || {};

  const isValidTab = tabs.some((tab) => tab.value === currentTab?.value);

  return (
    <BodyContainer $padding={padding} $isOpen={isOpen}>
      {isFetching && isOpen && isEmpty && (
        <NoData paddingLeft={padding} paddingRight={padding}>
          Loading...
        </NoData>
      )}

      {!isFetching && isOpen && isEmpty && activeData?.info && (
        <Col paddingLeft={padding} paddingRight={padding}>
          {activeData?.info}
        </Col>
      )}

      {!isFetching && isOpen && isEmpty && !activeData?.info && (
        <NoData paddingLeft={padding} paddingRight={padding}>
          No data to display
        </NoData>
      )}

      {isOpen && !isEmpty && isValidTab && (
        <TabsContainer>
          <Tabs
            $padding={padding}
            // Workaround for `MUI: The value provided to the Tabs component is invalid.`
            // @see: https://github.com/mui/material-ui/issues/32749
            value={currentTab?.value ?? false}
            onChange={onTabChange}
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>

          {Component && (
            <TabContent {...tabContentProps}>
              <Component {...componentProps} />
            </TabContent>
          )}
        </TabsContainer>
      )}
    </BodyContainer>
  );
};

RecordBody.propTypes = {
  padding: PropTypes.string.isRequired,
  activeData: PropTypes.shape({
    info: PropTypes.any,
    recordType: PropTypes.string,
    value: PropTypes.object,
  }),
  isOpen: PropTypes.bool,
};

RecordBody.defaultProps = {
  activeData: null,
  isOpen: false,
};

export default RecordBody;
