/* eslint-disable react/no-array-index-key */
import PropTypes from '+prop-types';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import Stack from '@mui/material/Stack';

import { ContextTypes } from '@/models/ContextTypes';
import RoutePaths from '@/models/RoutePaths';

import { selectors as rulesSelectors } from '@/redux/api/rules';

import Button, { ButtonVariants } from '+components/Button';
import CodeBlock from '+components/form/NqlTextField/components/CodeBlock';
import SeverityLabel from '+components/Labels/SeverityLabel';
import { Col, LayoutTypes, Row } from '+components/Layout';
import { usePageTabs } from '+components/PageTabs';
import { CellNqlSearch } from '+components/Table/Cells';
import UniversalField from '+components/UniversalField';
import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';

const CanBeEmptyRow = styled(Row)`
  &:empty::before {
    content: '—';
  }
`;

const Scrolled = styled(Stack)`
  overflow-x: hidden;
  overflow-y: auto;
  ${ScrollBarMixin};
  height: 100%;
  position: relative;
`;

const ButtonContainer = styled(Stack)`
  background-color: ${({ theme }) => theme.drawerBackgroundColor};
  position: sticky;
  bottom: 0;
  z-index: 2;
`;

export const DrawerBody = memo((props) => {
  const { id, onClose } = props;

  const [, , pageTabMethods] = usePageTabs();

  const algorithm = useSelector(rulesSelectors.getAlgorithm(id));

  const context = algorithm?.algo_record_type || ContextTypes.flow;

  const onCloseClick = useCallback(() => {
    onClose?.('closeButton');
  }, [onClose]);

  const onEditClick = useCallback(() => {
    pageTabMethods.add(
      `${algorithm?.algo_type === 'CDM' ? RoutePaths.modelsContext : RoutePaths.modelsDetection}/${id}`,
    );
    onClose?.('editButton');
  }, [algorithm?.algo_type, id]);

  return (
    <Scrolled px="10px" pt="20px" position="relative">
      <Col spacing={2}>
        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            {algorithm?.algo_type === 'CDM' ? 'Context Creation' : 'Detection'}{' '}
            Model
          </Row>
          <Row item $type={LayoutTypes.cardContent} py={0}>
            <UniversalField field="tdm" value={algorithm} />
          </Row>
        </Col>

        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            Categories / MITRE Techniques
          </Row>
          {(algorithm?.categories || []).map?.((category) => (
            <Row
              item
              $type={LayoutTypes.cardContent}
              py={0}
              key={category.name}
            >
              <UniversalField field="categories" value={category} />
            </Row>
          ))}
        </Col>

        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            Traffic
          </Row>
          <Row item $type={LayoutTypes.cardContent} py={0}>
            <UniversalField field="algo_record_type" value={context} disabled />
          </Row>
        </Col>

        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            NQL Search
          </Row>

          {(algorithm?.search_by || []).map?.((by) => (
            <Row key={by.type} item $type={LayoutTypes.cardContent} py={0}>
              {(by.search || []).map((item, index) => (
                <CellNqlSearch
                  key={index}
                  value={item}
                  type={index ? null : by.type}
                  context={context}
                />
              ))}
            </Row>
          ))}
        </Col>

        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            Thresholds
          </Row>
          {algorithm?.thresholds?.map?.((item, index) => (
            <Row key={index} item $type={LayoutTypes.cardContent} py={0}>
              <Stack direction="row" gap="5px" alignItems="baseline">
                <SeverityLabel severity={item.severity} />
                <CodeBlock $interactive $transparent>
                  {item.threshold}
                </CodeBlock>
              </Stack>
            </Row>
          ))}
        </Col>

        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            Auto Thresholding
          </Row>
          <Row item $type={LayoutTypes.cardContent} py={0}>
            {algorithm?.autothreshold ? 'Enabled' : 'Disabled'}
          </Row>
        </Col>

        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            Response Policies
          </Row>
          <CanBeEmptyRow
            item
            $type={LayoutTypes.cardContent}
            py={0}
            container={false}
          >
            <UniversalField field="rules" value={algorithm?.rules} />
          </CanBeEmptyRow>
        </Col>

        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            Integrations
          </Row>
          <CanBeEmptyRow
            item
            $type={LayoutTypes.cardContent}
            py={0}
            container={false}
          >
            <UniversalField
              field="rules.plugins"
              value={(algorithm?.rules || []).flatMap((rule) => rule.plugins)}
            />
          </CanBeEmptyRow>
        </Col>
      </Col>

      <ButtonContainer mt="20px">
        <Col>
          <Row item $type={LayoutTypes.cardContent}>
            <Stack direction="row" gap="10px">
              <Button onClick={onCloseClick}>Close</Button>
              <Button variant={ButtonVariants.outlined} onClick={onEditClick}>
                Edit
              </Button>
            </Stack>
          </Row>
        </Col>
      </ButtonContainer>
    </Scrolled>
  );
});

DrawerBody.displayName = 'DrawerBody';

DrawerBody.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

DrawerBody.defaultProps = {
  onClose: undefined,
};
