import PropTypes from '+prop-types';
import { memo } from 'react';

import Stack from '@mui/material/Stack';

import Button, { ButtonVariants } from '+components/Button';
import { LayoutSizes, LayoutTypes, Row } from '+components/Layout';

import { Pagination } from './Pagination';

export const Title = memo((props) => {
  const { onShowAllClick, collapsed, max, onIndexChange } = props;
  return (
    <Row $type={LayoutTypes.title} gap={LayoutSizes.groupGap}>
      Thresholds
      {collapsed && (max || 0) > 1 && (
        <Pagination max={max} onChange={onIndexChange} />
      )}
      <Stack direction="row" flex={1} />
      <Button
        variant={ButtonVariants.link}
        onClick={onShowAllClick}
        disabled={!onShowAllClick}
      >
        {collapsed ? 'Show all' : 'Show one'}
      </Button>
    </Row>
  );
});

Title.displayName = 'Title';

Title.propTypes = {
  onShowAllClick: PropTypes.func,
  onIndexChange: PropTypes.func,
  collapsed: PropTypes.bool,
  max: PropTypes.number,
};

Title.defaultProps = {
  onIndexChange: undefined,
  onShowAllClick: undefined,
  collapsed: true,
  max: 0,
};
