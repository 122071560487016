export default {
  gray: '#787985',

  // UI2020
  primaryLight: '#08616D',
  primaryDark: '#52BBB7',
  white: '#FFFFFF',

  gray2: '#A2A2A2',
  gray3: '#707070',
  gray4: '#54545A',
  gray7: '#202124',

  // Chart & Alert Colors
  success: '#53E0B7',
  warning: '#F6D874',
  medium: '#FE9F43',
  danger: '#FD4862',
};
