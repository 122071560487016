import PropTypes from '+prop-types';
import { useCallback } from 'react';

import classNames from 'classnames';
import styled, { css } from 'styled-components';

import * as PropertiesTray from '@/models/PropertiesTray';

import GenericTag from '+components/Tag/GenericTag';
import useEvent from '+hooks/useEvent';
import useUIProperty from '+hooks/useUIProperty';
import { UnderlineMixin } from '+theme/mixins/underlineMixin';
import makeArr from '+utils/makeArr';

const Container = styled.div`
  ${(props) =>
    !props.$hideUnderline &&
    !props.$disabled &&
    css`
      > span {
        ${UnderlineMixin}
      }
    `}
  ${(props) =>
    props.$disabled &&
    css`
      pointer-events: none;
      * {
        pointer-events: none;
      }
    `}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.__chip-ellipsis {
    ${GenericTag} {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
  }
`;

const CellWrapper = (props) => {
  const {
    className,
    title,
    customer: customerProp,
    field,
    value,
    stream,
    info,
    additionalMenuOptions,
    children,
    disabled,
    hideUnderline,
    clickable,
    showMenuOnPrimaryClick,
  } = props;

  const [hideNav] = useUIProperty('hideNav');
  const [, setPropertiesTray] = useUIProperty('propertiesTray', null);
  const [, setGlobalContextMenu] = useUIProperty('globalContextMenu', null);

  const openContextMenu = useCallback(
    (e, customers) => {
      const menuItem = {
        dataType: PropertiesTray.DataTypes.field,
        title,
        field,
        value,
        stream,
        additionalMenuOptions,
      };
      setGlobalContextMenu({
        data: customers?.length
          ? customers.map((customer) => ({
              ...menuItem,
              customer,
            }))
          : [menuItem],
        event: e,
      });
    },
    [title, field, value, stream, additionalMenuOptions],
  );

  const onClick = useEvent((e) => {
    // we need to stop propagation for GenericLabel component
    // which can be parent of CellWrapper
    // without this, we will have event loop in case of click on GenericLabel context (left part)
    e.stopPropagation();
    const customers = makeArr(customerProp).filter(Boolean);

    // ENG-969 ENG-1216 - all non-IP labels should show the context menu on primary click
    // IP labels should show the sidebar tray on primary click
    if (showMenuOnPrimaryClick) {
      openContextMenu(e, customers);
      return;
    }
    const trayItem = {
      dataType: PropertiesTray.DataTypes.field,
      title,
      field,
      value,
      stream,
      info,
      additionalMenuOptions,
    };
    setPropertiesTray({
      data: customers.length
        ? customers.map((customer) => ({
            ...trayItem,
            customer,
          }))
        : [trayItem],
      isOpen: true,
    });
  });

  const onContextMenu = useEvent((e) => {
    // we need to stop propagation for GenericLabel component
    // which can be parent of CellWrapper
    // without this, we will have event loop in case of click on GenericLabel context (left part)
    e.stopPropagation();

    const customers = makeArr(customerProp).filter(Boolean);
    openContextMenu(e, customers);
  });

  const isDisabled = disabled || hideNav || !clickable;

  return (
    <Container
      className={classNames(className, { 'menu-trigger': true })}
      type={isDisabled ? undefined : 'button'}
      onClick={isDisabled ? undefined : onClick}
      onContextMenu={isDisabled ? undefined : onContextMenu}
      $disabled={isDisabled}
      $hideUnderline={hideUnderline}
    >
      {children}
    </Container>
  );
};

CellWrapper.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  customer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  field: PropTypes.string,
  value: PropTypes.any,
  stream: PropTypes.shape({}),
  info: PropTypes.any,
  children: PropTypes.children,
  additionalMenuOptions: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  hideUnderline: PropTypes.bool,
  clickable: PropTypes.bool,
  showMenuOnPrimaryClick: PropTypes.bool,
};

CellWrapper.defaultProps = {
  className: '',
  title: undefined,
  customer: undefined,
  field: undefined,
  value: undefined,
  stream: undefined,
  info: undefined,
  children: undefined,
  additionalMenuOptions: undefined,
  disabled: false,
  hideUnderline: false,
  clickable: true,
  showMenuOnPrimaryClick: true,
};

export default CellWrapper;
