import PropTypes from '+prop-types';
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useField } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMeasure } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';

import { ContextTypes, ContextTypesLabels } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';
import FeatureFlags from '@/models/FeatureFlags';
import SettingCategories from '@/models/SettingCategories';
import { TimeDuration, TimePeriods } from '@/models/TimePeriods';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';
import {
  actions as ipLabelsActions,
  selectors as ipLabelsSelectors,
} from '@/redux/api/labels/ips';
import { selectors as profileSelectors } from '@/redux/api/user/profile';
import { actions as globalFiltersActions } from '@/redux/globalFilters';
import {
  actions as globalFiltersUiActions,
  selectors as globalFiltersUISelectors,
} from '@/redux/globalFilters/ui';
import { selectors as socketControlSelectors } from '@/redux/ui/socketControl';

import Button, { ButtonVariants } from '+components/Button';
import { DateTimeRange } from '+components/form/DateTimeRange';
import { Field, useForm, useFormState } from '+components/form/FinalForm';
import {
  normalizeDateTimeToNumber,
  normalizeMultiSelectValue,
  normalizeSelectValue,
} from '+components/form/Normalizers';
import { ToggleField } from '+components/form/Toggle';
import {
  validateDateTime,
  validateDateTimeBetween,
  validateDateTimeCannotBeSame,
  validateRequired,
} from '+components/form/Validators';
import {
  DateTimePickerField,
  NumberField,
  SelectField,
} from '+components/GlobalFilters/Components';
import Context from '+components/GlobalFilters/Context';
import IconButton from '+components/IconButton';
import { usePageTabs } from '+components/PageTabs';
import { Tab, Tabs, TabsContainer } from '+components/Tabs';
import * as toast from '+components/toast';
import Tooltip from '+components/Tooltip';
import { useVerifyNqlBeforeSend } from '+hooks';
import { useAllMetricsAndFields } from '+hooks/useAllMetricsAndFields';
import useGlobalFilters from '+hooks/useGlobalFilters';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useUIProperty from '+hooks/useUIProperty';
import { pluralize } from '+utils';
import dayjs from '+utils/dayjs';
import getMetricFieldName from '+utils/getMetricFieldName';
import getNqlFieldName from '+utils/getNqlFieldName';

import AdditionalFiltersButton from './AdditionalFiltersButton';
import AdditionalFiltersDropdownField, {
  AdditionalFiltersDropdownCaptureContainer,
  AdditionalFiltersDropdownCaptureLabel,
  AdditionalFiltersDropdownCaptureValue,
} from './AdditionalFiltersDropdownField';
import {
  AdditionalFiltersMore,
  AdditionalFiltersMoreDropdown,
} from './AdditionalFiltersMoreDropdown';
import AdditionalFiltersMultiDropdownField, {
  AdditionalFiltersMultiDropdownCaptureContainer,
  AdditionalFiltersMultiDropdownCaptureLabel,
  AdditionalFiltersMultiDropdownCaptureValue,
  AdditionalFiltersMultiDropdownShowMore,
} from './AdditionalFiltersMultiDropdownField';
import AdditionalFiltersRow from './AdditionalFiltersRow';
import AdditionalFiltersRowItem from './AdditionalFiltersRowItem';
import AdditionalFiltersSeparator from './AdditionalFiltersSeparator';
import ApplyButton from './ApplyButton';
import ContextDropdownField from './ContextDropdownField';
import DateTimeDropdown, {
  DateTimeMenuCol,
  DateTimeMenuContainer,
  DateTimeMenuLabel,
  DateTimeMenuRow,
} from './DateTimeDropdown';
import FormContainer from './FormContainer';
import MainNqlFilter, {
  NqlButtonsContainer,
  NQLFieldClear,
  NQLFieldContext,
  NqlFieldError,
  NQLFieldLabel,
  NQLFieldSave,
} from './MainNqlFilter';
import MainRow from './MainRow';
import MainRowGroup from './MainRowGroup';
import MainRowNqlGroup from './MainRowNqlGroup';
import MainRowSection from './MainRowSection';
import MainRowSeparator from './MainRowSeparator';
import SocketControlButton from './SocketControlButton';

const contextLabel = 'Filter By';
const labelContextsLabel = 'Display Labels';
const metricLabel = 'Metric';
const subAccountLabel = 'Filter by sub-accounts';

const parserPeriodValue = (input) => input.replace(/[^\d]/g, '');
const parserNql = (v) => v;
const isEqualNql = (a, b) => (a || '') === (b || '');
const isEqualSubAccounts = (a, b) =>
  isEqual([...(a || [])].sort(), [...(b || [])].sort());

const getContextOptions = (excludeContexts) => {
  const isExcluded = new Set(excludeContexts || []);
  return [
    {
      value: ContextTypes.flow,
      label: ContextTypesLabels[ContextTypes.flow],
      disabled: isExcluded.has(ContextTypes.flow),
    },
    {
      value: ContextTypes.dns,
      label: ContextTypesLabels[ContextTypes.dns],
      disabled: isExcluded.has(ContextTypes.dns),
    },
    {
      value: ContextTypes.traffic,
      label: ContextTypesLabels[ContextTypes.traffic],
      disabled: isExcluded.has(ContextTypes.traffic),
    },
    {
      value: ContextTypes.alerts,
      label: ContextTypesLabels[ContextTypes.alerts],
      disabled: isExcluded.has(ContextTypes.alerts),
    },
    {
      value: ContextTypes.blocks,
      label: ContextTypesLabels[ContextTypes.blocks],
      disabled: isExcluded.has(ContextTypes.blocks),
    },
  ].filter(Boolean);
};

const toastWarn = (daysLimit) => {
  toast.warn({
    message:
      'Selected period is more than available, it was changed to maximum',
    details: `Maximum period is ${daysLimit} ${pluralize(daysLimit, 'day')}`,
  });
};

const FormBody = (props) => {
  const { initialValues, handleSubmit, $separatorWidth } = props;

  const { portalChildren: additionalPageFilters } = useContext(Context);

  const dispatch = useDispatch();
  const location = useLocation();

  const form = useForm();
  const {
    pristine,
    values: formValues,
    dirtyFields,
  } = useFormState({
    subscription: {
      pristine: true,
      values: true,
      dirtyFields: true,
    },
  });

  const currentContext = formValues.context || ContextTypes.flow;

  const { meta: nqlFieldMeta } = useField(
    `${getNqlFieldName(currentContext)}[0]`,
  );
  const nqlFieldError = useMemo(() => {
    const errorMessage = nqlFieldMeta.error || nqlFieldMeta.submitError;
    const touched =
      nqlFieldMeta.touched && (nqlFieldMeta.dirty || nqlFieldMeta.submitFailed);
    const invalid = errorMessage && (errorMessage !== 'Required' || touched);
    return invalid ? errorMessage : null;
  }, [nqlFieldMeta]);

  const [guest] = useUIProperty('guest');
  const [filters] = useGlobalFilters();

  const [, activePageTab] = usePageTabs();
  const ui = useSelector(globalFiltersUISelectors.getAllState);
  const isAdditionalFiltersExpanded = useSelector(
    globalFiltersUISelectors.getDisplayAdditionalFilters,
  );
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const retention = useSelector(customerSelectors.getRetention);
  const isSocketPaused = useSelector(
    socketControlSelectors.isPaused(activePageTab?.id),
  );

  const isRolesUiSettingsEnabled = useFlag(FeatureFlags.rolesUiSettings);
  const profile = useSelector(profileSelectors.getProfile);
  const [userRoleUiSettings] = usePortalSettingsValue(
    SettingCategories.ui,
    `${profile?.roles?.[0]}:settings`,
    {},
  );
  const [sideBarRightWidth] = usePortalSettingsValue(
    SettingCategories.ui,
    'sideBarRightWidth',
    0,
  );
  const [sideBarRightWidthLocal] = useUIProperty(
    'sideBarRightWidth',
    sideBarRightWidth ?? 0,
  );
  const [propertiesTray] = useUIProperty('propertiesTray', null);
  const [, setGlobalNqlPresetChange] = useUIProperty(
    'globalNqlPresetChange',
    null,
  );

  const isPropertiesTrayOpen = !!propertiesTray?.isOpen;

  const submitButtonRef = useRef();

  const nqlGroupRowRef = useRef(null);

  const [isNqlGroupRowMouseEnter, setIsNqlGroupRowMouseEnter] = useState(false);
  const [isNqlFieldActive, setIsNqlFieldActive] = useState(false);
  const [isContextFieldActive, setIsContextFieldActive] = useState(false);
  const [isDateTimeFieldActive, setIsDateTimeFieldActive] = useState(false);
  const [isPeriodTypeMenuOpen, setIsPeriodTypeMenuOpen] = useState(false);

  const [additionalFiltersRowRef, { width: additionalFiltersRowWidth }] =
    useMeasure();

  const { validateNql } = useVerifyNqlBeforeSend(
    formValues.context,
    `${getNqlFieldName(currentContext)}[0]`,
  );

  const isCustomType = formValues.period?.type === CustomType;

  const uiDisabled = {
    periodValue: isCustomType || !ui.range,
    periodType: isCustomType || !ui.range,
    from: !isCustomType || !ui.from,
    to: !isCustomType || !ui.to,
    nql: !ui.nql,
    metric: !ui.metric,
    customers: !ui.customers,
    socketControl: !ui.socketControl,
    displayLabels: !ui.displayLabels,
  };

  uiDisabled.apply =
    uiDisabled.displayLabels &&
    uiDisabled.periodValue &&
    uiDisabled.periodType &&
    uiDisabled.from &&
    uiDisabled.to &&
    uiDisabled.nql &&
    uiDisabled.metric &&
    uiDisabled.customers;

  const contextOptions = useMemo(
    () => [
      {
        value: contextLabel,
        label: contextLabel,
        header: true,
      },
      ...getContextOptions(ui.excludeContexts),
    ],
    [ui.excludeContexts],
  );

  const { allMetrics } = useAllMetricsAndFields(formValues.context, {
    metrics: true,
  });

  const metricsOptions = useMemo(() => {
    const options = (allMetrics[formValues.context] || []).map((el) => ({
      value: el.metric || el.field,
      label: el.metric || el.field,
      description: el.description,
      disabled: (ui.excludeMetrics || []).includes(el.metric),
    }));
    options.unshift({
      value: metricLabel,
      label: metricLabel,
      header: true,
    });
    return options;
  }, [allMetrics, formValues.context, ui.excludeMetrics]);

  const periodOptions = useMemo(() => {
    const allowedPeriods = [
      TimeDuration.minute,
      TimeDuration.hour,
      TimeDuration.day,
      TimeDuration.week,
      TimeDuration.month,
    ];
    return allowedPeriods.map((value) => ({
      value,
      label:
        formValues.period?.value > 1
          ? TimePeriods[value].unit
          : TimePeriods[value].unit.slice(0, -1),
    }));
  }, [formValues.period?.value]);

  const isIpLabelsFetching = useSelector(ipLabelsSelectors.isFetching);
  const labelContexts = useSelector(ipLabelsSelectors.getContexts);
  const subAccountLabelContexts = useSelector(
    ipLabelsSelectors.getSubAccountsContexts(filters?.customers),
  );

  useEffect(() => {
    if (isIpLabelsFetching) {
      return;
    }
    if (!labelContexts?.length) {
      dispatch(ipLabelsActions.fetchContexts());
    }
  }, [isIpLabelsFetching, labelContexts?.length]);

  useEffect(() => {
    if (isIpLabelsFetching) {
      return;
    }
    const emptySubAccountsContexts = filters?.customers?.filter(
      (item) => !subAccountLabelContexts?.[item]?.length,
    );
    if (emptySubAccountsContexts?.length) {
      dispatch(
        ipLabelsActions.fetchSubAccountsContexts(emptySubAccountsContexts),
      );
    }
  }, [isIpLabelsFetching, filters?.customers, subAccountLabelContexts]);

  const labelContextsOptions = useMemo(() => {
    const contexts = filters?.customers?.length
      ? Object.values(subAccountLabelContexts || {}).flat()
      : labelContexts;

    const optionsHash = (contexts || []).flat().reduce((acc, item) => {
      if (item.alias || item.value === 'name') {
        return acc;
      }
      let record = acc[item.value];
      if (!record) {
        record = { value: item.value, label: item.value, customers: [] };
      }
      if (item.customer && !record.customers.includes(item.customer)) {
        record.customers.push(item.customer);
      }
      acc[item.value] = record;
      return acc;
    }, {});

    const options = Object.values(optionsHash)
      .map((item) => ({
        ...item,
        description: item.customers?.sort().join(', '),
      }))
      .sort((a, b) => a.value.localeCompare(b.value));

    options.unshift(
      ...[
        { value: labelContextsLabel, label: labelContextsLabel, header: true },
        { value: false, label: 'None' },
        { separator: true },
        { value: 'name', label: 'name' },
      ],
    );

    return options;
  }, [labelContexts, subAccountLabelContexts, filters?.customers?.length]);

  const customers = useSelector(customerSelectors.getCustomers);
  const areAllCustomersFetched = useSelector(customerSelectors.areAllFetched);
  useEffect(() => {
    if (!customer?.multi_account || areAllCustomersFetched) {
      return undefined;
    }
    const namespace = 'fetch_customers';
    dispatch(customerActions.fetch(customer?.shortname, namespace));
    return () => {
      dispatch(customerActions.cancel(namespace));
    };
  }, [customer?.multi_account, areAllCustomersFetched, customer?.shortname]);
  const subAccountOptions = useMemo(() => {
    if (!customer?.multi_account) {
      return [];
    }
    const options = Object.values(customers || {})
      .reduce((acc, item) => {
        const canAggSubAccountData =
          customer?.shortname === 'netography' ||
          (customer?.shortname === item.parent &&
            item.meta.reseller_data_agg_enabled);
        const haveSameRetention =
          customer?.retention === item.retention &&
          customer?.rollupRetention === item.rollupRetention;
        if (!canAggSubAccountData || !haveSameRetention) {
          return acc;
        }
        return [
          ...acc,
          {
            value: item.shortname,
            label: item.shortname,
            description: item.organization,
          },
        ];
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label));
    options.unshift({
      value: customer?.shortname,
      label: `${customer?.shortname} (current)`,
      description: customer?.organization,
    });
    options.unshift({
      value: subAccountLabel,
      label: subAccountLabel,
      header: true,
    });
    return options;
  }, [customers, customer]);

  const minDate = useMemo(
    () =>
      dayjs(new Date(2019, 0, 1))
        .startOf('day')
        .toDate(),
    [],
  );

  const maxDate = useMemo(() => dayjs().endOf('day').millisecond(0), []);

  const fromDateValidator = useMemo(
    () =>
      uiDisabled.from
        ? null
        : [
            validateRequired,
            validateDateTime,
            validateDateTimeCannotBeSame({
              fieldName: 'to',
              errorMessage: 'From date and To date cannot be the same.',
            }),
            validateDateTimeBetween({
              min: minDate,
              maxFieldName: 'to',
              includeMin: true,
              includeMax: false,
            }),
          ],
    [uiDisabled.from, minDate],
  );

  const toDateValidator = useMemo(
    () =>
      uiDisabled.to
        ? null
        : [
            validateRequired,
            validateDateTime,
            validateDateTimeCannotBeSame({
              fieldName: 'from',
              errorMessage: ' ',
            }),
            validateDateTimeBetween({
              minFieldName: 'from',
              max: maxDate,
              includeMin: false,
              includeMax: true,
            }),
          ],
    // To prevent form validation looping do not put 'max' to deps (validator will update if form values changed)
    // @see: https://netography.atlassian.net/browse/PORTAL-1415
    [uiDisabled.to, maxDate],
  );

  const onNqlClear = useCallback(() => {
    form.change(`${getNqlFieldName(currentContext)}`, ['']);
    form.mutators.touched(`${getNqlFieldName(currentContext)}`, true);
  }, [currentContext]);

  const doSubmit = useCallback(() => {
    submitButtonRef.current?.click();
  }, []);

  const onNqlKeyPress = useCallback(
    (event) => {
      if (event?.key === 'Enter') {
        document.activeElement.blur();
        doSubmit();
        document.activeElement.focus();
      }
    },
    [doSubmit],
  );

  const onPeriodValueEnterPress = useCallback(
    (event) => {
      if (event?.key === 'Enter') {
        doSubmit();
      }
    },
    [doSubmit],
  );

  const onPeriodTypeKeyDown = useCallback(
    (event) => {
      if (event?.key === 'Enter' && !isPeriodTypeMenuOpen) {
        doSubmit();
      }
    },
    [isPeriodTypeMenuOpen, doSubmit],
  );

  const onPeriodTypeMenuOpen = useCallback(
    () => setIsPeriodTypeMenuOpen(true),
    [],
  );

  const onPeriodTypeMenuClose = useCallback(
    () => setIsPeriodTypeMenuOpen(false),
    [],
  );

  const onDateTimeModeChange = useCallback(
    (_, value) => {
      if (value === 'absolute') {
        form.batch(() => {
          form.change('period.type', CustomType);
          form.change('period.value', initialValues.period?.value || 4);
          form.change('from', initialValues.from);
          form.change('to', initialValues.to);
        });
        return;
      }

      let periodType = initialValues.period?.type;
      if (!periodType || periodType === CustomType) {
        periodType = TimeDuration.hour;
      }

      form.batch(() => {
        form.change('period.type', periodType);
        form.change('period.value', initialValues.period?.value || 4);
        form.change('from', initialValues.from);
        form.change('to', initialValues.to);
      });
    },
    [initialValues.period, initialValues.from, initialValues.to],
  );

  const onDateTimeRangeChange = useCallback((value) => {
    form.change('from', normalizeDateTimeToNumber(value.startDate));
    form.change('to', normalizeDateTimeToNumber(value.endDate));
  }, []);

  const onSetPeriod = useCallback((type, value) => {
    form.change('period.type', type);
    form.change('period.value', value);
  }, []);

  const onRefreshClick = useCallback(() => {
    dispatch(globalFiltersActions.refreshRefresherManual());
  }, []);

  const onNqlPresetSave = useCallback(() => {
    setGlobalNqlPresetChange({
      id: null,
      title: '',
      context: currentContext,
      nql: formValues[getNqlFieldName(currentContext)]?.[0],
    });
  }, [formValues, currentContext]);

  const onDateTimeDropdownOpenOrClose = useCallback(
    (isOpen) => {
      if (!isOpen) {
        const periodLimitInDays = Math.min(
          isRolesUiSettingsEnabled && userRoleUiSettings.dateTimeLimit
            ? userRoleUiSettings.dateTimeLimit
            : retention,
          retention,
        );
        const periodLimitInSeconds = Math.abs(
          periodLimitInDays * TimePeriods[TimeDuration.day].query,
        );

        if (formValues.period.type === CustomType) {
          const periodInSeconds =
            Math.abs(formValues.to - formValues.from) / 1000;
          if (periodInSeconds > periodLimitInSeconds) {
            form.change('from', formValues.to - periodLimitInSeconds * 1000);
            toastWarn(periodLimitInDays);
          }
        } else {
          const periodInSeconds = Math.abs(
            formValues.period.value * TimePeriods[formValues.period.type].query,
          );
          if (periodInSeconds > periodLimitInSeconds) {
            form.batch(() => {
              form.change('period.type', TimeDuration.day);
              form.change('period.value', periodLimitInDays);
            });
            toastWarn(periodLimitInDays);
          }
        }
      }

      setIsDateTimeFieldActive(isOpen);
    },
    [
      isRolesUiSettingsEnabled,
      userRoleUiSettings?.dateTimeLimit,
      retention,
      formValues.period,
      formValues.from,
      formValues.to,
    ],
  );

  useEffect(() => {
    const checkCaretPosition = () => {
      setIsNqlFieldActive(
        nqlGroupRowRef.current?.contains(document.activeElement),
      );
    };

    document.addEventListener('click', checkCaretPosition);
    return () => {
      document.removeEventListener('click', checkCaretPosition);
    };
  }, []);

  // update filter context
  useEffect(() => {
    if (!!activePageTab?.id && formValues.context !== initialValues.context) {
      dispatch(
        globalFiltersActions.changeFilter({
          tabId: activePageTab?.id,
          context: formValues.context,
        }),
      );
    }
  }, [activePageTab?.id, formValues.context, initialValues.context]);

  const onToggleAdditionalFilters = useCallback(() => {
    if (isAdditionalFiltersExpanded) {
      dispatch(globalFiltersUiActions.hideAdditionalFilters());
    } else {
      dispatch(globalFiltersUiActions.showAdditionalFilters());
    }
  }, [isAdditionalFiltersExpanded]);

  useEffect(() => {
    dispatch(globalFiltersUiActions.showAdditionalFilters());
  }, [location.pathname]);

  const isSubAccountsFieldAvailable = !!customer?.multi_account && !guest;
  const isNqlGroupActive =
    (isNqlGroupRowMouseEnter || isContextFieldActive || isNqlFieldActive) &&
    !isDateTimeFieldActive;

  const showNqlLabel =
    !uiDisabled.nql || !!formValues[getNqlFieldName(currentContext)]?.[0];
  const showInvalidNqlIcon = !!nqlFieldError;
  const showNqlClearButton =
    !uiDisabled.nql &&
    !!formValues[getNqlFieldName(currentContext)]?.[0] &&
    isNqlGroupActive;
  const showNqlSaveButton =
    !uiDisabled.nql &&
    !showInvalidNqlIcon &&
    !!formValues[getNqlFieldName(currentContext)]?.[0] &&
    isNqlGroupActive;

  const additionalFiltersRowItems = [
    [
      <AdditionalFiltersRowItem key="afri__1">
        <Field
          component={AdditionalFiltersDropdownField}
          name="ipLabelContext"
          options={labelContextsOptions}
          parse={normalizeSelectValue}
          caption={
            <AdditionalFiltersDropdownCaptureContainer>
              <AdditionalFiltersDropdownCaptureLabel>
                {labelContextsLabel}
              </AdditionalFiltersDropdownCaptureLabel>
              <AdditionalFiltersDropdownCaptureValue>
                {formValues.ipLabelContext || 'None'}
              </AdditionalFiltersDropdownCaptureValue>
            </AdditionalFiltersDropdownCaptureContainer>
          }
          data-tracking="filter-row-display-labels"
        />
      </AdditionalFiltersRowItem>,
      <AdditionalFiltersSeparator $width={$separatorWidth} key="afri__2" />,
    ],

    !uiDisabled.metric && [
      <AdditionalFiltersRowItem key="afri__3">
        <Field
          component={AdditionalFiltersDropdownField}
          name={getMetricFieldName(currentContext)}
          options={metricsOptions}
          parse={normalizeSelectValue}
          caption={
            <AdditionalFiltersDropdownCaptureContainer>
              <AdditionalFiltersDropdownCaptureLabel>
                {metricLabel}
              </AdditionalFiltersDropdownCaptureLabel>
              <AdditionalFiltersDropdownCaptureValue>
                {formValues[getMetricFieldName(currentContext)]}
              </AdditionalFiltersDropdownCaptureValue>
            </AdditionalFiltersDropdownCaptureContainer>
          }
          disabled={uiDisabled.metric}
          data-tracking="filter-row-metric"
        />
      </AdditionalFiltersRowItem>,
      <AdditionalFiltersSeparator $width={$separatorWidth} key="afri__4" />,
    ],

    isSubAccountsFieldAvailable &&
      !uiDisabled.customers && [
        <AdditionalFiltersRowItem key="afri__5">
          <Field
            name="customers"
            component={AdditionalFiltersMultiDropdownField}
            options={subAccountOptions}
            parse={normalizeMultiSelectValue}
            caption={
              <AdditionalFiltersMultiDropdownCaptureContainer>
                <AdditionalFiltersMultiDropdownCaptureLabel>
                  {subAccountLabel}
                </AdditionalFiltersMultiDropdownCaptureLabel>
                <AdditionalFiltersMultiDropdownCaptureValue>
                  {formValues.customers?.[0] || 'None'}
                  {formValues.customers?.length > 1 && (
                    <AdditionalFiltersMultiDropdownShowMore>
                      +{formValues.customers?.length - 1} more
                    </AdditionalFiltersMultiDropdownShowMore>
                  )}
                </AdditionalFiltersMultiDropdownCaptureValue>
              </AdditionalFiltersMultiDropdownCaptureContainer>
            }
            disabled={uiDisabled.customers}
            isEqual={isEqualSubAccounts}
            data-tracking="filter-row-sub-account"
          />
        </AdditionalFiltersRowItem>,
        <AdditionalFiltersSeparator $width={$separatorWidth} key="afri__6" />,
      ],
    ...(additionalPageFilters || []).map((item) => (
      <Fragment key={item.id}>{item.content}</Fragment>
    )),
  ]
    .flat()
    .filter(Boolean);

  // 210 - width AdditionalFiltersRowItem
  // 80 - more button
  const maxItems =
    Math.max(Math.ceil((additionalFiltersRowWidth - (210 + 80)) / 210), 0) * 2;

  return (
    <FormContainer
      id="global-filters"
      onSubmit={handleSubmit}
      $paddingRight={isPropertiesTrayOpen ? sideBarRightWidthLocal : 0}
    >
      {/* We need those hidden fields to have them registered on the form even if DateTimeMenu is hidden */}
      <Field name="period.value" component={() => null} />
      <Field name="period.type" component={() => null} />
      <Field name="from" component={() => null} />
      <Field name="to" component={() => null} />
      {contextOptions.map((option, i) =>
        i === 0 ? null : (
          <Field
            key={option.value}
            name={`${getNqlFieldName(option.value)}[0]`}
            component={() => null}
          />
        ),
      )}
      <Field name="autoRefresh" component={() => null} />

      <MainRow $separatorWidth={$separatorWidth}>
        <MainRowSection>
          <MainRowNqlGroup
            ref={nqlGroupRowRef}
            className={classNames({
              invalid: !!nqlFieldError,
              active: isNqlGroupActive,
              disabled: uiDisabled.nql,
            })}
            onMouseEnter={() => setIsNqlGroupRowMouseEnter(true)}
            onMouseLeave={() => setIsNqlGroupRowMouseEnter(false)}
          >
            <NqlButtonsContainer>
              <NQLFieldContext>
                <Field
                  className={formValues.context}
                  name="context"
                  component={ContextDropdownField}
                  options={contextOptions}
                  validate={validateRequired}
                  isEqual={() => true}
                  onOpenOrClose={setIsContextFieldActive}
                  disabled={uiDisabled.nql}
                  required
                  data-tracking="traffic-type"
                />
              </NQLFieldContext>
            </NqlButtonsContainer>

            {showNqlLabel && <NQLFieldLabel>NQL</NQLFieldLabel>}

            <Field
              name={`${getNqlFieldName(currentContext)}[0]`}
              component={MainNqlFilter}
              context={currentContext}
              validate={validateNql}
              disabled={uiDisabled.nql}
              parse={parserNql}
              isEqual={isEqualNql}
              onEnterPress={onNqlKeyPress}
              data-tracking="nql-form"
              generalMenu
            />

            <NqlButtonsContainer>
              {showNqlClearButton && (
                <NQLFieldClear>
                  <IconButton
                    size="small"
                    title="Clear NQL"
                    onClick={onNqlClear}
                    data-tracking="gf-clear-nql"
                  >
                    <CloseIcon size={16} />
                  </IconButton>
                </NQLFieldClear>
              )}

              {showNqlSaveButton && (
                <NQLFieldSave>
                  <Button
                    variant={ButtonVariants.contained}
                    onClick={onNqlPresetSave}
                    data-tracking="gf-save-nql"
                  >
                    Save Preset
                  </Button>
                </NQLFieldSave>
              )}

              {showInvalidNqlIcon && (
                <Tooltip title={nqlFieldError} arrow={false}>
                  <NqlFieldError>
                    <InformationOutlineIcon />
                  </NqlFieldError>
                </Tooltip>
              )}
            </NqlButtonsContainer>
          </MainRowNqlGroup>

          <MainRowSeparator $width={$separatorWidth} $visible />

          <DateTimeDropdown
            className={classNames({
              active: isDateTimeFieldActive,
              inactive: !isSocketPaused || ui.onlyRealtime,
            })}
            values={formValues}
            onlyRealtime={ui.onlyRealtime}
            disabled={
              uiDisabled.periodValue &&
              uiDisabled.periodType &&
              uiDisabled.from &&
              uiDisabled.to
            }
            dirty={
              dirtyFields['period.value'] ||
              dirtyFields['period.type'] ||
              dirtyFields.from ||
              dirtyFields.to
            }
            onOpenOrClose={onDateTimeDropdownOpenOrClose}
            data-tracking="date-time-dropdown"
          >
            <DateTimeMenuContainer>
              <TabsContainer>
                <Tabs
                  value={
                    formValues.period?.type === CustomType
                      ? 'absolute'
                      : 'relative'
                  }
                  onChange={onDateTimeModeChange}
                >
                  <Tab value="absolute" label="Absolute" />
                  <Tab value="relative" label="Relative" />
                </Tabs>
              </TabsContainer>

              {formValues.period?.type === CustomType && (
                <Fragment>
                  <DateTimeMenuRow className="datetime-absolute-calendar">
                    <DateTimeRange
                      startDate={new Date(+formValues.from)}
                      endDate={new Date(+formValues.to)}
                      minDate={new Date(+minDate)}
                      maxDate={new Date(+maxDate)}
                      onChange={onDateTimeRangeChange}
                    />
                  </DateTimeMenuRow>

                  <DateTimeMenuRow className="datetime-absolute-fields">
                    <DateTimeMenuCol>
                      <DateTimeMenuLabel>From:</DateTimeMenuLabel>

                      <Field
                        component={DateTimePickerField}
                        name="from"
                        min={+minDate}
                        max={+(formValues.to ?? maxDate.subtract(1, 'minute'))}
                        disabled={uiDisabled.from}
                        validate={fromDateValidator}
                        parse={normalizeDateTimeToNumber}
                        errorPositionOver
                        minButton={false}
                      />
                    </DateTimeMenuCol>

                    <DateTimeMenuCol>
                      <DateTimeMenuLabel>To:</DateTimeMenuLabel>

                      <Field
                        component={DateTimePickerField}
                        name="to"
                        min={+(formValues.from ?? minDate)}
                        max={+maxDate}
                        disabled={uiDisabled.to}
                        validate={toDateValidator}
                        parse={normalizeDateTimeToNumber}
                        errorPositionOver
                        nowButton={false}
                      />
                    </DateTimeMenuCol>
                  </DateTimeMenuRow>
                </Fragment>
              )}

              {formValues.period?.type !== CustomType && (
                <Fragment>
                  <DateTimeMenuRow className="datetime-relative">
                    <DateTimeMenuLabel>Last:</DateTimeMenuLabel>

                    <Field
                      component={NumberField}
                      name="period.value"
                      step={1}
                      min={1}
                      max={Number.MAX_SAFE_INTEGER}
                      precision={0}
                      validate={[validateRequired]}
                      parser={parserPeriodValue}
                      onKeyUp={onPeriodValueEnterPress}
                      tabIndex={uiDisabled.periodValue ? '-1' : null}
                      disabled={uiDisabled.periodValue}
                    />

                    <Field
                      component={SelectField}
                      name="period.type"
                      options={periodOptions}
                      onOpen={onPeriodTypeMenuOpen}
                      onClose={onPeriodTypeMenuClose}
                      onKeyDown={onPeriodTypeKeyDown}
                      disabled={uiDisabled.periodType}
                      parse={normalizeSelectValue}
                    />
                  </DateTimeMenuRow>

                  <DateTimeMenuRow className="datetime-quick">
                    <Button
                      variant={ButtonVariants.link}
                      onClick={() => onSetPeriod(TimeDuration.minute, 30)}
                    >
                      Last 30 minutes
                    </Button>

                    <Button
                      variant={ButtonVariants.link}
                      onClick={() => onSetPeriod(TimeDuration.hour, 6)}
                    >
                      Last 6 hours
                    </Button>

                    <Button
                      variant={ButtonVariants.link}
                      onClick={() => onSetPeriod(TimeDuration.week, 1)}
                    >
                      Last 1 week
                    </Button>
                  </DateTimeMenuRow>

                  <DateTimeMenuRow className="datetime-quick">
                    <Button
                      variant={ButtonVariants.link}
                      onClick={() => onSetPeriod(TimeDuration.hour, 1)}
                    >
                      Last 1 hour
                    </Button>

                    <Button
                      variant={ButtonVariants.link}
                      onClick={() => onSetPeriod(TimeDuration.hour, 12)}
                    >
                      Last 12 hours
                    </Button>

                    <Button
                      variant={ButtonVariants.link}
                      onClick={() => onSetPeriod(TimeDuration.week, 2)}
                    >
                      Last 2 weeks
                    </Button>
                  </DateTimeMenuRow>

                  <DateTimeMenuRow className="datetime-quick">
                    <Button
                      variant={ButtonVariants.link}
                      onClick={() => onSetPeriod(TimeDuration.hour, 4)}
                    >
                      Last 4 hours
                    </Button>

                    <Button
                      variant={ButtonVariants.link}
                      onClick={() => onSetPeriod(TimeDuration.day, 1)}
                    >
                      Last 1 day
                    </Button>

                    <Button
                      variant={ButtonVariants.link}
                      onClick={() => onSetPeriod(TimeDuration.month, 1)}
                    >
                      Last 1 month
                    </Button>
                  </DateTimeMenuRow>
                </Fragment>
              )}

              <Field
                name="autoRefresh"
                checkedLabel="Auto refresh page"
                component={ToggleField}
                type="checkbox"
              />
            </DateTimeMenuContainer>
          </DateTimeDropdown>

          {!uiDisabled.apply && (
            <MainRowGroup>
              {pristine && (
                <ApplyButton
                  ref={submitButtonRef}
                  variant={ButtonVariants.outlined}
                  data-tracking="refresh-filters"
                  disabled={isSocketPaused ? ui.onlyRealtime : pristine}
                  onClick={onRefreshClick}
                >
                  Refresh
                </ApplyButton>
              )}
              {!pristine && (
                <ApplyButton
                  ref={submitButtonRef}
                  type="submit"
                  variant={ButtonVariants.contained}
                  data-tracking="apply-filters"
                  disabled={isSocketPaused ? ui.onlyRealtime : pristine}
                >
                  Update
                </ApplyButton>
              )}
            </MainRowGroup>
          )}

          {!uiDisabled.socketControl && (
            <MainRowGroup>
              <SocketControlButton />
            </MainRowGroup>
          )}
        </MainRowSection>

        <AdditionalFiltersButton
          $expanded={isAdditionalFiltersExpanded}
          onClick={onToggleAdditionalFilters}
          data-tracking="filters-row-hide-show"
        >
          {isAdditionalFiltersExpanded ? (
            <ChevronUpIcon />
          ) : (
            <ChevronDownIcon />
          )}
        </AdditionalFiltersButton>
      </MainRow>

      <AdditionalFiltersRow
        $expanded={isAdditionalFiltersExpanded}
        $separatorWidth={$separatorWidth}
        ref={additionalFiltersRowRef}
        $maxItems={maxItems}
      >
        {additionalFiltersRowItems}

        <AdditionalFiltersRowItem $width="8Opx" id="menu-more">
          <AdditionalFiltersMoreDropdown
            caption={
              <AdditionalFiltersDropdownCaptureContainer>
                <AdditionalFiltersDropdownCaptureLabel>
                  More...
                </AdditionalFiltersDropdownCaptureLabel>
              </AdditionalFiltersDropdownCaptureContainer>
            }
          >
            <AdditionalFiltersMore $maxItems={maxItems}>
              {additionalFiltersRowItems}
            </AdditionalFiltersMore>
          </AdditionalFiltersMoreDropdown>
        </AdditionalFiltersRowItem>

        <AdditionalFiltersSeparator $width={$separatorWidth} id="menu-more-s" />
      </AdditionalFiltersRow>
    </FormContainer>
  );
};

FormBody.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    ipLabelContext: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    context: PropTypes.string,
    period: PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.number,
    }),
    from: PropTypes.number,
    to: PropTypes.number,
  }).isRequired,
  $separatorWidth: PropTypes.number.isRequired,
};

export default FormBody;
