import getEventTableFilterValues from '+components/ContextTables/EventTable/getEventTableFilterValues';
import { UniversalCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  LabelOrIpColumnFactory,
  MenuColumnFactory,
} from '+components/Table/Columns';

export const getColumns = ({ labelContext, algorithms, cxActionMenu }) => [
  LabelOrIpColumnFactory({
    Header: 'IP',
    dataFieldName: 'ip',
    labelFieldName: 'ipname',
    showLabel: labelContext.show,
    context: labelContext.ip,
    fetchLabels: true,
    width: 200,
  }),
  BaseColumnFactory({
    accessor: 'algorithms',
    Header: 'Detection Models',
    Cell: UniversalCell('algorithm'),
    ...getEventTableFilterValues('algorithms', { algorithms }),
  }),
  MenuColumnFactory({
    cxActionMenu,
  }),
];
