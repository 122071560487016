import { useMemo } from 'react';

import { MenuColumnFactory } from '../../Columns';
import TechnicalColumns from '../TechnicalColumns';

const visibleColumns = (showMenu) => (columns) => {
  const menuColumn = columns.find((col) => col.id === TechnicalColumns.menu);

  if (menuColumn || !showMenu) {
    return columns;
  }

  const column = MenuColumnFactory({ cxActionMenu: () => null });

  return [column, ...columns];
};

export const useMenuColumn = (showMenu) => {
  const method = useMemo(() => visibleColumns(showMenu), [showMenu]);

  return (hooks) => {
    hooks.visibleColumns.push(method);
  };
};

useMenuColumn.pluginName = 'useMenuColumn';
