import { actions } from 'react-table';

import TechnicalColumns from '../TechnicalColumns';

const technicalColumnIds = new Set([
  TechnicalColumns.menu,
  TechnicalColumns.expander,
  TechnicalColumns.rowSelector,
]);

// sort columns this way: column with id == 'expander' always goes first,
// then column with id == 'menu' goes second, then all other columns
const visibleColumns = (columns) => {
  const menuColumn = columns.find((col) => col.id === TechnicalColumns.menu);
  const expanderColumn = columns.find(
    (col) => col.id === TechnicalColumns.expander,
  );
  const rowSelector = columns.find(
    (item) => item.id === TechnicalColumns.rowSelector,
  );

  return [
    menuColumn,
    expanderColumn,
    rowSelector,
    ...columns.filter((col) => !technicalColumnIds.has(col.id)),
  ].filter(Boolean);
};

export const useDefaultColumnOrder = (hooks) => {
  hooks.allColumns.push((columns, { instance }) => {
    instance.initialColumnOrder = (columns || []).map(({ id }) => id);
    return columns;
  });

  hooks.visibleColumns.push(visibleColumns);

  hooks.stateReducers.push((state, action, _, that) => {
    if (
      action.type === actions.setColumnOrder &&
      JSON.stringify(state.columnOrder) ===
        JSON.stringify(that.initialColumnOrder) &&
      state.columnOrder.length
    ) {
      return {
        ...state,
        columnOrder: [],
      };
    }

    return state;
  });
};
