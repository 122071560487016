import PropTypes from '+prop-types';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import MagnifyIcon from 'mdi-react/MagnifyIcon';

import { ContextTypes } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';
import RoutePaths from '@/models/RoutePaths';

import { selectors as labelsSelectors } from '@/redux/api/labels/ips';
import { selectors as rulesSelectors } from '@/redux/api/rules';

import IconButton from '+components/IconButton';
import { Col, LayoutTypes, Row } from '+components/Layout';
import { usePageTabs } from '+components/PageTabs';
import Table from '+components/Table';
import { useGlobalFilters } from '+hooks';
import { getFlowDataFromRecord } from '+utils/getFlowDataFromRecord';
import { getSearchUrl } from '+utils/getSearchUrl';

import { TableContainer } from '../TableContainer';
import { getColumns } from './getColumns';
import { useEventByIp } from './useEventByIp';

const LinkButton = ({ original, search }) => {
  const [, , pageTabMethods] = usePageTabs();

  const onClick = useCallback(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('ip', original.ip);
    searchParams.set('score', 'all');

    const url = `${RoutePaths.events}/list?${searchParams.toString()}&${search}`;

    pageTabMethods.add(url);
  }, [original, search]);

  return (
    <IconButton title={`Filter by ip: ${original.ip}`} onClick={onClick}>
      <MagnifyIcon size={16} />
    </IconButton>
  );
};

LinkButton.propTypes = {
  original: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired,
};

export const RelatedEvents = memo((props) => {
  const { event, isSubAccountRecord } = props;

  const [filters] = useGlobalFilters(ContextTypes.alerts);

  const algorithms = useSelector(rulesSelectors.getAlgorithms);

  const data = useEventByIp(event, algorithms);

  const ipLabelsHash = useSelector(labelsSelectors.getIpLabelsHash);

  const expandedData = useMemo(
    () =>
      (data || []).map((item) => ({
        ...item,
        ipname: ipLabelsHash?.[item.ip]?.[filters.labelContext.ip],
      })),
    [data, ipLabelsHash],
  );

  const search = useMemo(() => {
    const { from, to, endIsNow } = getFlowDataFromRecord({
      record: event,
      type: ContextTypes.alerts,
    });

    const url = getSearchUrl({
      context: ContextTypes.alerts,
      autoRefresh: false,
      from,
      to: endIsNow ? 'now' : to,
      period: {
        type: CustomType,
      },
      customer: isSubAccountRecord ? event?.customer : undefined,
    });

    return url.split('?')[1];
  }, [event, isSubAccountRecord]);

  const cxActionMenu = useCallback(
    (_, original) => <LinkButton original={original} search={search} />,
    [search],
  );

  const columns = useMemo(
    () =>
      getColumns({
        labelContext: filters.labelContext,
        algorithms,
        cxActionMenu,
      }),
    [filters.labelContext, algorithms],
  );

  return (
    <Row item $type={LayoutTypes.card}>
      <Col item>
        <Row $type={LayoutTypes.titleLarge}>Related Events</Row>

        <TableContainer item container={false}>
          <Table data={expandedData} columns={columns} minRows={1} />
        </TableContainer>
      </Col>
    </Row>
  );
});

RelatedEvents.displayName = 'RelatedEvents';

RelatedEvents.propTypes = {
  event: PropTypes.object,
  isSubAccountRecord: PropTypes.bool,
};

RelatedEvents.defaultProps = {
  event: undefined,
  isSubAccountRecord: false,
};
