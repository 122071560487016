import styled from 'styled-components';

import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';

import getBodyContainer from '+utils/getBodyContainer';

export default styled(
  ({ className, children, PopperProps, disabled, ...props }) =>
    disabled ? (
      children
    ) : (
      <Tooltip
        TransitionComponent={Fade}
        arrow
        {...props}
        classes={{
          tooltip: className,
        }}
        PopperProps={{
          ...PopperProps,
          container: getBodyContainer,
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                boundary: getBodyContainer(),
              },
            },
          ],
        }}
      >
        {children}
      </Tooltip>
    ),
)`
  z-index: 9999;

  &.MuiTooltip-tooltip {
    font-size: 1em;
    font-family: inherit;
    background-color: ${({ theme }) => theme.tooltipBackground};
    color: ${({ theme }) => theme.tooltipColor};
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.25);
    max-width: ${(props) =>
      props.$maxWidth ? `${props.$maxWidth}px` : 'unset'};

    .MuiTooltip-arrow:not(#fake_id_for_arrow) {
      color: ${({ theme }) => theme.tooltipBackground};
      &::before {
        box-shadow: 2px 2px 6px ${({ theme }) => theme.colorBackgroundSeparator};
      }
    }
  }
`;
