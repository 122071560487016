import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import styled from 'styled-components';

import { Modal } from '@mui/material';

import CogPlayOutlineIcon from 'mdi-react/CogPlayOutlineIcon';
import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as responseIntegrationsActions,
  selectors as responseIntegrationsSelectors,
} from '@/redux/api/integrations/response';

import Button, { ButtonVariants } from '+components/Button';
import { lang } from '+components/charts/common/utils';
import ConfirmModal from '+components/ConfirmModal';
import { PluginLabel } from '+components/Labels/PluginLabel';
import { ActionsContainer, Row } from '+components/Layout';
import { ModalBody, ModalFooter, ModalHeader } from '+components/Modal';
import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import useFocusOnRowId from '+hooks/useFocusOnRowId';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import { pluralize } from '+utils';

import { ResponseIntegrationModels } from '../shared/ResponseIntegrationModels';
import { Columns, getColumns } from './components/Columns';

const StyledModalBody = styled(ModalBody)`
  min-height: 60px;
`;

const preparedColumns = Object.values(Columns);

const tableId = 'RulesEngine_Plugins';
const FooterCloseButton = styled(Button)`
  color: #c0bfc5 !important;
  border-color: #c0bfc5 !important;
`;

const sortBy = [
  {
    id: 'name',
    desc: false,
  },
];

const ResponseIntegrations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [, setRowId] = useFocusOnRowId(tableId);

  const {
    plugins: integrations,
    isFetching,
    error,
    areAllPluginsFetched,
  } = useSelector(responseIntegrationsSelectors.getState);
  const permissions = usePermissions(
    PermissionModel.Resources.integration.value,
  );

  const [integration, setIntegration] = useState(null);
  const [showDelete, toggleShowDelete] = useToggle(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [selected, setSelected] = useState([]);
  const [showBulkDeleteModal, toggleBulkDeleteModal] = useToggle(false);
  const [showErrorModal, toggleErrorModal] = useToggle(false);
  const [errorModalDetails, setErrorModalDetails] = useState({});

  useLoadingIndicator(isFetching);

  const data = useMemo(() => Object.values(integrations || {}), [integrations]);

  const onAddClick = useCallback(
    () => navigate(`${RoutePaths.integrationsResponse}/add`),
    [],
  );

  const onEditClick = useCallback((item) => {
    navigate(`${RoutePaths.integrationsResponse}/edit/${item.id}`);
  }, []);

  const onTestClick = useCallback((integrationToTest) => {
    dispatch(responseIntegrationsActions.testPlugin(integrationToTest));
  }, []);
  const onDeleteConfirm = useCallback(() => {
    if (integration?.id) {
      dispatch(
        responseIntegrationsActions.deletePlugin(
          integration?.id,
          'ndr_integrations_delete_plugin',
        ),
      );
      setIsDeleting(true);
    }
  }, [integration?.id]);

  const cxActionMenu = useCallback(
    (id, row) => {
      const items = [
        {
          onClick: () => {
            setRowId(id);
            onEditClick(row);
          },
          icon: <LeadPencilIcon />,
          text: 'Edit',
          disabled: !ResponseIntegrationModels[row.adapter]?.component,
        },
        {
          onClick: () => {
            onTestClick(row);
          },
          icon: <CogPlayOutlineIcon />,
          text: 'Test',
          disabled:
            !ResponseIntegrationModels[row.adapter]?.component ||
            !permissions?.create,
        },
        {
          onClick: () => {
            setIntegration(row);
            toggleShowDelete(true);
          },
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled: !permissions?.delete,
        },
      ];

      return (
        <MenuColumnContextMenu
          title={row.name}
          items={items}
          dataTracking="response-integration"
        />
      );
    },
    [onEditClick, setIntegration, toggleShowDelete, permissions],
  );

  useEffect(() => {
    if (areAllPluginsFetched) {
      return;
    }
    dispatch(responseIntegrationsActions.fetchPlugins());
  }, [areAllPluginsFetched]);

  useEffect(() => {
    if (isFetching || !isDeleting) {
      return;
    }

    setIsDeleting(false);

    if (!error) {
      toggleShowDelete(false);
    }
  }, [isFetching, isDeleting, error, toggleShowDelete]);

  const onConfirmBulkDelete = useCallback(() => {
    toggleBulkDeleteModal();
    if (!selected.length) {
      return;
    }

    dispatch(
      responseIntegrationsActions.bulkDeleteResponseIntegrations(selected),
    );
  }, [selected]);

  const onErrorClick = useCallback((details) => {
    toggleErrorModal(true);
    setErrorModalDetails(details);
  }, []);

  const onSelectedRowsChange = useCallback((selectedRowIds) => {
    setSelected((prev) => {
      const next = Object.entries(selectedRowIds || {})
        .map(([key, value]) => (value ? key : null))
        .filter(Boolean);

      if (!prev.length && !next.length) {
        return prev;
      }

      return next;
    });
  }, []);

  const getIsRowSelectorDisabled = useCallback(
    () => !permissions?.update,
    [permissions],
  );

  const columns = useMemo(
    () =>
      getColumns(preparedColumns, {
        cxActionMenu,
        onErrorClick,
      }),
    [cxActionMenu, selected, onErrorClick],
  );

  return (
    <Fragment>
      <ActionsContainer>
        <Button
          disabled={!permissions?.create}
          onClick={onAddClick}
          testId="add-response-integration-button"
        >
          Add Integration
        </Button>

        <Button
          variant={ButtonVariants.outlined}
          onClick={toggleBulkDeleteModal}
          disabled={!permissions?.delete || !selected?.length}
        >
          Delete Selected
        </Button>
      </ActionsContainer>

      <Row sm={12} item container={false}>
        <Table
          id={tableId}
          columns={columns}
          data={data}
          sortBy={sortBy}
          noDataText={integrations ? undefined : lang.loading}
          getIsRowSelectorDisabled={getIsRowSelectorDisabled}
          onSelectedRowsChange={onSelectedRowsChange}
          testId="response-integrations-table"
        />
      </Row>

      {showDelete && (
        <ConfirmModal
          item={
            <PluginLabel
              style={{ marginLeft: '5px' }}
              deleteName={integration?.name}
              adapter={integration?.adapter}
              title={ResponseIntegrationModels[integration?.adapter]?.title}
              size={14}
            />
          }
          onConfirm={onDeleteConfirm}
          onToggle={toggleShowDelete}
          toggleOnConfirm={false}
          isDisabled={isFetching}
          cancelButtonDisabled={isFetching}
          isOpen
          testId="delete-response-integration-modal"
        />
      )}
      {showBulkDeleteModal && (
        <ConfirmModal
          item={`${selected.length} Response ${pluralize(
            selected.length,
            'Integration',
          )}`}
          confirmButtonText="delete"
          whyAsking=""
          onToggle={toggleBulkDeleteModal}
          onConfirm={onConfirmBulkDelete}
          toggleOnConfirm={false}
          isOpen
        />
      )}
      {/* Using MUI modal and css padding is a workaround - our modal component was causing scrolling issues when implemented this way */}
      <Modal
        open={showErrorModal}
        onClose={toggleErrorModal}
        sx={{
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '0 400px',
        }}
      >
        <Fragment>
          <ModalHeader color="danger" onClose={toggleErrorModal}>
            {errorModalDetails.name} Error Details:
          </ModalHeader>
          <StyledModalBody>
            <code>{`${errorModalDetails.error}`}</code>
          </StyledModalBody>
          <ModalFooter>
            <FooterCloseButton
              onClick={toggleErrorModal}
              variant={ButtonVariants.outlined}
            >
              Close
            </FooterCloseButton>
          </ModalFooter>
        </Fragment>
      </Modal>
    </Fragment>
  );
};

export default ResponseIntegrations;
