import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import isEqual from 'lodash.isequal';
import pick from 'lodash.pick';

import { ContextTypes } from '@/models/ContextTypes';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import { selectors as rulesSelectors } from '@/redux/api/rules';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import { Breadcrumb } from '+components/Breadcrumb';
import { usePageTabs } from '+components/PageTabs';
import usePermissions from '+hooks/usePermissions';

import CommonForm from './CommonForm';
import { paramsToUi } from './utils';

const additionalDefaultValuesForSystem = {
  beta: true,
  bypassanalytics: true,
  recommended: true,
};

const CommonAdd = (props) => {
  const {
    title,
    FormBody,
    defaultValues,
    initAlgorithm,
    pickFields,
    onSubmit,
    focusOnFields,
    isLoading,
    postProcessing,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [, activePageTab] = usePageTabs();

  // track if we have intercepted submit and if we are waiting on the API/dispatch to complete
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValuesMap, setInitialValuesMap] = useState({});

  const isFetching = useSelector(rulesSelectors.isFetching);
  const error = useSelector(rulesSelectors.getError);

  const isDefaultCustomer = useSelector(profileSelectors.isDefaultCustomer);

  const permissions = usePermissions(
    PermissionModel.Resources.threat_model.value,
  );

  const onCancel = useCallback(() => {
    navigate(`${RoutePaths.models}`);
  }, []);

  const doSubmit = useCallback(
    (values) => {
      setIsSubmitting(activePageTab?.id);
      onSubmit(values);
    },
    [onSubmit, activePageTab?.id],
  );

  useEffect(() => {
    if (!isSubmitting || isFetching) {
      return;
    }

    if (isSubmitting !== activePageTab?.id) {
      return;
    }

    if (error) {
      setIsSubmitting(false);
      return;
    }

    const isOk = postProcessing?.() ?? true;

    if (isOk) {
      onCancel();
    }
  }, [
    isSubmitting,
    isFetching,
    error,
    postProcessing,
    activePageTab?.id,
    onCancel,
  ]);

  // Workaround to prevent form rerendering while using fieldsArray with async validation
  useEffect(() => {
    const initValues = pick(initAlgorithm || {}, [
      ...pickFields,
      ...(isDefaultCustomer
        ? ['beta', 'subscriptiontype', 'bypassanalytics', 'recommended']
        : []),
    ]);

    if (!initValues.algo_record_type) {
      initValues.algo_record_type = ContextTypes.flow;
    }

    setInitialValuesMap((prev) => {
      const next = {
        [ContextTypes.flow]: paramsToUi({
          ...defaultValues[ContextTypes.flow],
          ...(isDefaultCustomer ? additionalDefaultValuesForSystem : {}),
          ...(initValues.algo_record_type === ContextTypes.flow && initValues),
        }),
        [ContextTypes.dns]: paramsToUi({
          ...defaultValues[ContextTypes.dns],
          ...(isDefaultCustomer ? additionalDefaultValuesForSystem : {}),
          ...(initValues.algo_record_type === ContextTypes.dns && initValues),
        }),
      };
      return isEqual(prev, next) ? prev : next;
    });
  }, [isDefaultCustomer, initAlgorithm, defaultValues]);

  return (
    <Fragment>
      <Breadcrumb title={title} pathname={location.pathname} />

      {!!Object.keys(initialValuesMap).length && (
        <CommonForm
          FormBody={FormBody}
          mode="create"
          isDefaultCustomer={isDefaultCustomer}
          initialValuesMap={initialValuesMap}
          initialContext={initAlgorithm?.algo_record_type || ContextTypes.flow}
          disabledTrafficType={!!initAlgorithm?.id}
          loading={!!isSubmitting || isFetching || isLoading}
          permissions={permissions}
          confirmButtonText="Create"
          onSubmit={doSubmit}
          onCancel={onCancel}
          focusOnFields={focusOnFields}
        />
      )}
    </Fragment>
  );
};

CommonAdd.propTypes = {
  title: PropTypes.string.isRequired,
  defaultValues: PropTypes.shape({
    [ContextTypes.flow]: PropTypes.object,
    [ContextTypes.dns]: PropTypes.object,
  }).isRequired,
  pickFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  initAlgorithm: PropTypes.shape({
    id: PropTypes.string,
    algo_record_type: PropTypes.oneOf([ContextTypes.flow, ContextTypes.dns]),
  }),
  onSubmit: PropTypes.func.isRequired,
  FormBody: PropTypes.component.isRequired,
  focusOnFields: PropTypes.bool,
  isLoading: PropTypes.bool,

  /**
   * Function to be called after the form is submitted and the API call is finished without errors
   * @returns {boolean} - if the form should be closed or not
   */
  postProcessing: PropTypes.func,
};

CommonAdd.defaultProps = {
  initAlgorithm: null,
  focusOnFields: true,
  isLoading: false,
  postProcessing: null,
};

export default CommonAdd;
