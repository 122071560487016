import PropTypes from '+prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import MDEditor from '@uiw/react-md-editor';
import rehypeExternalLinks from 'rehype-external-links';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';

import RoutePaths from '@/models/RoutePaths';

import { selectors as docsSelectors } from '@/redux/api/docs';
import { selectors as rulesSelectors } from '@/redux/api/rules';

import { usePageTabs } from '+components/PageTabs';
import { HeaderSubheader } from '+components/Table/Cells/HeaderSubheaderCell';

import CellWrapper from './CellWrapper';

const MarkdownStyling = {
  fontSize: '14px',
  backgroundColor: 'transparent',
};

const plugins = [
  [rehypeExternalLinks, { target: '_blank', rel: 'noreferrer' }],
];

const CellAlgorithmName = (props) => {
  const { name, description, original, disabled, isNew } = props;
  const [, , pageTabMethods] = usePageTabs();

  const algorithm = useSelector(rulesSelectors.getAlgorithmByName(name));
  const detectionModelDoc = useSelector(
    docsSelectors.getDetectionModelDoc(name),
  );

  const item = useMemo(() => {
    if (!algorithm) {
      return { name, info: detectionModelDoc?.body };
    }

    return {
      id: algorithm.id,
      name: algorithm.name,
      description: algorithm.description,
      type: 'algorithm',
      info: detectionModelDoc?.body,
      model:
        algorithm.algo_type === 'CDM'
          ? RoutePaths.modelsContext.pageName
          : RoutePaths.modelsDetection.pageName,
    };
  }, [name, algorithm, detectionModelDoc?.body]);

  const additionalMenuOptions = useMemo(
    () =>
      item.id && item.type === 'algorithm'
        ? [
            {
              icon: <LeadPencilIcon />,
              text: 'Edit',
              onClick: () => {
                pageTabMethods.add(
                  `${RoutePaths.models}/${item.model}/${item.id}`,
                );
              },
            },
          ]
        : [],
    [item.id, item.type, item.model, pageTabMethods],
  );

  return (
    <HeaderSubheader
      header={
        <CellWrapper
          customer={original?.customer}
          field="algorithm"
          value={name}
          info={
            detectionModelDoc?.body && (
              <MDEditor.Markdown
                source={detectionModelDoc?.body}
                fullscreen
                style={MarkdownStyling}
                rehypePlugins={plugins}
              />
            )
          }
          additionalMenuOptions={additionalMenuOptions}
          disabled={disabled}
        >
          <span>{item.name}</span>
        </CellWrapper>
      }
      subHeader={description}
      isNew={isNew}
    />
  );
};

CellAlgorithmName.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  original: PropTypes.shape(),
  disabled: PropTypes.bool,
  isNew: PropTypes.bool,
};

CellAlgorithmName.defaultProps = {
  description: null,
  original: null,
  disabled: false,
  isNew: false,
};

export default CellAlgorithmName;
